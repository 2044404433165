import { axios } from "src/common/utils";
import { appSettings } from "src/configs";
import { commonHeader, authHeader } from "src/common/requestHeader";
const ExpireReportService = {};

ExpireReportService.searchExpireReport = (formData) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
            },
            method: "get",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/ExpireReport`,
            params: { ...formData },
        })
            .then((response) => resolve(response.data))
            .catch((error) => reject(error));
    });
};

ExpireReportService.searchExpireReportAll = (formData) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
            },
            method: "get",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/ExpireReport/all`,
            params: { ...formData },
        })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
};

ExpireReportService.updateFormExpireReport = (formData) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
            },
            method: "post",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/ExpireReport/all`,
            data: { ...formData },
        })
            .then((response) => resolve(response.data))
            .catch((error) => reject(error));
    });
};

export default ExpireReportService;
