import * as actionTypes from "src/redux/actions/actionTypes";

const initialState = {
  currentMenu: null,
  loggedIn: false,
  user: null,
  tokenData: null,
  companyList: [],
  countryList: [],
  cargoList: [],
  terminalList: [],
  opertionList: [],
  nationalityList: [],
  classificationList: [],
  vesselTypeList: [],
  productList: [],
  unitList: [],
  vesselContractList: [],
  typeOfTransactionList: [],
  berthList: [],
  becauseList: [],
  becauseFinalReleaseList: [],
  userList: [],
  userListTerminal: [],
  userListVetter: [],
  currentRequestDetail: null,
  suportInfoVessel: null,
  requestTerminal: null,
  requestClearance: null,
  requestFinalrelease: null,
  requestAppealing: null,
  evaluationList: [],
  clearanceList: [],
  rootcauseList: [],
  operatorResponseList: [],
  docstsList: [],
  mQFormList: [],
  menuList: [],
  favoriteList: [],
  operationOfIncidentList: [],
  categoryList: [],
  countMyTask: [],
  countMyDraft: [],
  countVetterTask: [],
  countInspectionTask: [],
  countSponsorTask: [],
  businessUnitList: [],
  roleList: [],
  configSystemList: [],
  onHoldStatus: [],
  conditionOfInsList: [],
};

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SESSION_CURRENT_MENU: {
      return {
        ...state,
        currentMenu: action.payload,
      };
    }

    case actionTypes.SESSION_LOGIN: {
      return {
        ...state,
        loggedIn: true,
        user: action.payload,
      };
    }

    case actionTypes.SESSION_LOGOUT: {
      return {
        ...initialState,
      };
    }

    case actionTypes.SESSION_COMPANY: {
      return {
        ...state,
        companyList: action.payload,
      };
    }

    case actionTypes.SESSION_COUNTRY: {
      return {
        ...state,
        countryList: action.payload,
      };
    }

    case actionTypes.SESSION_CARGO: {
      return {
        ...state,
        cargoList: action.payload,
      };
    }

    case actionTypes.SESSION_TERMINAL: {
      return {
        ...state,
        terminalList: action.payload,
      };
    }

    case actionTypes.SESSION_OPERTION: {
      return {
        ...state,
        opertionList: action.payload,
      };
    }

    case actionTypes.SESSION_NATIONALITY: {
      return {
        ...state,
        nationalityList: action.payload,
      };
    }

    case actionTypes.SESSION_CLASSIFICATION: {
      return {
        ...state,
        classificationList: action.payload,
      };
    }

    case actionTypes.SESSION_VESSELTYPE: {
      return {
        ...state,
        vesselTypeList: action.payload,
      };
    }

    case actionTypes.SESSION_PRODUCT: {
      return {
        ...state,
        productList: action.payload,
      };
    }

    case actionTypes.SESSION_UNIT: {
      return {
        ...state,
        unitList: action.payload,
      };
    }

    case actionTypes.SESSION_VESSEL_CONTACT_LIST: {
      return {
        ...state,
        vesselContractList: action.payload,
      };
    }

    case actionTypes.SESSION_TYPE_OF_TRANSACTION: {
      return {
        ...state,
        typeOfTransactionList: action.payload,
      };
    }

    case actionTypes.SESSION_BERTH: {
      return {
        ...state,
        berthList: action.payload,
      };
    }

    case actionTypes.SESSION_BECAUSE: {
      return {
        ...state,
        becauseList: action.payload,
      };
    }

    case actionTypes.SESSION_BECAUSE_FINALERELEASE: {
      return {
        ...state,
        becauseFinalReleaseList: action.payload,
      };
    }

    case actionTypes.SESSION_USER: {
      return {
        ...state,
        userList: action.payload,
      };
    }

    case actionTypes.SESSION_USER_TERMINAL: {
      return {
        ...state,
        userListTerminal: action.payload,
      };
    }

    case actionTypes.SESSION_USER_VETTER: {
      return {
        ...state,
        userListVetter: action.payload,
      };
    }

    case actionTypes.SESSION_TOKENDATA: {
      return {
        ...state,
        tokenData: action.payload,
      };
    }

    case actionTypes.SESSION_REQUEST_CUREENT: {
      return {
        ...state,
        currentRequestDetail: action.payload,
      };
    }

    case actionTypes.SESSION_SUPPORTINFO_VESSEL: {
      return {
        ...state,
        suportInfoVessel: action.payload,
      };
    }

    case actionTypes.SESSION_REQUEST_TERMINAL: {
      return {
        ...state,
        requestTerminal: action.payload,
      };
    }

    case actionTypes.SESSION_REQUEST_CLEARANCE: {
      return {
        ...state,
        requestClearance: action.payload,
      };
    }

    case actionTypes.SESSION_REQUEST_FINALRELEASE: {
      return {
        ...state,
        requestFinalrelease: action.payload,
      };
    }

    case actionTypes.SESSION_REQUEST_APPEALING: {
      return {
        ...state,
        requestAppealing: action.payload,
      };
    }

    case actionTypes.SESSION_EVALUATION: {
      return {
        ...state,
        evaluationList: action.payload,
      };
    }

    case actionTypes.SESSION_CLEARANCE: {
      return {
        ...state,
        clearanceList: action.payload,
      };
    }

    case actionTypes.SESSION_ROOTCAUSE: {
      return {
        ...state,
        rootcauseList: action.payload,
      };
    }

    case actionTypes.SESSION_OPERATORRESPONSE: {
      return {
        ...state,
        operatorResponseList: action.payload,
      };
    }

    case actionTypes.SESSION_DOCSTS: {
      return {
        ...state,
        docstsList: action.payload,
      };
    }

    case actionTypes.SESSION_MQFORM: {
      return {
        ...state,
        mQFormList: action.payload,
      };
    }

    case actionTypes.SESSION_MENUROLE: {
      return {
        ...state,
        menuList: action.payload,
      };
    }

    case actionTypes.SESSION_VESSEL_FAVORITE: {
      return {
        ...state,
        favoriteList: action.payload,
      };
    }

    case actionTypes.SESSION_OPERATION_OF_INCIDENT: {
      return {
        ...state,
        operationOfIncidentList: action.payload,
      };
    }

    case actionTypes.SESSION_CATEGORY: {
      return {
        ...state,
        categoryList: action.payload,
      };
    }

    case actionTypes.SESSION_COUNT_MY_TASK: {
      return {
        ...state,
        countMyTask: action.payload,
      };
    }

    case actionTypes.SESSION_COUNT_MY_DRAFT: {
      return {
        ...state,
        countMyDraft: action.payload,
      };
    }

    case actionTypes.SESSION_COUNT_VETTER_TASK: {
      return {
        ...state,
        countVetterTask: action.payload,
      };
    }

    case actionTypes.SESSION_COUNT_INSPECTION_TASK: {
      return {
        ...state,
        countInspectionTask: action.payload,
      };
    }

    case actionTypes.SESSION_COUNT_SPONSOR_TASK: {
      return {
        ...state,
        countSponsorTask: action.payload,
      };
    }

    case actionTypes.SESSION_BUSINESS_UNIT_LIST: {
      return {
        ...state,
        businessUnitList: action.payload,
      };
    }

    case actionTypes.SESSION_ROLE_LIST: {
      return {
        ...state,
        roleList: action.payload,
      };
    }

    case actionTypes.SESSION_CONFIG_SYSTEM: {
      return {
        ...state,
        configSystemList: action.payload,
      };
    }

    case actionTypes.SESSION_ON_HOLD_STATUS: {
      return {
        ...state,
        onHoldStatus: action.payload,
      };
    }

    case actionTypes.SEESION_CONDITION_OF_INS: {
      return {
        ...state,
        conditionOfInsList: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default sessionReducer;
