import React, { createContext, useState, useContext } from "react";

const LeftMenuContext = createContext();

export const LeftMenuProvider = ({ children }) => {
    const [isMobile, setIsMobile] = useState(false);
    const [isLeftMenuFull, setIsLeftMenuFull] = useState(true);
  
    return (
      <LeftMenuContext.Provider value={{ isMobile, setIsMobile, isLeftMenuFull,setIsLeftMenuFull }}>
        {children}
      </LeftMenuContext.Provider>
    );
  };
  export const useLeftMenuContext = () => useContext(LeftMenuContext);