import { useHistory } from "react-router";
import helperUtil from "src/common/utils/helperUtil";
import { appSettings } from "src/configs";
import { TYPE_ROLE_USER_NAME } from "src/common/utils/StringUtil";
import Storage from "src/common/Storage";

const PATH_REQUEST_SEARCH = "/requestsearch";
const PATH_INSPECTION_SEARCH = "/inspectionsearch";
const PATH_MY_TASK = "/mytasks";
const PATH_VETTER_TASK = "/vettingtask";
const PATH_TERMINAL_TASK = "/myterminalscreening";

export default function useRoute() {
  const history = useHistory();

  const encryptId = (id) => {
    return helperUtil.encryptBase64(id);
  };

  const routeNotAuthen = () => {
    history.push("/errors/401");
  };

  const routeNotFound = () => {
    history.push("/error-404");
  };

  const reloadPage = () => {
    history.go(0);
  };

  const goBack = () => {
    if (history.length > 1) {
      history.goBack();
    } else {
      routeDefaultRole();
    }
  };

  const routeHome = () => {
    history.push("/");
  };

  const routeHomeRefresh = () => {
    window.open("/", "_self");
  };

  const routeLogin = (params = {}) => {
    const { queryString = "" } = params;
    history.push({
      pathname: `/auth/login`,
      search: queryString ? `?${queryString}` : "",
    });
  };

  const routeExpire = (params = {}) => {
    const { queryString = "" } = params;
    history.push({
      pathname: `/auth/expire`,
      search: queryString ? `?${queryString}` : "",
    });
  };

  const routeAddUser = () => {
    history.push("/adduser");
  };

  const routeVesselDetail = (params) => {
    const { id, newtab = false } = params;
    const url = `/Vessel/Detail/${encryptId(id)}`;
    if (newtab) {
      window.open(url, "_blank");
    } else {
      history.push(url);
    }
  };

  const routeVesselEdit = (params) => {
    const { id } = params;
    history.push({
      pathname: `/editVessel/${encryptId(id)}`,
    });
  };

  const routeSearchReplace = (params = {}) => {
    const { type = 0 } = params;
    history.replace(
      type === 2
        ? "/vesselsearch"
        : type === 1
          ? PATH_INSPECTION_SEARCH
          : PATH_REQUEST_SEARCH
    );
  };

  const routeVetterTaskReplace = (params) => {
    const { type } = params;
    history.replace(type === 1 ? "/inspectiontask" : PATH_VETTER_TASK);
  };

  const routeProfileDetail = (params) => {
    const { id, queryString = "" } = params;

    history.push({
      pathname: `/Profile/${encryptId(id)}`,
      search: `${queryString}`,
    });
  };

  const routeProfileDetailRefresh = (params) => {
    const { id, queryString = "" } = params;
    const url = `/Profile/${encryptId(id)}${queryString}`;
    window.open(url, "_self");
  };

  const routeViewAttachSupportInfo = (params) => {
    const { id } = params;
    const url = `${appSettings.services.map.apiUrl}/api/v1.0/SupportInfo/File/${id}`;
    window.open(url, "_blank");
  };

  const routeTopAzureLogin = () => {
    const url = `${appSettings.link.azure.top}`;
    window.open(url, "_self");
  };

  const routeIRPCAzureLogin = () => {
    const url = `${appSettings.link.azure.irpc}`;
    window.open(url, "_self");
  };

  const routeGCAzureLogin = () => {
    const url = `${appSettings.link.azure.gc}`;
    window.open(url, "_self");
  };

  const routeAzureLogout = () => {
    const url = `${appSettings.link.azure_logout_common}`;
    window.open(url, "_blank");
  };

  const routeCrewShipManual = () => {
    const url = `/CrewShipManual`;
    window.open(url, "_blank");
  };

  const routeAnswerShipManual = () => {
    const url = `/AnswerShipManual`;
    window.open(url, "_blank");
  };

  const routeAuthenAzureReplace = () => {
    history.replace("/auth/azure");
  };

  const routeAuthenResetPasswordReplace = () => {
    history.replace("/auth/resetpassword");
  };

  const routeViewVesselVPQ = async (params) => {
    const { id } = params;
    const url = `/VPQ/File/${encryptId(id)}`;
    window.open(url, "_blank");
  };

  const routeRequestDetail = (params) => {
    const { id, newtab = false } = params;
    const url = `/request/${encryptId(id)}`;
    if (newtab) {
      window.open(url, "_blank");
    } else {
      history.push(url);
    }
  };

  const routeRequestDetailRefresh = (params) => {
    const { id } = params;
    const url = `/request/${encryptId(id)}`;
    window.open(url, "_self");
  };

  const routeVetterClearanceRefresh = (params) => {
    const { id } = params;
    const url = `/vettingclearance/${encryptId(id)}`;
    window.open(url, "_self");
  };

  const routeTerminalScreening = (params) => {
    const { id } = params;

    history.push({
      pathname: `/terminalscreening/${encryptId(id)}`,
    });
  };

  const routeMyTask = () => {
    history.push(PATH_MY_TASK);
  };

  const routeMyDraft = () => {
    history.push("/draft");
  };

  const routeViewAttachRequest = (params) => {
    const { id } = params;
    const url = `${appSettings.services.map.apiUrl}/api/v1.0/Request/File/${id}`;
    window.open(url, "_blank");
  };

  const routeRequestTab = (params) => {
    const { type, id } = params;
    let url = "";
    const newId = encryptId(id);
    switch (parseInt(type)) {
      case 0:
        url = `/request/${newId}`;
        break;
      case 1:
        url = `/supportinfo/${newId}`;
        break;
      case 2:
        url = `/terminalscreening/${newId}`;
        break;
      case 3:
        url = `/vettingclearance/${newId}`;
        break;
      case 4:
        url = `/finalrelease/${newId}`;
        break;
      case 5:
        url = `/appealing/${newId}`;
        break;
      default:
        routeNotFound();
        break;
    }
    window.history.replaceState(null, "", url);
    // history.replace(url);
  };

  const routeMyTerminalTask = () => {
    history.push(PATH_TERMINAL_TASK);
  };

  const routeVetterClearanceDetail = (params) => {
    const { id } = params;

    history.push({
      pathname: `/vettingclearance/${encryptId(id)}`,
    });
  };

  const routeFinalreleaseDetail = (params) => {
    const { id } = params;

    history.push({
      pathname: `/finalrelease/${encryptId(id)}`,
    });
  };

  const routeVetterTask = () => {
    history.push(PATH_VETTER_TASK);
  };

  const routeMyAppealing = () => {
    history.push("/appealing");
  };

  const routeAppealingDetail = (params) => {
    const { id } = params;

    history.push({
      pathname: `/appealing/${encryptId(id)}`,
    });
  };

  const routeGoto = (url) => {
    history.push(url);
  };

  const routeRefreshGoto = (url) => {
    window.open(url, "_self");
  };

  const routeViewAttachInspection = (params) => {
    const { id } = params;
    const url = `${appSettings.services.map.apiUrl}/api/v1.0/Request/Inspection/InspectionFile/${id}`;
    window.open(url, "_blank");
  };

  const routeViewAttachQuickRA = (params) => {
    const { id } = params;
    const url = `${appSettings.services.map.apiUrl}/api/v1.0/Request/QuickReportRa/${id}`;
    window.open(url, "_blank");
  };

  const routeViewFullReport = (params) => {
    const { id } = params;
    const url = `${appSettings.services.map.apiUrl}/api/v1.0/Request/InspectionFullReport/${id}`;
    window.open(url, "_blank");
  };

  const routeViewSireDoc = (params) => {
    const { id } = params;
    const url = `${appSettings.services.map.apiUrl}/api/v1.0/Request/CheckSire/File/${id}`;
    window.open(url, "_blank");
  };

  const routeViewContactFile = (params) => {
    const { id } = params;
    const url = `${appSettings.services.map.apiUrl}/api/v1.0/Request/Contact/File/${id}`;
    window.open(url, "_blank");
  };

  const routeViewWorksheetFile = (params) => {
    const { id } = params;
    const url = `${appSettings.services.map.apiUrl}/api/v1.0/Request/Worksheet/File/${id}`;
    window.open(url, "_blank");
  };

  const routeViewUserManual = () => {
    const url = `${appSettings.services.map.apiUrl}/api/v1.0/File/PTT_MAP_User_Manual`;
    window.open(url, "_self");
  };

  const routeViewInsProgramManual = () => {
    const url = `${appSettings.services.map.apiUrl}/api/v1.0/File/PTT_MAP_Ins_Program_Manual`;
    window.open(url, "_self");
  };

  const routeViewCrewShipManual = () => {
    const url = `${appSettings.services.map.apiUrl}/api/v1.0/File/PTT_MAP_Crew_Ship_Manual`;
    window.open(url, "_self");
  };

  const routeViewAnswerShipManual = () => {
    const url = `${appSettings.services.map.apiUrl}/api/v1.0/File/PTT_MAP_Answer_Ship_Manual`;
    window.open(url, "_self");
  };

  const routeViewShipOperManual = () => {
    const url = `${appSettings.services.map.apiUrl}/api/v1.0/File/PTT_MAP_Ship_Oper_Manual`;
    window.open(url, "_self");
  };

  const routeInspectionDetail = (params) => {
    const { id, newtab = false } = params;
    const url = `/requestvesselinspection/${encryptId(id)}`;
    if (newtab) {
      window.open(url, "_blank");
    } else {
      history.push({
        pathname: url,
      });
    }
  };

  const routeDefaultRole = () => {
    const user = Storage.getUser();
    const role_id = user?.role_id;
    let path = "/";
    if (role_id) {
      switch (role_id) {
        case TYPE_ROLE_USER_NAME.REQUESTER.value:
          path = PATH_MY_TASK;
          break;
        case TYPE_ROLE_USER_NAME.TERMINAL.value:
          path = PATH_TERMINAL_TASK;
          break;
        case TYPE_ROLE_USER_NAME.VETTER.value:
          path = PATH_REQUEST_SEARCH;
          // path = PATH_VETTER_TASK;
          break;
        case TYPE_ROLE_USER_NAME.ADMIN.value:
        case TYPE_ROLE_USER_NAME.SUPER.value:
          path = PATH_REQUEST_SEARCH;
          break;
        case TYPE_ROLE_USER_NAME.MANAGEMENT.value:
          // path = PATH_REQUEST_SEARCH;
          break;
        case TYPE_ROLE_USER_NAME.SHIPOPERATOR.value:
          path = PATH_INSPECTION_SEARCH;
          break;
        default:
          break;
      }
      window.open(path, "_self");
    } else {
      window.open(path, "_self");
    }
  };

  const routeUserList = (params) => {
    const { queryString = "" } = params;

    history.push({
      pathname: `/userlist`,
      search: queryString ? `?${queryString}` : "",
    });
  };

  const routeUserListReplace = (params) => {
    const { queryString = "" } = params;

    history.replace({
      pathname: `/userlist`,
      search: queryString ? `?${queryString}` : "",
    });
  };

  const routeReportInspectionGrade = (params) => {
    const { id, newtab = false } = params;
    const url = `/ReportInspectionGrade/${encryptId(id)}`;
    if (newtab) {
      window.open(url, "_blank");
    } else {
      history.push(url);
    }
  };

  const routeSupportInfoReplace = (params) => {
    const { id = "" } = params;

    history.replace({
      pathname: `/Vessel/Detail/ODY4`,
      search: `?report=${id}`,
    });
  };

  const routeSupportInfoDetail = (params) => {
    const { id, queryString, newtab = false } = params;
    const url = `/InspectionReport/${encryptId(id)}`;
    if (newtab) {
      window.open(url, "_blank");
    } else {
      history.push({
        pathname: url,
        search: queryString ? queryString : "",
      });
    }
  };

  return {
    routeNotAuthen,
    routeNotFound,
    reloadPage,
    goBack,
    routeHome,
    routeLogin,
    routeExpire,
    routeVesselDetail,
    routeInspectionDetail,
    routeAddUser,
    routeSearchReplace,
    routeVetterTaskReplace,
    routeProfileDetail,
    routeProfileDetailRefresh,
    routeViewAttachSupportInfo,
    routeTopAzureLogin,
    routeIRPCAzureLogin,
    routeGCAzureLogin,
    routeAzureLogout,
    routeCrewShipManual,
    routeAnswerShipManual,
    routeAuthenAzureReplace,
    routeAuthenResetPasswordReplace,
    routeViewVesselVPQ,
    routeRequestDetail,
    routeRequestDetailRefresh,
    routeVetterClearanceRefresh,
    routeTerminalScreening,
    routeMyTask,
    routeMyDraft,
    routeViewAttachRequest,
    routeRequestTab,
    routeMyTerminalTask,
    routeVetterClearanceDetail,
    routeGoto,
    routeFinalreleaseDetail,
    routeVetterTask,
    routeMyAppealing,
    routeAppealingDetail,
    routeViewAttachInspection,
    routeViewAttachQuickRA,
    routeViewFullReport,
    routeViewSireDoc,
    routeViewContactFile,
    routeViewWorksheetFile,
    routeViewUserManual,
    routeViewInsProgramManual,
    routeViewCrewShipManual,
    routeViewAnswerShipManual,
    routeViewShipOperManual,
    routeRefreshGoto,
    routeHomeRefresh,
    routeDefaultRole,
    routeVesselEdit,
    routeUserList,
    routeUserListReplace,
    routeReportInspectionGrade,
    routeSupportInfoReplace,
    routeSupportInfoDetail,
  };
}
