/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { AppBar, Toolbar } from "@material-ui/core";
import useRoute from "src/common/utils/useRoute";

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "none",
    backgroundPosition: "right",
    backgroundColor: "#fff",
    display: "flex",
    justifyContent: "center",
    height: 90,
  },
  small: {
    border: "3px solid",
    borderColor: theme.palette.primary.user.avatarBorderColor,
    marginRight: 5,
  },
  toolbar: {
    minHeight: 90,
    [theme.breakpoints.down("md")]: {
      minHeight: 64,
    },
    maxWidth: 1600,
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
    justifyContent: "space-between",
  },
  toolbarLeft: {
    display: "flex",
    alignItems: "center",
  },
  imgLogo: {
    height: 45,
  },
  textLogo: {
    color: "#232a46",
    fontFamily: '"Helvetica 65"',
    fontSize: 24,
    margin: "9px 0 0",
    lineHeight: 1,
    marginLeft: 30,
  },
  textManual: {
    color: "#232a46",
    fontFamily: '"Helvetica 65"',
    fontSize: 18,
    margin: "0",
    padding: "10px",
    lineHeight: 1,
    marginLeft: 30,
    "&:hover": {
      background: "#dfdfdf",
      cursor: "pointer",
      borderRadius: "5px",
    },
  },
}));

function TopBar({ className, typePage, ...rest }) {
  const classes = useStyles();
  const { routeCrewShipManual, routeAnswerShipManual } = useRoute();

  return (
    <>
      <AppBar
        {...rest}
        className={clsx(classes.root, className)}
        color="primary"
      >
        <Toolbar className={classes.toolbar}>
          <div className={classes.toolbarLeft}>
            <div className="col-logo">
              <img
                className={classes.imgLogo}
                src="/assets/images/logo.png"
                alt="PTT"
              ></img>
            </div>
            <div>
              <h1 className={classes.textLogo}>Inspection Program</h1>
            </div>
          </div>
          <div>
            <p
              className={classes.textManual}
              onClick={() => {
                if (typePage === 1) {
                  routeAnswerShipManual();
                } else if (typePage === 2) {
                  routeCrewShipManual();
                }
              }}
            >
              <i className="far fa-file-alt"></i> User Manual
            </p>
          </div>
        </Toolbar>
      </AppBar>
    </>
  );
}

TopBar.propTypes = {
  className: PropTypes.string,
};

export default TopBar;
