import palette from '../palette';

export default {
  root: {
  background: '#f8f9fd',
  },
  input: {
    '&:focus': {
      background: '#ffffff',
    },
    '&::placeholder': {
      opacity: 1,
      color: palette.text.secondary
    },
   
  }
};
