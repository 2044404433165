import React, { Suspense } from 'react';
import { renderRoutes } from 'react-router-config';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { LinearProgress } from '@material-ui/core';
//import Topbar from './Topbar';


const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: '100vh',
    background: `url('${process.env.PUBLIC_URL}/assets/images/bglogin.png')`,
    '-webkit-background-size': 'cover',
    '-moz-background-size': 'cover',
    '-o-background-size': 'cover',
    '-ms-background-size': 'cover',
    },
  content: {
    flexGrow: 1,
    maxWidth: '100%',
    overflowX: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.down('xs')]: {
      paddingTop: 56
    },
    backgroundColor:'green',
  }
}));

function Auth({ route }) {
  const classes = useStyles();

  return (
    <>
      <div className={classes.container}>
          <Suspense fallback={<LinearProgress />}>
            {renderRoutes(route.routes)}
          </Suspense>
      </div>
    </>
  );
}

Auth.propTypes = {
  route: PropTypes.object
};

export default Auth;
