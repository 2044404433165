import appSettingsAll from "./appSettings.all";

const IS_INTRANET = appSettingsAll.IS_INTRANET;
const domain_backend = IS_INTRANET
    ? "https://do64008-map-web-api-prd.apps.ocpprd.pttdigital.com"
    : "https://api-pttmap.pttgrp.com";
const domain_frontend = IS_INTRANET
    ? "https://do64008-map-web-app-prd.apps.ocpprd.pttdigital.com"
    : "https://pttmap.pttgrp.com";

const appSettings = {
    ...appSettingsAll,
    services: {
        map: {
            apiUrl: domain_backend,
        },
    },
    link: {
        azure: {
            top:
                "https://myapps.microsoft.com/signin/14765bf6-bd55-459f-91f0-402bdd9aacef?tenantId=894f6e4e-e59c-47ff-be1e-b63a852cfb53",
            irpc:
                "https://myapps.microsoft.com/signin/cd39e16f-94d8-49c6-8afe-7b630046ab77?tenantId=0b03b740-99eb-40e7-947a-14dadbec534f",
            gc:
                "https://myapps.microsoft.com/signin/cff76c73-162a-4c79-9f0e-d6d3d90b14f2?tenantId=dc6df0e3-1692-4482-aa55-729e0a5e8361",
        },
        azure_logout: {
            top: `https://login.windows.net/894f6e4e-e59c-47ff-be1e-b63a852cfb53/oauth2/logout?post_logout_redirect_uri=${domain_frontend}`,
            irpc: `https://login.windows.net/0b03b740-99eb-40e7-947a-14dadbec534f/oauth2/logout?post_logout_redirect_uri=${domain_frontend}`,
            gc: `https://login.windows.net/dc6df0e3-1692-4482-aa55-729e0a5e8361/oauth2/logout?post_logout_redirect_uri=${domain_frontend}`,
        },
        azure_logout_common: `https://login.microsoftonline.com/common/oauth2/logout?post_logout_redirect_uri=${domain_frontend}`,
    },
};

export default appSettings;
