import { axios } from "src/common/utils";
import { appSettings } from "src/configs";
import { commonHeader, authHeader } from "src/common/requestHeader";

const ExcelService = {};

ExcelService.vesselNomination = (formData) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
                Pragma: "no-cache",
            },
            method: "get",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/Excel/VesselNomination`,
            params: { ...formData },
            responseType: "blob",
        })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
};

ExcelService.vesselInformation = (formData) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
                Pragma: "no-cache",
            },
            method: "get",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/Excel/VesselInformation`,
            params: { ...formData },
            responseType: "blob",
        })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
};

ExcelService.riskAssessment = (formData) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
                Pragma: "no-cache",
            },
            method: "get",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/Excel/RiskAssessment`,
            params: { ...formData },
            responseType: "blob",
        })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
};

ExcelService.requestedByMember = (formData) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
                Pragma: "no-cache",
            },
            method: "get",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/Excel/RequestedByMember`,
            params: { ...formData },
            responseType: "blob",
        })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
};

ExcelService.requestedByFlag = (formData) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
                Pragma: "no-cache",
            },
            method: "get",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/Excel/RequestedByFlag`,
            params: { ...formData },
            responseType: "blob",
        })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
};

ExcelService.flagByMember = (formData) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
                Pragma: "no-cache",
            },
            method: "get",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/Excel/FlagByMember`,
            params: { ...formData },
            responseType: "blob",
        })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
};

ExcelService.statusByMember = (formData) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
                Pragma: "no-cache",
            },
            method: "get",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/Excel/StatusByMember`,
            params: { ...formData },
            responseType: "blob",
        })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
};

ExcelService.statusSummary = (formData) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
                Pragma: "no-cache",
            },
            method: "get",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/Excel/StatusSummary`,
            params: { ...formData },
            responseType: "blob",
        })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
};

ExcelService.rejectedAtScreening = (formData) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
                Pragma: "no-cache",
            },
            method: "get",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/Excel/RejectedAtScreening`,
            params: { ...formData },
            responseType: "blob",
        })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
};

ExcelService.notReleased = (formData) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
                Pragma: "no-cache",
            },
            method: "get",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/Excel/NotReleased`,
            params: { ...formData },
            responseType: "blob",
        })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
};

ExcelService.rawData = (formData) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
                Pragma: "no-cache",
            },
            method: "get",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/Excel/RawData`,
            params: { ...formData },
            responseType: "blob",
        })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
};

ExcelService.inspectionAssignment = (formData) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
                Pragma: "no-cache",
            },
            method: "get",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/Excel/InspectionAssignment`,
            params: { ...formData },
            responseType: "blob",
        })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
};

export default ExcelService;
