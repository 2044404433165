export default {
    root: {},
    hasPopupIcon: {
        "&.MuiAutocomplete-hasClearIcon": {
            "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'][class*='MuiAutocomplete-inputRoot']": {
                paddingRight: 35,
            },
        },
    },
};
