import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CircleProgress from "src/components/Loading/CircleProgress";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        alignItems: "center",
    },
    wrapper: {
        // margin: theme.spacing(1),
        position: "relative",
    },
    wrapperLoading: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
    },
}));

const ButtonLoading = (props) => {
    const {
        buttonClassname,
        loading = false,
        disabled,
        handleButtonClick = () => {},
        buttonText = "",
        loadingSize = 24,
        buttonColor = "primary",
        buttonVariant = "contained",
        buttonSize = "large",
        typeButton = "button",
        ...otherprop
    } = props;

    const classes = useStyles();

    return (
        <div className={classes.wrapper}>
            <Button
                type={typeButton}
                size={buttonSize}
                variant={buttonVariant}
                color={buttonColor}
                className={buttonClassname}
                disabled={disabled === undefined ? loading : disabled}
                onClick={handleButtonClick}
                {...otherprop}
            >
                {loading && <CircleProgress loadingSize={loadingSize} />}
                <span>{buttonText}</span>
            </Button>
        </div>
    );
};

export default ButtonLoading;
