/*eslint-disable eqeqeq*/
import moment from "moment";
import {
  ALL_STRING,
  TYPE_SUPPORT_INFO,
  TYPE_CHANGE_CODE,
  TYPE_CHANGE_CODE_NAME,
  TYPE_ROLE_USER,
  TYPE_ROLE_USER_NAME,
  TYPE_DOMAIN,
  TYPE_DOMAIN_NAME,
  LIST_MONTH,
  VESSEL_STATUS,
} from "src/common/utils/StringUtil";
import Storage from "src/common/Storage";
import { AES, enc } from "crypto-js";

const aes_code = process.env.REACT_APP_AES_CODE;
const statusCodeDraft = 10;
const statusCodeRequested = 11;
const statusCodeCancel = 99;

const helperUtil = {};

helperUtil.parseFloat = function (value) {
  if (value != null) {
    var l = (value + "").length - (value + "").replace(/\./g, "").length;
    if (l > 1) {
      value = "";
    }
  }
  var ret = parseFloat((value + "").replace(/,/g, ""));

  if (isNaN(ret)) ret = 0;
  return ret;
};

helperUtil.parseInt = function (value) {
  if (value != null) {
    var l = (value + "").length - (value + "").replace(/\./g, "").length;
    if (l > 1) {
      value = "";
    }
  }
  var ret = parseInt((value + "").replace(/,/g, ""));

  if (isNaN(ret)) ret = 0;
  return ret;
};

helperUtil.formatDate = function (props = {}) {
  const {
    value,
    format = "DD/MM/YYYY",
    resInvalid = "",
    addTime = 0,
    addType = "hours",
  } = props;
  let result = resInvalid === "null" ? null : resInvalid;
  if (value) {
    result = moment(value)?.isValid()
      ? moment(value).add(addTime, addType).format(format)
      : result;
  }
  return result;
};

helperUtil.formatMonth = function (props = {}) {
  const { value, format = "MM", resInvalid } = props;
  let result = resInvalid === "null" ? null : resInvalid;
  if (value) {
    result =
      value > 0 && value <= 12
        ? moment()
          .month(value - 1)
          .format(format)
        : result;
  }
  return result;
};

helperUtil.getStartOfMonth = function (props) {
  const { year, month = 1, format = "YYYY-MM-DD" } = props;
  return moment([year, parseInt(month) - 1])
    .startOf("month")
    .format(format);
};

helperUtil.getEndOfMonth = function (props) {
  const { year, month = 12, format = "YYYY-MM-DD" } = props;
  return moment([year, parseInt(month) - 1])
    .endOf("month")
    .format(format);
};

helperUtil.getDate = function (props = {}) {
  const {
    format = "YYYY-MM-DD",
    addTime = 0,
    addType = "hours",
    value,
  } = props;
  const result = value
    ? moment(value).add(addTime, addType).format(format)
    : moment().add(addTime, addType).format(format);
  return result;
};

helperUtil.getTypeSupportInfo = function (type) {
  const typeSupportInfo = TYPE_SUPPORT_INFO?.[type]?.url
    ? TYPE_SUPPORT_INFO?.[type]?.url
    : "";
  return typeSupportInfo;
};

helperUtil.checkHasObject = function (data, boolType = true) {
  let check = 0;
  if (data) {
    check = Object?.keys(data)?.length;
  }
  return boolType ? !!check : check;
};

helperUtil.checkStatusSupportInfo = function (status) {
  let color_name = "";
  if (typeof status !== "string") {
    color_name = "gray";
  } else if (status === "") {
    color_name = "yellow";
  } else if (status === "Y") {
    color_name = "green";
  } else {
    color_name = "red";
  }
  return color_name;
};

helperUtil.textStatusVetter = function (status) {
  let status_text = "";
  if (typeof status !== "boolean") {
    status_text = "";
  } else if (status) {
    status_text = "Yes";
  } else {
    status_text = "No";
  }
  return status_text;
};

helperUtil.textEmpty = function (props = {}) {
  const { data = "", textAdd = "", textAddPre = "" } = props;
  let text = "";
  if (typeof data !== "string") {
    text = "";
  } else {
    text = textAddPre + data + textAdd;
  }
  return text;
};

helperUtil.getStatusAccess = function (props = {}) {
  const { status = 0 } = props;
  const isCreateable = 0 === status;
  const isUpdateable = 1 === status;
  return { isCreateable, isUpdateable };
};

helperUtil.getRoleList = function (props = {}) {
  const { sort = "" } = props;
  const list = Object.keys(TYPE_ROLE_USER).map((data) => ({
    id: TYPE_ROLE_USER[data].value,
    name: TYPE_ROLE_USER[data].name,
  }));
  if (sort.toString().toLowerCase() === "name_asc") {
    list.sort(function (a, b) {
      return ("" + a.name).localeCompare(b.name);
    });
  } else if (sort.toString().toLowerCase() === "name_desc") {
    list.sort(function (a, b) {
      return ("" + b.name).localeCompare(a.name);
    });
  } else if (sort.toString().toLowerCase() === "id_asc") {
    list.sort(function (a, b) {
      return a.id - b.id;
    });
  } else if (sort.toString().toLowerCase() === "id_desc") {
    list.sort(function (a, b) {
      return b.id - a.id;
    });
  }
  return list;
};

helperUtil.getDomainList = function (props = {}) {
  const { PTT_ONLY = false, sort = "" } = props;
  const list = Object.keys(TYPE_DOMAIN)
    .map((data) => ({
      id: TYPE_DOMAIN[data].value,
      name: TYPE_DOMAIN[data].name,
    }))
    .filter((data) => !PTT_ONLY || data.name != TYPE_DOMAIN_NAME.OTHER.name);
  if (sort.toString().toLowerCase() === "asc") {
    list.sort(function (a, b) {
      return ("" + a.name).localeCompare(b.name);
    });
  } else if (sort.toString().toLowerCase() === "desc") {
    list.sort(function (a, b) {
      return ("" + b.name).localeCompare(a.name);
    });
  }
  return list;
};

helperUtil.getDefaultPagesize = function (props = {}) {
  return [10, 20, 50, 100];
};

helperUtil.getTypeChangeCode = function (type) {
  const typeChangeCodeName = TYPE_CHANGE_CODE?.[type]?.name;
  const typeChangeCodeValue = TYPE_CHANGE_CODE?.[type]?.value;
  const code = typeChangeCodeValue
    ? typeChangeCodeValue
    : TYPE_CHANGE_CODE_NAME.VESSEL.value;
  const name = typeChangeCodeName
    ? typeChangeCodeName
    : TYPE_CHANGE_CODE_NAME.VESSEL.name;
  return { code, name };
};

helperUtil.checkStorageSize = function () {
  var _lsTotal = 0,
    _xLen,
    _x;
  for (_x in localStorage) {
    if (!localStorage.hasOwnProperty(_x)) {
      continue;
    }
    _xLen = (localStorage[_x].length + _x.length) * 2;
    _lsTotal += _xLen;
    console.log(_x.substr(0, 50) + " = " + (_xLen / 1024).toFixed(2) + " KB");
  }
  console.log("Total = " + (_lsTotal / 1024).toFixed(2) + " KB");
};

helperUtil.getTextItemOfPage = function (props) {
  const { currentPage, ItemLimit, totalItem, type = "text" } = props;
  const firstItem = (currentPage - 1) * ItemLimit + 1;
  const lastItem =
    currentPage * ItemLimit > totalItem ? totalItem : currentPage * ItemLimit;
  const text = `Records ${firstItem}-${lastItem} of ${helperUtil.addCommaNumber(
    totalItem
  )}`;
  return type == "text"
    ? text
    : {
      firstItem,
      lastItem,
      totalItem,
    };
};

helperUtil.getTypeRoleUser = function (type) {
  const typeRoleUser = TYPE_ROLE_USER?.[type]?.name
    ? TYPE_ROLE_USER?.[type]?.name
    : "";
  return typeRoleUser;
};

helperUtil.removeCommaNumber = (val) => {
  if (val) {
    return val.toString().replace(/,/g, "");
  }
  return val;
};

helperUtil.addCommaNumber = (val) => {
  if (val) {
    let data = helperUtil.removeCommaNumber(val);
    data = data
      .replace(".", "x")
      .replace(/\./g, "")
      .replace("x", ".")
      .split(".");
    data[0] = parseInt(data[0])
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    data = data.join(".");
    return data;
  }
  return val;
};

helperUtil.getStatusRole = function (props = {}) {
  const user = Storage.getUser();

  const user_role = user?.role_id;

  const IS_REQUESTER = TYPE_ROLE_USER_NAME.REQUESTER.value == user_role;
  const IS_TERMINAL = TYPE_ROLE_USER_NAME.TERMINAL.value == user_role;
  const IS_VETTER = TYPE_ROLE_USER_NAME.VETTER.value == user_role;
  const IS_ADMIN = TYPE_ROLE_USER_NAME.ADMIN.value == user_role;
  const IS_SUPER = TYPE_ROLE_USER_NAME.SUPER.value == user_role;
  const IS_MANAGEMENT = TYPE_ROLE_USER_NAME.MANAGEMENT.value == user_role;
  const IS_INSPECTOR = TYPE_ROLE_USER_NAME.INSPECTOR.value == user_role;
  const IS_SHIPOWNER = TYPE_ROLE_USER_NAME.SHIPOWNER.value == user_role;
  const IS_SHIPOPERATOR = TYPE_ROLE_USER_NAME.SHIPOPERATOR.value == user_role;
  return {
    IS_REQUESTER,
    IS_TERMINAL,
    IS_VETTER,
    IS_ADMIN,
    IS_SUPER,
    IS_MANAGEMENT,
    IS_INSPECTOR,
    IS_SHIPOWNER,
    IS_SHIPOPERATOR,
  };
};

helperUtil.getTypeSuppoerInfoList = function (props = {}) {
  const list = Object.keys(TYPE_SUPPORT_INFO).map((data) => ({
    id: data,
    name: TYPE_SUPPORT_INFO[data].name,
  }));
  return list;
};

helperUtil.checkRoleAction = function (props = {}) {
  const { empcode = 0 } = props;
  const user = Storage.getUser();

  const isOwner = user?.empcode == empcode;

  return { isOwner };
};

helperUtil.sleep = function (sleepDuration = 1000) {
  return new Promise((resolve) => {
    let now = new Date().getTime();
    while (new Date().getTime() < now + sleepDuration) { }
    resolve(true);
  });
};

helperUtil.numberOnly = function (val) {
  if (val) {
    let n;
    let result = val.toString();
    if ((n = result.indexOf(".")) != -1)
      result = "".concat(
        result.substring(0, n + 1),
        result.substring(n + 1).replace(".", "")
      );
    return result.replace(/[^0-9.]/g, "");
  }
  return val;
};

const getImageFile = (val) => {
  const checkPdf = val.includes("pdf");
  const checkJpg = val.includes("jpg") || val.includes("jpeg");
  const checkPng = val.includes("png");
  const checkGif = val.includes("gif");
  const checkTxt = val.includes("plain");
  const checkWord = val.includes("wordprocessingml") || val.includes("msword");
  const checkExcel = val.includes("spreadsheetml") || val.includes("excel");
  const checkPpt = val.includes("presentationml") || val.includes("powerpoint");
  const checkTif = val.includes("tif");
  const checkZip = val.includes("zip");
  const checkRar = val.includes("octet-stream");
  if (checkPdf) {
    return "/assets/images/pdf.png";
  } else if (checkJpg) {
    return "/assets/images/jpg.png";
  } else if (checkPng) {
    return "/assets/images/png.png";
  } else if (checkGif) {
    return "/assets/images/gif.png";
  } else if (checkTxt) {
    return "/assets/images/txt.png";
  } else if (checkWord) {
    return "/assets/images/doc.png";
  } else if (checkExcel) {
    return "/assets/images/xls.png";
  } else if (checkPpt) {
    return "/assets/images/ppt.png";
  } else if (checkTif) {
    return "/assets/images/tif.png";
  } else if (checkZip) {
    return "/assets/images/zip.png";
  } else if (checkRar) {
    return "/assets/images/rar.png";
  }
};
helperUtil.getPictureTypeImage = function (val, name = "") {
  let imgPath = "";
  if (val) {
    imgPath = getImageFile(val);
    if (!imgPath && name) {
      const split = name.split(".");
      const ext =
        split[split.length - 1] == "rar"
          ? "octet-stream"
          : split[split.length - 1];
      imgPath = getImageFile(ext);
    }
  } else if (name) {
    const split = name.split(".");
    const ext =
      split[split.length - 1] == "rar"
        ? "octet-stream"
        : split[split.length - 1];
    imgPath = getImageFile(ext);
  }
  return imgPath || "/assets/images/document.png";
};

helperUtil.bytesToSize = function (bytes) {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "n/a";
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
  if (i === 0) return `${bytes} ${sizes[i]})`;
  return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
};

helperUtil.checkAllowFileType = function (file, specific_type = []) {
  try {
    const extensionLists = !!specific_type?.length
      ? specific_type
      : ALL_STRING.FILE_WHITE_LIST;
    if (
      extensionLists &&
      extensionLists?.indexOf(file?.name?.split(".")?.pop()?.toLowerCase()) < 0
    ) {
      return false;
    }
    return true;
  } catch (error) {
    console.log("error", error);
    return false;
  }
};

helperUtil.checkLimitFileSize = function (file, checkAllowType = true) {
  if (file.size > 10e6) {
    return false;
  }
  return true;
};

helperUtil.btoa = function (str) {
  return new Buffer.from(str, "binary").toString("base64");
};

helperUtil.atob = function (str) {
  return Buffer.from(str, "base64").toString("binary");
};

helperUtil.encryptBase64 = function (data) {
  return data ? helperUtil.btoa(unescape(encodeURIComponent(data))) : data;
};

helperUtil.decryptBase64 = function (data) {
  return data ? decodeURIComponent(escape(helperUtil.atob(data))) : data;
};

helperUtil.calCenterPopup = function (width, height) {
  const window_width = window.screen.width;
  const window_height = window.screen.height;
  const object = {
    width: window_width,
    height: window_height,
  };
  if (width > 0 && height > 0) {
    const left = (window_width - width) / 2;
    const top = (window_height - height) / 4;
    object["left"] = left;
    object["top"] = top;
  }
  return { ...object };
};

helperUtil.getImageProfileUser = function () {
  const user = Storage.getUser();
  const bu = user?.bu?.toUpperCase() || "";
  const empcode = user?.empcode;
  let imgUrl = "/assets/images/user.png";
  if (bu && empcode) {
    switch (bu) {
      case TYPE_DOMAIN_NAME.OTHER.name:
        imgUrl = `https://pttg-ho-web01.pttgrp.com/PTTGRPDirectory/pttdigital/photo/${empcode}.jpg`;
        break;
      case TYPE_DOMAIN_NAME.PTTOR.name:
        imgUrl = `https://pttg-ho-web01.pttgrp.com/PTTGRPDirectory/files/pttor/${empcode}.jpg`;
        break;
      case TYPE_DOMAIN_NAME.PTT.name:
        imgUrl = `https://hq-web-s13.pttplc.com/directory/photo/${empcode}.jpg`;
        break;
      case TYPE_DOMAIN_NAME.IRPC.name:
        imgUrl = `https://pttg-ho-web01.pttgrp.com/PTTGRPDirectory/files/irpc/${empcode}.jpg`;
        break;
      case TYPE_DOMAIN_NAME.PTTGC.name:
        imgUrl = `http://pttg-ho-web01.pttgrp.com/PTTGRPDirectory/Web/empimg/${empcode}.jpg`;
        break;
      default:
        imgUrl = "/assets/images/user.png";
        break;
    }
  }

  return imgUrl;
};

helperUtil.getMonthList = function (props = {}) {
  const list = Object.keys(LIST_MONTH).map((data) => ({
    id: LIST_MONTH[data].value,
    name: LIST_MONTH[data].name,
    short_name: LIST_MONTH[data].short_name,
  }));
  return list;
};

helperUtil.getYearList = function (props = {}) {
  const { start = 0, end = 10 } = props;
  const startYear = moment().year() + parseInt(start);
  const list = [];
  for (let i = 0; i <= end; i++) {
    list.push({
      id: (startYear - i).toString(),
      name: (startYear - i).toString(),
    });
  }
  return list;
};

helperUtil.getVesselStatusList = function (props = {}) {
  const list = Object.keys(VESSEL_STATUS).map((data) => ({
    id: VESSEL_STATUS[data].value,
    name: VESSEL_STATUS[data].name,
  }));
  return list;
};

helperUtil.getNameFromPath = function (props = {}) {
  const { value } = props;
  let name = "";
  if (value) {
    name = value;
    const split = name.split("/");
    if (split?.length > 0) {
      name = split[split.length - 1];
    }
  }
  return name;
};

helperUtil.showYearBuilt = function (props = {}) {
  const { value } = props;
  let result = "";
  if (value) {
    result = value;
    if (value > 0) {
      const calYear = helperUtil.getDate({ format: "YYYY" }) - value;
      result = `${value} (${calYear} yrs.)`;
    }
  }
  return result;
};

helperUtil.encryptData = (val) => {
  if (val) {
    let ciphertext = AES.encrypt(val.toString(), aes_code).toString();
    return ciphertext;
  }
};

helperUtil.decryptData = (val) => {
  if (val) {
    let bytes = AES.decrypt(val, aes_code);
    let originalText = bytes.toString(enc.Utf8);
    return originalText;
  }
};

helperUtil.stringIsNullOrEmpty = (val) => {
  let data = "";
  const isString = typeof val == "string";
  const isStringNotEmpty = !!val?.trim()?.length;
  if (isString && isStringNotEmpty) {
    data = val;
  }
  return data;
};

helperUtil.colorVettingRequest = (doc_sts) => {
  const greenStatus = ["52", "66"];
  const redStatus = ["21", "51", "63", "67", "72", "90", "99"];
  const orangeStatus = [];
  let data = "#377AFC";
  if (greenStatus.includes(doc_sts)) {
    data = "#00E298";
  } else if (redStatus.includes(doc_sts)) {
    data = "#E53935";
  } else if (orangeStatus.includes(doc_sts)) {
    data = "#FFA500";
  }
  return data;
};

helperUtil.validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

helperUtil.checkRequestStatus = (val) => {
  const isDraft = val ? val == statusCodeDraft : false;
  const isRequested = val ? val == statusCodeRequested : false;
  const isCancel = val ? val == statusCodeCancel : false;
  const isFinal = val
    ? [21, 51, 52, 63, 66, 67, 99].some((s) => s == val)
    : false;

  return { isDraft, isRequested, isCancel, isFinal };
};

helperUtil.getInfoInsCrewMatrix = (key) => {
  let nameField = "";
  let maxLengthField = 0;
  switch (key) {
    case "CM01":
      nameField = "nationality";
      maxLengthField = 100;
      break;
    case "CM02":
      nameField = "cer_of_compatency";
      break;
    case "CM03":
      nameField = "issue_country";
      maxLengthField = 100;
      break;
    case "CM04":
      nameField = "admin_accept";
      maxLengthField = 100;
      break;
    case "CM05":
      nameField = "tanker_cer";
      maxLengthField = 100;
      break;
    case "CM06":
      nameField = "stcw_cargo";
      maxLengthField = 100;
      break;
    case "CM07":
      nameField = "radio_qua";
      maxLengthField = 10;
      break;
    case "CM08":
      nameField = "year_operator";
      maxLengthField = 18;
      break;
    case "CM09":
      nameField = "year_rank";
      maxLengthField = 18;
      break;
    case "CM10":
      nameField = "year_this_tanker";
      maxLengthField = 18;
      break;
    case "CM11":
      nameField = "year_all_tanker";
      maxLengthField = 18;
      break;
    case "CM12":
      nameField = "months_tour";
      maxLengthField = 10;
      break;
    case "CM13":
      nameField = "english_proficiency";
      maxLengthField = 100;
      break;
    case "CM14":
      nameField = "date_join_vessel";
      break;
    case "CM15":
      nameField = "year_watch";
      maxLengthField = 10;
      break;
    default:
      nameField = "";
      break;
  }
  return { nameField, maxLengthField };
};

export default helperUtil;
