import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Paper,
  MenuItem,
  Popper,
  Grow,
  ClickAwayListener,
  MenuList,
  TextField,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import helperUtil from "src/common/utils/helperUtil";

const useTableStyles = makeStyles({
  root: {},
  btn: {
    color: "#495057",
    background: "#ffffff",
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 6,
    paddingRight: 6,
    fontSize: "1em",
    marginLeft: ".5rem",
  },
  textpage: {
    marginTop: 0,
    marginBottom: 0,
    marginLeft: ".5rem",
    marginRight: ".5rem",
  },
  textpageInput: {
    background: "#ffffff",
    borderRadius: 6,
    fontSize: "1em",
    color: "#000000",
    paddingTop: 1,
    paddingBottom: 0,
  },
  linePage: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

export default (props) => {
  const {
    count = 1,
    pageCount = 1,
    page = 1,
    pageSize = 10,
    onChangePage = () => {},
    onChangeRowsPerPage = () => {},
    showRowPage = true,
    rowsPerPageOption = helperUtil.getDefaultPagesize(),
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const [pageData, setPageData] = useState(page);
  const [pageDataTxt, setPageDataTxt] = useState(page);
  const [rowsPerPageOptionData] = useState(rowsPerPageOption);
  const [rowsPerPageData, setRowsPerPageData] = useState(pageSize);

  const handlePage = (value) => {
    const _v = parseInt(value);
    onChangePage(_v);
    setPageData(_v);
    setPageDataTxt(_v);
  };

  useEffect(() => {
    const _v = parseInt(page);
    setPageData(_v);
    setPageDataTxt(_v);
  }, [page]);

  const classes = useTableStyles();

  const handleOnStepBackward = (event) => {
    event.preventDefault();
    handlePage(1);
  };

  const handleOnBackward = (event) => {
    event.preventDefault();
    if (pageData > 1) {
      handlePage(pageData - 1);
    }
  };

  const handleOnForward = (event) => {
    event.preventDefault();
    if (pageData < pageCount) {
      handlePage(pageData + 1);
    }
  };

  const handleOnStepForward = (event) => {
    event.preventDefault();
    handlePage(pageCount);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const handleSelectRowPerPageClose = (event, value) => {
    setAnchorEl(null);
    setOpen(false);

    if (value) {
      setRowsPerPageData(value);
      onChangeRowsPerPage(value);
    }
  };

  const handleOnChangePage = (event) => {
    const v = event.target.value;
    if (v === "") {
      setPageDataTxt(v);
    } else if (isNaN(v)) {
      event.preventDefault();
    } else {
      let _v = parseInt(v);
      if (_v === 0 && count > 0) {
        _v = 1;
      } else if (_v > pageCount) {
        _v = pageCount;
      }
      setPageDataTxt(v);
      // handlePage(_v);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleOnBlurPage(event);
    }
  };

  const handleOnBlurPage = (event) => {
    const {
      target: { value },
    } = event;
    if (value === "") {
      handlePage(1);
    } else if (isNaN(value)) {
      event.preventDefault();
    } else {
      let _v = parseInt(value);
      if (_v === 0 && count > 0) {
        _v = 1;
      } else if (_v > pageCount) {
        _v = pageCount;
      }
      handlePage(_v);
    }
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  return (
    <div className="table-pagination">
      <div className="row m-0">
        <div className="col-2"></div>
        <div className="col-8">
          <ul className="pagination">
            <li>
              <a href="#stepbackward" onClick={handleOnStepBackward}>
                <span className="glyphicon glyphicon-step-backward"></span>
              </a>
            </li>
            <li>
              <a href="#backward" onClick={handleOnBackward}>
                <span className="glyphicon glyphicon-backward"></span>
              </a>
            </li>
            <li className={clsx("page", classes.linePage)}>
              Page
              <TextField
                id="map-paging-page"
                variant="outlined"
                margin="dense"
                className={classes.textpage}
                InputProps={{
                  className: classes.textpageInput,
                }}
                value={pageDataTxt}
                inputProps={{
                  style: {
                    width:
                      (pageData + "").length > 1
                        ? 15 + (pageData + "").length * 6
                        : 15,
                  },
                }}
                onBlur={handleOnBlurPage}
                onChange={handleOnChangePage}
                onKeyDown={handleKeyDown}
              />
              of{" "}
              <span>
                {helperUtil.addCommaNumber(pageCount > 0 ? pageCount : 1)}
              </span>
            </li>
            <li>
              <a href="#forward" onClick={handleOnForward}>
                <span className="glyphicon glyphicon-forward"></span>
              </a>
            </li>
            <li>
              <a href="#stepforward" onClick={handleOnStepForward}>
                <span className="glyphicon glyphicon-step-forward"></span>
              </a>
            </li>
          </ul>
        </div>
        <div className="col-2">
          {showRowPage && (
            <div className="col-optional">
              <span>Rows/page</span>

              <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
                className={classes.btn}
                endIcon={<ExpandMoreIcon />}
                variant="contained"
              >
                {rowsPerPageData}
              </Button>
              <Popper
                open={open}
                anchorEl={anchorEl}
                role={undefined}
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === "bottom" ? "center top" : "center bottom",
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList
                          autoFocusItem={open}
                          id="map-paging-row-per-page"
                          onKeyDown={handleListKeyDown}
                        >
                          {rowsPerPageOptionData.map((o, i) => (
                            <MenuItem
                              onClick={(event) =>
                                handleSelectRowPerPageClose(event, o)
                              }
                              key={"map-paging-row-per-page-item-" + i}
                            >
                              {o}
                            </MenuItem>
                          ))}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
