import React from "react";
import { Router } from "react-router-dom";
import { renderRoutes } from "react-router-config";
import { createBrowserHistory } from "history";
import MomentUtils from "@date-io/moment";
import { Provider as StoreProvider } from "react-redux";
import { SnackbarProvider } from "notistack";
import { ThemeProvider } from "@material-ui/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { theme } from "./common/theme";
import { configureStore } from "./store";
import routes from "./routes";
import { CookiesNotification, ScrollReset, StylesProvider } from "./components";
import { LeftMenuProvider } from "src/common/Storage/LeftMenu.Context";
import "simplebar/src/simplebar.css";
import "./assets/scss/bootstrap.css";
import "./assets/scss/main.css";
import "./assets/scss/custom.css";
import Button from "@material-ui/core/Button";

const history = createBrowserHistory();
const store = configureStore();

function App() {
  const notistackRef = React.createRef();
  const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };
  return (
    <StoreProvider store={store}>
      <SnackbarProvider
        maxSnack={3}
        ref={notistackRef}
        action={(key) => <Button onClick={onClickDismiss(key)}>Close</Button>}
      >
        <ThemeProvider theme={theme}>
          <LeftMenuProvider>
            <StylesProvider direction={"ltr"}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <Router history={history}>
                  <ScrollReset />
                  <CookiesNotification />
                  {renderRoutes(routes)}
                </Router>
              </MuiPickersUtilsProvider>
            </StylesProvider>
          </LeftMenuProvider>
        </ThemeProvider>
      </SnackbarProvider>
    </StoreProvider>
  );
}

export default App;
