import { axios } from "src/common/utils";
import { appSettings } from "src/configs";
import { commonHeader, authHeader } from "src/common/requestHeader";
const MasterGroupService = {};

MasterGroupService.getMasterGroupList = (formData) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
            },
            method: "get",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/MasterGroup`,
            params: { ...formData }
        })
            .then((response) => resolve(response.data))
            .catch((error) => reject(error));
    });
};

MasterGroupService.getDataMasterGroupList = (formData) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
            },
            method: "get",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/MasterGroup/Data`,
            params: { ...formData }
        })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
};

MasterGroupService.updateDataMasterGroup = (formData) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
            },
            method: "post",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/MasterGroup/Data`,
            data: formData
        })
            .then((response) => resolve(response.data))
            .catch((error) => reject(error));
    });
};

export default MasterGroupService;