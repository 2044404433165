import { axios } from "src/common/utils";
import axiosDefault from "axios";
import { appSettings } from "src/configs";
import { commonHeader, authHeader } from "src/common/requestHeader";
import { ALL_STRING } from "src/common/utils/StringUtil";

const WORD_INPUT_INVALID = ALL_STRING.WORD_INPUT_INVALID;

const UserService = {};
var callUsers;
var callTerminalUsers;
var callVetterUsers;

UserService.getUsers = (formData) => {
  return new Promise((resolve, reject) => {
    if (callUsers) {
      callUsers.cancel();
    }
    callUsers = axiosDefault.CancelToken.source();
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
      },
      cancelToken: callUsers.token,
      method: "get",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/User`,
      params: { ...formData },
    })
      .then((response) => resolve(response))
      .catch(function (error) {
        if (axiosDefault.isCancel(error)) {
          console.log("Request canceled");
        } else {
          reject(error);
        }
      });
  });
};

UserService.getTerminalUsers = (formData) => {
  return new Promise((resolve, reject) => {
    if (callTerminalUsers) {
      callTerminalUsers.cancel();
    }
    callTerminalUsers = axiosDefault.CancelToken.source();
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
      },
      cancelToken: callTerminalUsers.token,
      method: "get",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/User`,
      params: { ...formData, role_id: "2" },
    })
      .then((response) => resolve(response))
      .catch(function (error) {
        if (axiosDefault.isCancel(error)) {
          console.log("Request canceled");
        } else {
          reject(error);
        }
      });
  });
};

UserService.getVetterUsers = (formData) => {
  return new Promise((resolve, reject) => {
    if (callVetterUsers) {
      callVetterUsers.cancel();
    }
    callVetterUsers = axiosDefault.CancelToken.source();
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
      },
      cancelToken: callVetterUsers.token,
      method: "get",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/User`,
      params: { ...formData, role_id: "3" },
    })
      .then((response) => resolve(response))
      .catch(function (error) {
        if (axiosDefault.isCancel(error)) {
          console.log("Request canceled");
        } else {
          reject(error);
        }
      });
  });
};

UserService.getUser = (userName, bu) => {
  const formData = {
    bu: bu,
  };

  return new Promise((resolve, reject) => {
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
      },
      method: "get",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/User/${userName}`,
      params: { ...formData },
    })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

UserService.getUserProfile = (userName, bu) => {
  const formData = {
    bu: bu,
  };

  return new Promise((resolve, reject) => {
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
      },
      method: "get",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/User/Profile/${userName}`,
      params: { ...formData },
    })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

UserService.addUser = (model) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
      },
      method: "post",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/User`,
      data: model,
    })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

UserService.updateUser = (model) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
      },
      method: "put",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/User`,
      data: model,
    })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

UserService.deleteUser = (userId, clientNo, updateBy) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
      },
      method: "delete",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/User`,
      params: {
        userId: userId,
        clientNo: clientNo,
        updateBy: updateBy,
      },
    })
      .then(() => resolve())
      .catch((error) => reject(error));
  });
};

UserService.getUserPTT = (props) => {
  const { bu, empcode, isptt = 1 } = props;
  if (!bu || !empcode) {
    return new Promise((resolve, reject) =>
      resolve({ message: WORD_INPUT_INVALID })
    );
  }
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
      },
      method: "get",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/User/${bu}/${empcode}/${isptt}`,
    })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

UserService.getVesselFavorite = () => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
      },
      method: "get",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/User/VesselFavorite`,
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

UserService.updateVesselFavorite = (formData) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
      },
      method: "post",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/User/VesselFavorite`,
      data: { ...formData },
    })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

export default UserService;
