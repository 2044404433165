import { axios } from "src/common/utils";
import axiosDefault from "axios";
import { appSettings } from "src/configs";
import { commonHeader, authHeader } from "src/common/requestHeader";

const RequestService = {};
var callMyTask;
var callDraft;
var callVetterTask;
var callInspectionTask;
var callSponsorTask;

RequestService.adddraft = (bodyFormData) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
      },
      method: "post",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/request/Draft`,
      data: bodyFormData,
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

RequestService.addrequest = (bodyFormData) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
      },
      method: "post",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/request/New`,
      data: bodyFormData,
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

RequestService.saveRecall = (req_no) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
      },
      method: "post",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/request/Recall/${req_no}`,
      data: {},
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

RequestService.saveCancel = (bodyFormData) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
      },
      method: "post",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/request/Cancel`,
      data: bodyFormData,
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

RequestService.drafts = () => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
        Pragma: "no-cache",
      },
      method: "get",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/tasks/draft`,
      data: {},
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

RequestService.draftLength = () => {
  return new Promise((resolve, reject) => {
    if (callDraft) {
      callDraft.cancel();
    }
    callDraft = axiosDefault.CancelToken.source();
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
        Pragma: "no-cache",
      },
      cancelToken: callDraft.token,
      method: "get",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/tasks/draft`,
      data: {},
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

RequestService.mytasks = () => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
        Pragma: "no-cache",
      },
      method: "get",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/tasks/request`,
      data: {},
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

// แยกกันไม่ให้ cancel ของการทำงานหลัก
RequestService.mytaskLength = () => {
  return new Promise((resolve, reject) => {
    if (callMyTask) {
      callMyTask.cancel();
    }
    callMyTask = axiosDefault.CancelToken.source();
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
        Pragma: "no-cache",
      },
      cancelToken: callMyTask.token,
      method: "get",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/tasks/request`,
      data: {},
    })
      .then((response) => resolve(response))
      .catch((error) => {
        if (axiosDefault.isCancel(error)) {
          console.log("Request canceled");
        } else {
          reject(error);
        }
      });
  });
};

RequestService.taskdetail = (id) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
        Pragma: "no-cache",
      },
      method: "get",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/tasks/request/${id}`,
      data: {},
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

RequestService.myterminalscreening = () => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
        Pragma: "no-cache",
      },
      method: "get",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/tasks/terminal`,
      data: {},
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

RequestService.terminalscreeningdetail = (formData) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
        Pragma: "no-cache",
      },
      method: "get",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/request/Terminal/Screening`,
      params: { ...formData },
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

RequestService.terminalscreening = (formData) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
      },
      method: "post",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/request/Terminal/Screening`,
      data: { ...formData },
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

RequestService.mytaskvetter = (formData) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
        Pragma: "no-cache",
      },
      method: "get",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/tasks/vetter`,
      params: {},
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

RequestService.myTaskVetterLength = (formData) => {
  return new Promise((resolve, reject) => {
    if (callVetterTask) {
      callVetterTask.cancel();
    }
    callVetterTask = axiosDefault.CancelToken.source();
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
        Pragma: "no-cache",
      },
      cancelToken: callVetterTask.token,
      method: "get",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/tasks/vetter`,
      data: {},
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

RequestService.mytaskinspection = (formData) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
        Pragma: "no-cache",
      },
      method: "get",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/tasks/inspection`,
      params: {},
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

RequestService.myTaskInspectionLength = (formData) => {
  return new Promise((resolve, reject) => {
    if (callInspectionTask) {
      callInspectionTask.cancel();
    }
    callInspectionTask = axiosDefault.CancelToken.source();
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
        Pragma: "no-cache",
      },
      cancelToken: callInspectionTask.token,
      method: "get",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/tasks/inspection`,
      data: {},
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};


RequestService.mytaskSponsor = (formData) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
        Pragma: "no-cache",
      },
      method: "get",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/tasks/Sponsor`,
      params: { ...formData },
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

RequestService.myTaskSponsorLength = (formData) => {
  return new Promise((resolve, reject) => {
    if (callSponsorTask) {
      callSponsorTask.cancel();
    }
    callSponsorTask = axiosDefault.CancelToken.source();
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
        Pragma: "no-cache",
      },
      cancelToken: callSponsorTask.token,
      method: "get",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/tasks/Sponsor`,
      data: {},
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

RequestService.vetterclearancedetail = (reqno) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
        Pragma: "no-cache",
      },
      method: "get",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/request/Vetter/Clearance/${reqno}`,
      params: {},
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

RequestService.vetterclearance = (bodyFormData) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
      },
      method: "post",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/request/Vetter/Clearance`,
      data: bodyFormData,
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

RequestService.vetterclearanceSave = (bodyFormData) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
      },
      method: "post",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/request/Vetter/Clearance/Save`,
      data: bodyFormData,
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

RequestService.finalreleasedetail = (reqno) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
        Pragma: "no-cache",
      },
      method: "get",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/request/Terminal/FinalRelease/${reqno}`,
      params: {},
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

RequestService.finalrelease = (formData) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
      },
      method: "post",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/request/Terminal/FinalRelease`,
      data: { ...formData },
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

RequestService.inspectionsummarydetail = (report_no) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
        Pragma: "no-cache",
      },
      method: "get",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/Request/Inspection/${report_no}`,
      params: {},
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

RequestService.inspectionvetter = (bodyFormData) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
      },
      method: "post",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/request/Inspection`,
      data: bodyFormData,
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

RequestService.inspectionvetterSave = (bodyFormData) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
      },
      method: "post",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/request/Inspection/Save`,
      data: bodyFormData,
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

RequestService.inspectionquickreportdetail = (report_no) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
        Pragma: "no-cache",
      },
      method: "get",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/Request/Vetter/QuickReport?report_no=${report_no}`,
      params: {},
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
RequestService.inspectionquickreportdetail2 = (report_no) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
        Pragma: "no-cache",
      },
      method: "get",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/Request/Vetter/QuickReport2?report_no=${report_no}`,
      params: {},
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

RequestService.inspectionQuickReport = (formData) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
      },
      method: "post",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/Request/Vetter/QuickReport/RA`,
      data: { ...formData },
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

RequestService.inspectionQuickReport2 = (formData) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
      },
      method: "post",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/Request/Vetter/QuickReport2/RA`,
      data: { ...formData },
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

RequestService.inspectionQuestionaireReport = (report_no) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
        Pragma: "no-cache",
      },
      method: "get",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/Request/Vetter/Questionaire/Report?report_no=${report_no}`,
      params: {},
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

RequestService.inspectionQuestionaireReport2 = (report_no) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
        Pragma: "no-cache",
      },
      method: "get",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/Request/Vetter/Questionaire2/Report?report_no=${report_no}`,
      params: {},
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

RequestService.inspectionQuickReportRaDetail = (result_id) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
        Pragma: "no-cache",
      },
      method: "get",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/Request/Vetter/QuickReport/RA?result_id=${result_id}`,
      params: {},
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

RequestService.inspectionQuickReport2RaDetail = (result_id) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
        Pragma: "no-cache",
      },
      method: "get",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/Request/Vetter/QuickReport2/RA?result_id=${result_id}`,
      params: {},
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

RequestService.myappealing = () => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
        Pragma: "no-cache",
      },
      method: "get",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/tasks/appealing`,
      data: {},
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

RequestService.myappealingvetter = () => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
        Pragma: "no-cache",
      },
      method: "get",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/tasks/appealing/vetter`,
      data: {},
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

RequestService.myappealingterminal = () => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
        Pragma: "no-cache",
      },
      method: "get",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/tasks/appealing/terminal`,
      data: {},
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

RequestService.appealingdetail = (reqno) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
        Pragma: "no-cache",
      },
      method: "get",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/Request/Appealing/Process?requestNo=${reqno}`,
      params: {},
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

RequestService.appealing = (bodyFormData) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
      },
      method: "post",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/request/Appealing/Process`,
      data: bodyFormData,
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

RequestService.searchrequest = (formData) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
        Pragma: "no-cache",
      },
      method: "get",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/request/search/paging`,
      params: { ...formData },
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

RequestService.checksire = (imo) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
        Pragma: "no-cache",
      },
      method: "get",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/Request/Vetter/Buysire/CheckSire/${imo}`,
      params: {},
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

RequestService.buysire = (reportno, templateno) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
        Pragma: "no-cache",
      },
      method: "get",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/Request/Vetter/Buysire/Purchase/${reportno}/${templateno}`,
      params: {},
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

RequestService.buysire2_0 = (inspection_id) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
        Pragma: "no-cache",
      },
      method: "get",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/Request/Vetter/Buysire/Purchase/${inspection_id}`,
      params: {},
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

RequestService.contactRequester = (formData) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
      },
      method: "post",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/Request/Terminal/ContactRequester`,
      data: { ...formData },
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

RequestService.getContactRequester = (id) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
        Pragma: "no-cache",
      },
      method: "get",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/Request/Terminal/RequesterAnswer/${id}`,
      data: {},
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

RequestService.contactRequesterAnswer = (bodyFormData) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
      },
      method: "post",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/Request/Terminal/RequesterAnswer`,
      data: bodyFormData,
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

RequestService.contactRequesterVetter = (formData) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
      },
      method: "post",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/Request/Vetter/ContactRequester`,
      data: { ...formData },
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

RequestService.getContactRequesterVetter = (id) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
        Pragma: "no-cache",
      },
      method: "get",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/Request/Vetter/RequesterAnswer/${id}`,
      data: {},
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

RequestService.contactRequesterAnswerVetter = (bodyFormData) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
      },
      method: "post",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/Request/Vetter/RequesterAnswer`,
      data: bodyFormData,
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

RequestService.getVesselWorksheet = (formData) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
        Pragma: "no-cache",
      },
      method: "get",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/Request/Inspection/Imo`,
      params: { ...formData },
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

RequestService.addworksheet = (bodyFormData) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
      },
      method: "post",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/Request/Inspection/Create`,
      data: bodyFormData,
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

RequestService.getWorksheetDetail = (formData) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
        Pragma: "no-cache",
      },
      method: "get",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/Request/Inspection/AssignInspector`,
      params: { ...formData },
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

RequestService.veriftworksheet = (bodyFormData) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
      },
      method: "post",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/Request/Inspection/Verify`,
      data: bodyFormData,
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

RequestService.assignInspector = (bodyFormData) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
      },
      method: "post",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/Request/Inspection/AssignInspector`,
      data: bodyFormData,
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

RequestService.assignInspectorCancel = (formData) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
      },
      method: "post",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/Request/Inspection/AssignInspector/Cancel`,
      data: { ...formData },
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

RequestService.veriftreport = (bodyFormData) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
      },
      method: "post",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/Request/Inspection/VerfiyTemplate`,
      data: bodyFormData,
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

RequestService.inspectionRequestCancel = (bodyFormData) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
      },
      method: "post",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/Request/Inspection/RequestCancel`,
      data: bodyFormData,
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

RequestService.getVesselHistory = (imo) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
        Pragma: "no-cache",
      },
      method: "get",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/tasks/history/${imo}`,
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

RequestService.getRecentlyBooking = (formData) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
      },
      method: "get",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/tasks/recentlybooking`,
      params: { ...formData },
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

RequestService.inspectionReportAdd = (bodyFormData) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
      },
      method: "post",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/Request/Vetter/Inspection/Add`,
      data: bodyFormData,
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

RequestService.getShipownerAnswer = (formData) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
      },
      method: "get",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/Request/Inspection/ShipAnswer`,
      params: { ...formData },
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

RequestService.saveShipownerAnswer = (bodyFormData) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
      },
      method: "post",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/Request/Inspection/ShipAnswer`,
      data: bodyFormData,
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

RequestService.submitShipownerAnswer = (formData) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
      },
      method: "post",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/Request/Inspection/ShipAnswer/Submit`,
      data: { ...formData },
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

RequestService.inspectionGrade = (formData) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
        Pragma: "no-cache",
      },
      method: "get",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/Request/InspectionGrade`,
      params: { ...formData },
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

export default RequestService;
