import React, { Suspense, useState } from "react";
import { renderRoutes } from "react-router-config";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { LinearProgress } from "@material-ui/core";
import NavBar from "./NavBar";
import TopBar from "./TopBar";
import { useLeftMenuContext } from "src/common/Storage/LeftMenu.Context";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    container: {
        minHeight: "100vh",
        // display: "flex",
        background: "#eef1f8",
        "@media all and (-ms-high-contrast:none)": {
            height: 0, // IE11 fix
        },
    },
    content: {
        paddingTop: 110,
        flexGrow: 1,
        //overflowX: 'hidden',
        height: "100%",
        maxWidth: 1700,
        [theme.breakpoints.up("lg")]: {
            paddingLeft: 300,
        },
        [theme.breakpoints.down("xs")]: {
            // paddingTop: 56,
        },
    },
    contentSmall: {
        paddingTop: 110,
        flexGrow: 1,
        maxWidth: 1700,
        //overflowX: 'hidden',
        [theme.breakpoints.up("lg")]: {
            paddingLeft: 70,
        },
        [theme.breakpoints.down("xs")]: {
            paddingTop: 56,
        },
    },
    hidePadding: {
        padding: 0,
    },
}));

function Full(props) {
    const { route } = props;
    const { showLayout = true } = route;
    const classes = useStyles();
    const [openNavBarMobile, setOpenNavBarMobile] = useState(false);
    const [fullNav, setFullNav] = useState(true);
    const { /*setIsMobile, */ setIsLeftMenuFull } = useLeftMenuContext();
    const handleOnNavToggle = (_isFullNav) => {
        setFullNav(_isFullNav);
        setIsLeftMenuFull(_isFullNav);
    };
    return (
        <>
            {showLayout && (
                <>
                    <TopBar
                        onOpenNavBarMobile={() => setOpenNavBarMobile(true)}
                    ></TopBar>
                    <NavBar
                        onMobileClose={() => setOpenNavBarMobile(false)}
                        openMobile={openNavBarMobile}
                        onNavToggle={handleOnNavToggle}
                    />
                </>
            )}
            <div className={classes.container}>
                <div
                    className={clsx(
                        fullNav ? classes.content : classes.contentSmall,
                        showLayout ? "" : classes.hidePadding
                    )}
                >
                    <Suspense fallback={<LinearProgress />}>
                        {renderRoutes(route.routes)}
                    </Suspense>
                </div>
            </div>
        </>
    );
}

Full.propTypes = {
    route: PropTypes.object,
};

export default Full;
