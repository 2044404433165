import palette from "../palette";
import typography from "../typography";

export default {
    root: {
        ...typography.body1,
        borderBottom: `1px solid ${palette.divider}`,
        "&:not(:first-child)": {
            borderLeft: "1px solid #dee2e6",
        },
    },
    head: {
        color: "#ffffff",
        lineHeight: "21px",
    },
};
