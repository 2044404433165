/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from "react";
//import { Link as RouterLink } from 'react-router-dom';
import { useHistory } from "react-router";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import {
  AppBar,
  Badge,
  Button,
  IconButton,
  Toolbar,
  Hidden,
  Input,
  colors,
  Popper,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ClickAwayListener,
  Avatar,
} from "@material-ui/core";
import NotificationsIcon from "@material-ui/icons/NotificationsOutlined";
import InputIcon from "@material-ui/icons/Input";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import NotificationsPopover from "src/components/NotificationsPopover";
import UserPopover from "src/components/User";
import { logout } from "src/redux/actions";
import Storage from "src/common/Storage";
import useRoute from "src/common/utils/useRoute";
import helperUtil from "src/common/utils/helperUtil";
import Image from "src/components/Image";

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "none",
    background: `url('${process.env.PUBLIC_URL}/assets/images/nav-bg-1.png') no-repeat left bottom/auto 100%`,
    // backgroundPosition: "right",
    height: "100px",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    backgroundPosition: "200px 0",
    backgroundColor: "#eef1f8",
    [theme.breakpoints.down("sm")]: {
      backgroundPosition: "0px 0",
    },
  },
  flexGrow: {
    flexGrow: 1,
  },
  search: {
    backgroundColor: "rgba(255,255,255, 0.1)",
    borderRadius: 4,
    flexBasis: 300,
    height: 36,
    padding: theme.spacing(0, 2),
    display: "flex",
    alignItems: "center",
  },
  searchIcon: {
    marginRight: theme.spacing(2),
    color: "inherit",
  },
  searchInput: {
    flexGrow: 1,
    color: "inherit",
    "& input::placeholder": {
      opacity: 1,
      color: "inherit",
    },
  },
  searchPopper: {
    zIndex: theme.zIndex.appBar + 100,
  },
  searchPopperContent: {
    marginTop: theme.spacing(1),
  },
  trialButton: {
    marginLeft: theme.spacing(2),
    color: theme.palette.common.white,
    backgroundColor: colors.green[600],
    "&:hover": {
      backgroundColor: colors.green[900],
    },
  },
  trialIcon: {
    marginRight: theme.spacing(1),
  },
  menuButton: {
    marginRight: theme.spacing(1),
  },
  chatButton: {
    marginLeft: theme.spacing(1),
  },
  notificationsButton: {
    marginLeft: theme.spacing(1),
  },
  notificationsBadge: {
    backgroundColor: colors.orange[600],
  },
  logoutButton: {
    marginLeft: theme.spacing(1),
  },
  logoutIcon: {
    marginRight: theme.spacing(1),
  },
  userButton: {
    color: theme.palette.primary.user.color,
    textTransform: "none",
    "& > span > div": {
      marginRight: 8,
    },
  },
  small: {
    border: "3px solid",
    borderColor: theme.palette.primary.user.avatarBorderColor,
    marginRight: 5,
    backgroundColor: "transparent",
  },
  toolbar: {
    minHeight: 100,
    [theme.breakpoints.down("md")]: {
      minHeight: 64,
    },
  },
  imgLogo: {
    height: 45,
    marginLeft: 310,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
  },
  textLogo: {
    color: "#232a46",
    fontFamily: '"Helvetica 65"',
    fontSize: 24,
    margin: "9px 0 0",
    lineHeight: 1,
    marginLeft: 30,
  },
  boxUserInfo: {
    fontFamily: "helvetica neue",
    color: "#000",
    display: "flex",
    flexDirection: "column",
    textAlign: "right",
  },
}));

const popularSearches = [
  "Devias React Dashboard",
  "Devias",
  "Admin Pannel",
  "Project",
  "Pages",
];

function TopBar({ onOpenNavBarMobile, className, ...rest }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const userRef = useRef(null);
  const [openUser, setOpenUser] = useState(false);
  const [user, setUser] = useState(undefined);

  React.useEffect(() => {
    const _user = Storage.getUser();
    if (helperUtil.checkHasObject(_user)) {
      setUser(_user);
    }
  }, []);

  const handleUserOpen = () => {
    setOpenUser(true);
  };

  const handleUserClose = () => {
    setOpenUser(false);
  };

  const user_bu = user?.bu || "";
  const user_role = user?.role || "";
  let userInfoMain = "";
  userInfoMain = user_bu;
  userInfoMain =
    userInfoMain && user_role ? `${userInfoMain} | ${user_role}` : userInfoMain;
  let userInfo = "";
  const user_empcode = user?.empcode || "";
  const user_full_name = user?.full_name_en || user?.full_name_th || "";
  const user_position_name =
    user?.postabbr || user?.postname_th || user?.postname_en || "";
  userInfo = user_empcode;
  userInfo =
    userInfo && user_full_name ? `${userInfo} | ${user_full_name}` : userInfo;
  userInfo =
    userInfo && user_position_name
      ? `${userInfo} | ${user_position_name}`
      : userInfo;

  return (
    <AppBar {...rest} className={clsx(classes.root, className)} color="primary">
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton
            className={classes.menuButton}
            onClick={onOpenNavBarMobile}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
        <div className="col-logo">
          <img
            className={classes.imgLogo}
            src="/assets/images/logo.png"
            alt="PTT"
          ></img>
        </div>
        <div>
          <h1 className={classes.textLogo}>Marine Quality Assurance Program</h1>
        </div>
        <div className={classes.flexGrow} />
        {/* <Hidden mdDown> */}{" "}
        <div className={classes.boxUserInfo}>
          <span style={{ fontWeight: "bold" }}>{userInfoMain}</span>
          <span style={{ fontSize: 12 }}>{userInfo}</span>
        </div>
        <Button
          className={classes.userButton}
          color="inherit"
          onClick={handleUserOpen}
          ref={userRef}
        >
          <Avatar className={classes.small}>
            <Image
              style={{ height: "100%", maxWidth: "100%" }}
              path={helperUtil.getImageProfileUser()}
              alt={user ? user.full_name_en : ""}
              onError={(e) => {
                e.target.src = "/assets/images/user.png";
              }}
            />
          </Avatar>
        </Button>
        {/* </Hidden> */}
      </Toolbar>
      <UserPopover
        anchorEl={userRef.current}
        onClose={handleUserClose}
        open={openUser}
      />
    </AppBar>
  );
}

TopBar.propTypes = {
  className: PropTypes.string,
  onOpenNavBarMobile: PropTypes.func,
};

export default TopBar;
