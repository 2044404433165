import React from "react";
import { makeStyles } from "@material-ui/styles";

import Page from "../../components/Page/Page.Component";
import { SecureGate } from "src/components";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(() => ({
    root: {
        paddingTop: 25,
        paddingLeft: 15,
        paddingRight: 15,
    },
}));

function MainScreen() {
    const classes = useStyles();

    return (
        <Page className={classes.root}>
            <SecureGate>
                <Grid container spacing={4}></Grid>
            </SecureGate>
        </Page>
    );
}

export default MainScreen;
