export const SESSION_CURRENT_MENU = 'SESSION_CURRENT_MENU';
export const SESSION_LOGIN = 'SESSION_LOGIN';
export const SESSION_TOKENDATA = 'SESSION_TOKENDATA';
export const SESSION_LOGOUT = 'SESSION_LOGOUT';
export const SESSION_COMPANY = 'SESSION_COMPANY';
export const SESSION_COUNTRY = 'SESSION_COUNTRY';
export const SESSION_CARGO = 'SESSION_CARGO';
export const SESSION_TERMINAL = 'SESSION_TERMINAL';
export const SESSION_OPERTION = 'SESSION_OPERTION';
export const SESSION_NATIONALITY = 'SESSION_NATIONALITY';
export const SESSION_CLASSIFICATION = 'SESSION_CLASSIFICATION';
export const SESSION_VESSELTYPE = 'SESSION_VESSELTYPE';
export const SESSION_PRODUCT = 'SESSION_PRODUCT';
export const SESSION_UNIT = 'SESSION_UNIT';
export const SESSION_VESSEL_CONTACT_LIST = 'SESSION_VESSEL_CONTRACT_LIST';
export const SESSION_TYPE_OF_TRANSACTION = 'SESSION_TYPE_OF_TRANSACTION';
export const SESSION_BERTH = 'SESSION_BERTH';
export const SESSION_BECAUSE = 'SESSION_BECAUSE';
export const SESSION_BECAUSE_FINALERELEASE = 'SESSION_BECAUSE_FINALERELEASE';
export const SESSION_USER = 'SESSION_USER';
export const SESSION_USER_TERMINAL = 'SESSION_USER_TERMINAL';
export const SESSION_USER_VETTER = 'SESSION_USER_VETTER';
export const SESSION_EVALUATION = 'SESSION_EVALUATION';
export const SESSION_CLEARANCE = 'SESSION_CLEARANCE';
export const SESSION_REQUEST_CUREENT = 'SESSION_REQUEST_CUREENT';
export const SESSION_REQUEST_TERMINAL = 'SESSION_REQUEST_TERMINAL';
export const SESSION_SUPPORTINFO_VESSEL = 'SESSION_SUPPORTINFO_VESSEL';
export const SESSION_REQUEST_CLEARANCE = 'SESSION_REQUEST_CLEARANCE';
export const SESSION_REQUEST_FINALRELEASE = 'SESSION_REQUEST_FINALRELEASE';
export const SESSION_REQUEST_APPEALING = 'SESSION_REQUEST_APPEALING';
export const SESSION_ROOTCAUSE = 'SESSION_ROOTCAUSE';
export const SESSION_OPERATORRESPONSE = 'SESSION_OPERATORRESPONSE';
export const SESSION_DOCSTS = 'SESSION_DOCSTS';
export const SESSION_MQFORM = 'SESSION_MQFORM';
export const SESSION_MENUROLE = 'SESSION_MENUROLE';
export const SESSION_VESSEL_FAVORITE = 'SESSION_VESSEL_FAVORITE';
export const SESSION_OPERATION_OF_INCIDENT = 'SESSION_OPERATION_OF_INCIDENT';
export const SESSION_CATEGORY = 'SESSION_CATEGORY';
export const SESSION_COUNT_MY_TASK = 'SESSION_COUNT_MY_TASK';
export const SESSION_COUNT_MY_DRAFT = 'SESSION_COUNT_MY_DRAFT';
export const SESSION_COUNT_VETTER_TASK = 'SESSION_COUNT_VETTER_TASK';
export const SESSION_COUNT_INSPECTION_TASK = 'SESSION_COUNT_INSPECTION_TASK';
export const SESSION_COUNT_SPONSOR_TASK = 'SESSION_COUNT_SPONSOR_TASK';
export const SESSION_BUSINESS_UNIT_LIST = 'SESSION_BUSINESS_UNIT_LIST';
export const SESSION_ROLE_LIST = 'SESSION_ROLE_LIST';
export const SESSION_CONFIG_SYSTEM = 'SESSION_CONFIG_SYSTEM';
export const SESSION_ON_HOLD_STATUS = 'SESSION_ON_HOLD_STATUS';
export const SEESION_CONDITION_OF_INS = 'SEESION_CONDITION_OF_INS';
