export const ALL_STRING = {
  WORD_ERROR_PLEASE_TRY_AGAIN:
    "Something wrong. Please try again or contact the administrator",
  WORD_ALERT: "Confirm Action",
  WORD_CONFIRM_ACTION_REPORT_OVER_SIX:
    "This report is over 6 months old. Are you sure you want to do this ?",
  WORD_CONFIRM_ACTION: "Are you sure you want to do this ?",
  WORD_CONFIRM_ADD_FAVORATE:
    "Are you sure to add this vessel to your favourited list?",
  WORD_CONFIRM_REMOVE_FAVORATE:
    "Are you sure to remove this vessel from your favourited list?",
  WORD_CONFIRM_CANCEL_INS_REQ: "Are you sure to cancel this request?",
  WORD_CANCEL: "Cancel",
  WORD_CONFIRM: "Confirm",
  WORD_CHECK_EMAIL: "Please check the Email",
  WORD_CLOSE: "Close",
  WORD_USERNAME: "Username",
  WORD_EMAIL: "Email",
  WORD_EMP_CODE: "Employee ID",
  WORD_PASSWORD: "Password",
  WORD_CONFIRM_PASSWORD: "Confirm password",
  WORD_INVALID_CONFIRM_PASSWORD: "Password and confirm password dose not match",
  WORD_SAVE_SUCCESS: "Save Completed",
  WORD_PLEASE_ALL_ANSWER: "Please fill the answer for all questions.",
  WORD_NAME: "Name",
  WORD_CHANGE_PASSWORD_SUCCESS:
    "The password has been changed successfully. Please login.",
  WORD_LOAD_DATA_SUCCESS: "Loading, Please wait",
  WORD_INPUT_INVALID: "Please fill the correct information",
  WORD_INPUT_ROLE_DUPLICATE:
    "This user role have ever been registered in the system. Please recheck.",
  WORD_INPUT_ROLE_ONE: "Please select at least one role.",
  WORD_INPUT_EMAIL_INVALID: "Please fill the correct email",
  WORD_EMPLOYEE_NOTFOUND: "Not found employee info.",
  WORD_REQUREID: "Please fill required field.",
  WORD_INPUT_DUPLICATE: "Duplicate Information",
  WORD_OUT_OF_DWT: "Cargo quantity is over safety margin of total DWT.",
  WORD_SUPPORT_INFO_REQUIRED: "Support info incomplete information",
  WORD_SUPPORT_INFO_LIST_REQUIRED: function (list) {
    const text = list?.length > 0 ? `(${list.join(", ")})` : "";
    return `Support Info ${text} incomplete information`;
  },
  WORD_USER_DUPLICATE_BU: function (empcode, bu) {
    return `Empcode: ${empcode} is duplicate in ${bu}`;
  },
  NO_DATA: "No Data",
  LIMIT_FILE_10MB: "This file cannot be attached exceed 10MB",
  SUPPORT_FILE_FORMAT:
    "Supported file formats: jpg, jpeg, gif, png, txt, doc, xls, pdf, eml, docx, xlsx, pptx, msg, mht, tif, tiff, zip, rar",
  NOT_ALLOW_FILE: `This file cannot be attached wrong type`,
  SPLIT_DATA_SEMICOLON: `If there is more than one, please separate with a semicolon(;).`,
  WORD_INPUT_MINIMUM_DATE: "Date should not be before minimum date",
  WORD_INPUT_MAXIMAL_DATE: "Date should not be after maximal date",
  WORD_INPUT_OVER_LOADING_DISCHARGE_DATE:
    "Loading date should not be after discharging date",
  WORD_INPUT_OVER_DISCHARGE_LOADING_DATE:
    "Discharging date should not be before loading date",
  FILE_WHITE_LIST: [
    "jpg",
    "jpeg",
    "gif",
    "png",
    "txt",
    "doc",
    "docx",
    "xls",
    "xlsx",
    "pdf",
    "eml",
    "ppt",
    "pptx",
    "msg",
    "mht",
    "tif",
    "tiff",
    "zip",
    "rar",
  ],
};

export const TYPE_SUPPORT_INFO_NAME = {
  TerminalFeedback: {
    value: 1,
    name: "Terminal Feedback",
    folder_name: "TerminalFeedback",
  },
  IndustryInformation: {
    value: 2,
    name: "Industry Information",
    folder_name: "IndustryInformation",
  },
  IncidentReport: {
    value: 3,
    name: "Casualty Report",
    folder_name: "IncidentReport",
  },
  PortState: {
    value: 4,
    name: "Port State",
    folder_name: "PortState",
  },
  ChangeInStatus: {
    value: 5,
    name: "Change In Status",
    folder_name: "ChangeInStatus",
  },
  InspectionReport: {
    value: 6,
    name: "Inspection Report",
    folder_name: "InspectionReport",
  },
  Certificate: {
    value: 7,
    name: "Certificate",
    folder_name: "Certificate",
  },
  OwnerPerformance: {
    value: 8,
    name: "TMSA",
    folder_name: "OwnerPerformance",
  },
  CrewManagement: {
    value: 9,
    name: "Crew Management",
    folder_name: "CrewManagement",
  },
  StructuralCondition: {
    value: 10,
    name: "Structural Condition",
    folder_name: "StructuralCondition",
  },
};

export const TYPE_SUPPORT_INFO = {
  [TYPE_SUPPORT_INFO_NAME.TerminalFeedback.value]: {
    name: TYPE_SUPPORT_INFO_NAME.TerminalFeedback.name,
    api_name: "terminal_feedback",
    url: "TerminalFeedback",
    urlOwnerResponse: "owneresponseTerminalFeedback",
    urlOwnerResponseUpdate: "ownerResponseTerminalFeedbackUpdate",
  },
  [TYPE_SUPPORT_INFO_NAME.IndustryInformation.value]: {
    name: TYPE_SUPPORT_INFO_NAME.IndustryInformation.name,
    api_name: "industry_information",
    url: "IndustryInformation",
    urlOwnerResponse: "owneresponseIndustry",
    urlOwnerResponseUpdate: "ownerResponseIndustryUpdate",
  },
  [TYPE_SUPPORT_INFO_NAME.IncidentReport.value]: {
    name: TYPE_SUPPORT_INFO_NAME.IncidentReport.name,
    api_name: "incident",
    url: "CasualtyReport",
    urlOwnerResponse: "owneresponseIncident",
    urlOwnerResponseUpdate: "ownerResponseIncidentReportUpdate",
  },
  [TYPE_SUPPORT_INFO_NAME.PortState.value]: {
    name: TYPE_SUPPORT_INFO_NAME.PortState.name,
    api_name: "port_state",
    url: "PortState",
    urlOwnerResponse: "owneresponsePortState",
    urlOwnerResponseUpdate: "ownerResponsePortStateUpdate",
  },
  [TYPE_SUPPORT_INFO_NAME.ChangeInStatus.value]: {
    name: TYPE_SUPPORT_INFO_NAME.ChangeInStatus.name,
    api_name: "change_in_status",
    url: "ChangeInStatus",
    urlOwnerResponse: "owneresponseChangeInStatus",
    urlOwnerResponseUpdate: "ownerResponseChangeInStatusUpdate",
  },
  [TYPE_SUPPORT_INFO_NAME.InspectionReport.value]: {
    name: TYPE_SUPPORT_INFO_NAME.InspectionReport.name,
    api_name: "inspection_report",
    url: "InspectionReport",
  },
  [TYPE_SUPPORT_INFO_NAME.Certificate.value]: {
    name: TYPE_SUPPORT_INFO_NAME.Certificate.name,
    api_name: "certificate",
    url: "Certificate",
    urlOwnerResponse: "owneresponseCertificate",
    urlOwnerResponseUpdate: "ownerResponseCertificateUpdate",
  },
  [TYPE_SUPPORT_INFO_NAME.OwnerPerformance.value]: {
    name: TYPE_SUPPORT_INFO_NAME.OwnerPerformance.name,
    api_name: "owner_performance",
    url: "TMSA",
    urlOwnerResponse: "owneresponseOwnerPerformance",
    urlOwnerResponseUpdate: "ownerResponseOwnerPerformanceUpdate",
  },
  [TYPE_SUPPORT_INFO_NAME.CrewManagement.value]: {
    name: TYPE_SUPPORT_INFO_NAME.CrewManagement.name,
    api_name: "crew_management",
    url: "CrewManagement",
    urlOwnerResponse: "owneresponseCrewManagement",
    urlOwnerResponseUpdate: "ownerResponseCrewManagementUpdate",
  },
  [TYPE_SUPPORT_INFO_NAME.StructuralCondition.value]: {
    name: TYPE_SUPPORT_INFO_NAME.StructuralCondition.name,
    api_name: "structural_condition",
    url: "StructuralCondition",
    urlOwnerResponse: "owneresponseStructural",
    urlOwnerResponseUpdate: "ownerResponseStructuralUpdate",
  },
};

export const TYPE_CHANGE_CODE_NAME = {
  VESSEL: {
    value: "V",
    name: "Vessel",
  },
  OWNER: {
    value: "R",
    name: "Owner",
  },
  OPERATOR: {
    value: "O",
    name: "Operator",
  },
  FLAG: {
    value: "F",
    name: "Flag",
  },
  CLASS: {
    value: "C",
    name: "Class",
  },
  OTHER: {
    value: "E",
    name: "Other",
  },
};

export const TYPE_CHANGE_CODE = {
  [TYPE_CHANGE_CODE_NAME.VESSEL.value]: {
    name: TYPE_CHANGE_CODE_NAME.VESSEL.name,
    value: TYPE_CHANGE_CODE_NAME.VESSEL.value,
  },
  [TYPE_CHANGE_CODE_NAME.OWNER.value]: {
    name: TYPE_CHANGE_CODE_NAME.OWNER.name,
    value: TYPE_CHANGE_CODE_NAME.OWNER.value,
  },
  [TYPE_CHANGE_CODE_NAME.OPERATOR.value]: {
    name: TYPE_CHANGE_CODE_NAME.OPERATOR.name,
    value: TYPE_CHANGE_CODE_NAME.OPERATOR.value,
  },
  [TYPE_CHANGE_CODE_NAME.FLAG.value]: {
    name: TYPE_CHANGE_CODE_NAME.FLAG.name,
    value: TYPE_CHANGE_CODE_NAME.FLAG.value,
  },
  [TYPE_CHANGE_CODE_NAME.CLASS.value]: {
    name: TYPE_CHANGE_CODE_NAME.CLASS.name,
    value: TYPE_CHANGE_CODE_NAME.CLASS.value,
  },
  [TYPE_CHANGE_CODE_NAME.OTHER.value]: {
    name: TYPE_CHANGE_CODE_NAME.OTHER.name,
    value: TYPE_CHANGE_CODE_NAME.OTHER.value,
  },
};

export const TYPE_ROLE_USER_NAME = {
  REQUESTER: {
    value: 1,
    name: "Requester",
  },
  TERMINAL: {
    value: 2,
    name: "Terminal",
  },
  VETTER: {
    value: 3,
    name: "Vetter",
  },
  ADMIN: {
    value: 4,
    name: "Admin",
  },
  SUPER: {
    value: 5,
    name: "Super Admin",
  },
  MANAGEMENT: {
    value: 6,
    name: "Management",
  },
  INSPECTOR: {
    value: 7,
    name: "Inspector",
  },
  SHIPOWNER: {
    value: 8,
    name: "Ship Owner",
  },
  SHIPOPERATOR: {
    value: 9,
    name: "Ship Operator",
  },
};

export const TYPE_ROLE_USER = {
  [TYPE_ROLE_USER_NAME.REQUESTER.value]: {
    name: TYPE_ROLE_USER_NAME.REQUESTER.name,
    value: TYPE_ROLE_USER_NAME.REQUESTER.value,
  },
  [TYPE_ROLE_USER_NAME.TERMINAL.value]: {
    name: TYPE_ROLE_USER_NAME.TERMINAL.name,
    value: TYPE_ROLE_USER_NAME.TERMINAL.value,
  },
  [TYPE_ROLE_USER_NAME.VETTER.value]: {
    name: TYPE_ROLE_USER_NAME.VETTER.name,
    value: TYPE_ROLE_USER_NAME.VETTER.value,
  },
  [TYPE_ROLE_USER_NAME.ADMIN.value]: {
    name: TYPE_ROLE_USER_NAME.ADMIN.name,
    value: TYPE_ROLE_USER_NAME.ADMIN.value,
  },
  [TYPE_ROLE_USER_NAME.SUPER.value]: {
    name: TYPE_ROLE_USER_NAME.SUPER.name,
    value: TYPE_ROLE_USER_NAME.SUPER.value,
  },
  [TYPE_ROLE_USER_NAME.MANAGEMENT.value]: {
    name: TYPE_ROLE_USER_NAME.MANAGEMENT.name,
    value: TYPE_ROLE_USER_NAME.MANAGEMENT.value,
  },
  [TYPE_ROLE_USER_NAME.INSPECTOR.value]: {
    name: TYPE_ROLE_USER_NAME.INSPECTOR.name,
    value: TYPE_ROLE_USER_NAME.INSPECTOR.value,
  },
  [TYPE_ROLE_USER_NAME.SHIPOWNER.value]: {
    name: TYPE_ROLE_USER_NAME.SHIPOWNER.name,
    value: TYPE_ROLE_USER_NAME.SHIPOWNER.value,
  },
  [TYPE_ROLE_USER_NAME.SHIPOPERATOR.value]: {
    name: TYPE_ROLE_USER_NAME.SHIPOPERATOR.name,
    value: TYPE_ROLE_USER_NAME.SHIPOPERATOR.value,
  },
};

export const TYPE_DOMAIN_NAME = {
  PTT: {
    value: 1,
    name: "PTT",
  },
  IRPC: {
    value: 2,
    name: "IRPC",
  },
  PTTGC: {
    value: 3,
    name: "GC",
  },
  TOP: {
    value: 4,
    name: "TOP",
  },
  PTTTANK: {
    value: 5,
    name: "PTTTANK",
  },
  PTTOR: {
    value: 6,
    name: "OR",
  },
  OTHER: {
    value: 7,
    name: "OTHER",
  },
};

export const TYPE_DOMAIN = {
  [TYPE_DOMAIN_NAME.PTT.value]: {
    name: TYPE_DOMAIN_NAME.PTT.name,
    value: TYPE_DOMAIN_NAME.PTT.value,
  },
  [TYPE_DOMAIN_NAME.IRPC.value]: {
    name: TYPE_DOMAIN_NAME.IRPC.name,
    value: TYPE_DOMAIN_NAME.IRPC.value,
  },
  [TYPE_DOMAIN_NAME.PTTGC.value]: {
    name: TYPE_DOMAIN_NAME.PTTGC.name,
    value: TYPE_DOMAIN_NAME.PTTGC.value,
  },
  [TYPE_DOMAIN_NAME.TOP.value]: {
    name: TYPE_DOMAIN_NAME.TOP.name,
    value: TYPE_DOMAIN_NAME.TOP.value,
  },
  [TYPE_DOMAIN_NAME.PTTTANK.value]: {
    name: TYPE_DOMAIN_NAME.PTTTANK.name,
    value: TYPE_DOMAIN_NAME.PTTTANK.value,
  },
  [TYPE_DOMAIN_NAME.PTTOR.value]: {
    name: TYPE_DOMAIN_NAME.PTTOR.name,
    value: TYPE_DOMAIN_NAME.PTTOR.value,
  },
  [TYPE_DOMAIN_NAME.OTHER.value]: {
    name: TYPE_DOMAIN_NAME.OTHER.name,
    value: TYPE_DOMAIN_NAME.OTHER.value,
  },
};

export const LIST_MONTH_NAME = {
  January: {
    value: 1,
    name: "January",
    short_name: "Jan",
  },
  February: {
    value: 2,
    name: "February",
    short_name: "Feb",
  },
  March: {
    value: 3,
    name: "March",
    short_name: "Mar",
  },
  April: {
    value: 4,
    name: "April",
    short_name: "Apr",
  },
  May: {
    value: 5,
    name: "May",
    short_name: "May",
  },
  June: {
    value: 6,
    name: "June",
    short_name: "Jun",
  },
  July: {
    value: 7,
    name: "July",
    short_name: "Jul",
  },
  August: {
    value: 8,
    name: "August",
    short_name: "Aug",
  },
  September: {
    value: 9,
    name: "September",
    short_name: "Sep",
  },
  October: {
    value: 10,
    name: "October",
    short_name: "Oct",
  },
  November: {
    value: 11,
    name: "November",
    short_name: "Nov",
  },
  December: {
    value: 12,
    name: "December",
    short_name: "Dec",
  },
};

export const LIST_MONTH = {
  [LIST_MONTH_NAME.January.value]: {
    name: LIST_MONTH_NAME.January.name,
    short_name: LIST_MONTH_NAME.January.short_name,
    value: LIST_MONTH_NAME.January.value,
  },
  [LIST_MONTH_NAME.February.value]: {
    name: LIST_MONTH_NAME.February.name,
    short_name: LIST_MONTH_NAME.February.short_name,
    value: LIST_MONTH_NAME.February.value,
  },
  [LIST_MONTH_NAME.March.value]: {
    name: LIST_MONTH_NAME.March.name,
    short_name: LIST_MONTH_NAME.March.short_name,
    value: LIST_MONTH_NAME.March.value,
  },
  [LIST_MONTH_NAME.April.value]: {
    name: LIST_MONTH_NAME.April.name,
    short_name: LIST_MONTH_NAME.April.short_name,
    value: LIST_MONTH_NAME.April.value,
  },
  [LIST_MONTH_NAME.May.value]: {
    name: LIST_MONTH_NAME.May.name,
    short_name: LIST_MONTH_NAME.May.short_name,
    value: LIST_MONTH_NAME.May.value,
  },
  [LIST_MONTH_NAME.June.value]: {
    name: LIST_MONTH_NAME.June.name,
    short_name: LIST_MONTH_NAME.June.short_name,
    value: LIST_MONTH_NAME.June.value,
  },
  [LIST_MONTH_NAME.July.value]: {
    name: LIST_MONTH_NAME.July.name,
    short_name: LIST_MONTH_NAME.July.short_name,
    value: LIST_MONTH_NAME.July.value,
  },
  [LIST_MONTH_NAME.August.value]: {
    name: LIST_MONTH_NAME.August.name,
    short_name: LIST_MONTH_NAME.August.short_name,
    value: LIST_MONTH_NAME.August.value,
  },
  [LIST_MONTH_NAME.September.value]: {
    name: LIST_MONTH_NAME.September.name,
    short_name: LIST_MONTH_NAME.September.short_name,
    value: LIST_MONTH_NAME.September.value,
  },
  [LIST_MONTH_NAME.October.value]: {
    name: LIST_MONTH_NAME.October.name,
    short_name: LIST_MONTH_NAME.October.short_name,
    value: LIST_MONTH_NAME.October.value,
  },
  [LIST_MONTH_NAME.November.value]: {
    name: LIST_MONTH_NAME.November.name,
    short_name: LIST_MONTH_NAME.November.short_name,
    value: LIST_MONTH_NAME.November.value,
  },
  [LIST_MONTH_NAME.December.value]: {
    name: LIST_MONTH_NAME.December.name,
    short_name: LIST_MONTH_NAME.December.short_name,
    value: LIST_MONTH_NAME.December.value,
  },
};

export const VESSEL_STATUS_NAME = {
  Inactive: { value: 0, name: "Inactive" },
  Active: { value: 1, name: "Active" },
  Onhold: { value: 2, name: "On hold" },
};

export const VESSEL_STATUS = {
  [VESSEL_STATUS_NAME.Inactive.value]: {
    name: VESSEL_STATUS_NAME.Inactive.name,
    value: VESSEL_STATUS_NAME.Inactive.value,
  },
  [VESSEL_STATUS_NAME.Active.value]: {
    name: VESSEL_STATUS_NAME.Active.name,
    value: VESSEL_STATUS_NAME.Active.value,
  },
  [VESSEL_STATUS_NAME.Onhold.value]: {
    name: VESSEL_STATUS_NAME.Onhold.name,
    value: VESSEL_STATUS_NAME.Onhold.value,
  },
};
