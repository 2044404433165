import React, { useEffect, useState } from "react";
//import { NavLink as RouterLink } from 'react-router-dom';
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { /*ListItem, Button,*/ Collapse, Link } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { useHistory, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  currentMenu,
  setCountMyTask,
  setCountMyDraft,
  setCountVetterTask,
  setCountInspectionTask,
  setCountSponsorTask,
} from "src/redux/actions";
import Badge from "@material-ui/core/Badge";

const useStyles = makeStyles((theme) => ({
  item: {
    display: "block",
    paddingTop: 0,
    paddingBottom: 0,
  },
  itemLeaf: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
    "&:hover": {
      cursor: "pointer",
      "& a.nav-link": {
        backgroundColor: "#dbe3f8",
        fontWeight: "bold",
      },
    },
  },
  button: {
    padding: "10px 8px",
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
  },
  buttonLeaf: {
    padding: "10px 8px",
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
    fontWeight: theme.typography.fontWeightRegular,
    "&.depth-0": {
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
  icon: {
    color: theme.palette.icon,
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1),
  },
  expandIcon: {
    marginLeft: "auto",
    height: 16,
    width: 16,
  },
  label: {
    display: "flex",
    alignItems: "center",
    marginLeft: "auto",
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    "& $icon": {
      color: theme.palette.primary.main,
    },
  },
  textLink: {
    "&:hover": {
      textDecoration: "none",
      cursor: "pointer",
    },
  },
  contentBadge: {
    backgroundColor: "#00AFD7",
    color: "white",
    top: "18px",
    right: "92px",
  },
  contentBadgeDraft: {
    backgroundColor: "#00AFD7",
    color: "white",
    top: "18px",
    right: "68px",
  },
  contentBadgeVetter: {
    backgroundColor: "#00AFD7",
    color: "white",
    top: "15px",
    right: "0px",
    fontSize: "12px",
    width: "17px",
    height: "17px",
  },
  contentBadgeSponsor: {
    backgroundColor: "#00AFD7",
    color: "white",
    top: "15px",
    right: "0px",
    fontSize: "12px",
    width: "17px",
    height: "17px",
  },
  dotBadge: {
    top: "2px",
    right: "2px",
    width: "10px",
    height: "10px",
    borderRadius: "10px",
  },
}));

function NavItem({
  title,
  href,
  depth,
  children,
  icon: Icon,
  className,
  open: openProp,
  label: Label,
  eleIcon,
  ...rest
}) {
  const session = useSelector((state) => state.session);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [open, setOpen] = useState(openProp);
  const [isShowBadge, setIsShowBadge] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const handleToggle = (event) => {
    event.preventDefault();
    setOpen((prevOpen) => !prevOpen);
    dispatch(currentMenu(title));
  };

  const handleToggleLink = (event, link) => {
    event.preventDefault();
    if (
      link?.toLowerCase() === "/usermanual" ||
      link?.toLowerCase() === "/insprogrammanual"
    ) {
      window.open(link, "_blank");
    } else {
      history.push(link);
    }
  };

  const checkBadgeShow = async () => {
    const { countMyTask } = session;
    let totalList = [];
    const doc_sts_1 = ["11", "20"];
    const doc_sts_2 = ["30", "31", "32", "33", "39", "40"];
    if (!!countMyTask?.length) {
      await countMyTask.forEach((row) => {
        const { questioninfo } = row;
        let chkQues = questioninfo?.some(
          (x) => x.ans_id === 0 || x.ans_id === null
        );
        let chkQuesAndRole = questioninfo?.some(
          (x) => (x.ans_id === 0 || x.ans_id === null) && x.role_id === 3
        );
        let result =
          (doc_sts_1.includes(row.doc_sts) && chkQues) ||
          (row.doc_sts === "22" && chkQuesAndRole) ||
          (doc_sts_2.includes(row.doc_sts) && chkQuesAndRole);
        totalList = [...totalList, result];
      });
    }
    let isDisplay = totalList.every((x) => x === false);
    setIsShowBadge(isDisplay);
  };

  let paddingLeft = 65;

  if (depth > 0) {
    paddingLeft = 60 + 12 * depth;
  }

  const style = {
    paddingLeft,
    width: "fit-content",
  };

  useEffect(() => {
    if (open && session?.currentMenu && session?.currentMenu !== title) {
      setOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session.currentMenu]);

  useEffect(() => {
    const isFirstTimeOpenMyTask = session?.currentMenu == null;
    const isClickTaskMenu =
      open && session?.currentMenu && session?.currentMenu === "Task";
    if (isClickTaskMenu) {
      dispatch(setCountMyTask());
      dispatch(setCountMyDraft());
    }
    if (isFirstTimeOpenMyTask && title === "My Task") {
      dispatch(setCountMyTask());
    }
    if (isFirstTimeOpenMyTask && title === "Draft") {
      dispatch(setCountMyDraft());
    }
    if (title === "Vetter Task") {
      dispatch(setCountVetterTask());
      dispatch(setCountInspectionTask());
    }
    if (title === "Sponsor Task") {
      dispatch(setCountSponsorTask());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, location.pathname]);

  useEffect(() => {
    checkBadgeShow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session?.countMyTask]);

  if (children) {
    return (
      <li key={title} className={clsx(classes.item, className)}>
        <Link
          href={href}
          onClick={handleToggle}
          className={clsx(
            classes.textLink,
            "nav-link",
            className,
            location.pathname === href ? "active" : ""
          )}
          style={depth > 0 ? style : {}}
        >
          {eleIcon ? (
            <span className="left">
              <i className={eleIcon}></i>
            </span>
          ) : (
            ""
          )}
          {title}
          {open ? (
            <ExpandLessIcon className={classes.expandIcon} color="inherit" />
          ) : (
            <ExpandMoreIcon className={classes.expandIcon} color="inherit" />
          )}
        </Link>
        <Collapse in={open}>{children}</Collapse>
      </li>
    );
  }
  const isMytask = title === "My Task";
  const isDraft = title === "Draft";
  const isVetterTask = title === "Vetter Task";
  const isSponsorTask = title === "Sponsor Task";
  if (isMytask || isDraft || isVetterTask || isSponsorTask) {
    let countValue = 0;
    let classBadge = {};
    switch (title) {
      case "My Task":
        countValue = session ? session?.countMyTask?.length ?? 0 : 0;
        classBadge = classes.contentBadge;
        break;
      case "Draft":
        countValue = session ? session?.countMyDraft?.length ?? 0 : 0;
        classBadge = classes.contentBadgeDraft;
        break;
      case "Vetter Task":
        countValue += session ? session?.countVetterTask?.length ?? 0 : 0;
        countValue += session ? session?.countInspectionTask?.length ?? 0 : 0;
        classBadge = classes.contentBadgeVetter;
        break;
      case "Sponsor Task":
        countValue = session ? session?.countSponsorTask?.length ?? 0 : 0;
        classBadge = classes.contentBadgeSponsor;
        break;
      default:
        countValue = 0;
        break;
    }
    return (
      <li
        key={title}
        className={clsx(classes.itemLeaf, className)}
        onClick={(event) => handleToggleLink(event, href)}
      >
        <Badge
          overlap="rectangular"
          variant="dot"
          color="error"
          showZero={false}
          classes={{ badge: classes.dotBadge }}
          invisible={!isMytask || isShowBadge}
        >
          <Badge
            overlap="rectangular"
            classes={{ badge: classBadge }}
            badgeContent={countValue}
            max={99}
            showZero
          >
            <Link
              href={href}
              className={clsx(
                classes.textLink,
                "nav-link",
                className,
                location.pathname === href ? "active" : ""
              )}
              style={depth > 0 ? style : {}}
            >
              {eleIcon ? (
                <span className="left">
                  <i className={eleIcon}></i>
                </span>
              ) : (
                ""
              )}
              {title}
              {Label && (
                <span className={classes.label}>
                  <Label />
                </span>
              )}
            </Link>
          </Badge>
        </Badge>
      </li>
    );
  }
  return (
    <li
      key={title}
      className={clsx(classes.itemLeaf, className)}
      onClick={(event) => handleToggleLink(event, href)}
    >
      <Link
        href={href}
        className={clsx(
          classes.textLink,
          "nav-link",
          className,
          location.pathname === href ? "active" : ""
        )}
        style={depth > 0 ? style : {}}
      >
        {eleIcon ? (
          <span className="left">
            <i className={eleIcon}></i>
          </span>
        ) : (
          ""
        )}
        {title}
        {Label && (
          <span className={classes.label}>
            <Label />
          </span>
        )}
      </Link>
    </li>
  );
}

export default NavItem;
