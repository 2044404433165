import React, { Suspense } from "react";
import { renderRoutes } from "react-router-config";
import PropTypes from "prop-types";
import { LinearProgress } from "@material-ui/core";
import TopBar from "./TopBar";

function Inspection({ route }) {
  return (
    <>
      <TopBar typePage={2}></TopBar>
      <div className="fullpage fullpage-container inspection">
        <Suspense fallback={<LinearProgress />}>
          {renderRoutes(route.routes)}
        </Suspense>
      </div>
    </>
  );
}

Inspection.propTypes = {
  route: PropTypes.object,
};

export default Inspection;
