import axios from "axios";
import { AuthService } from "src/services";
import Storage from "src/common/Storage";
import helperUtil from "src/common/utils/helperUtil";
import jwt_decode from "jwt-decode";
import moment from "moment";

const axiosApiInstance = axios.create();

// Response interceptor for API calls
axiosApiInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error?.config;
    if (error && error?.response?.status === 400 && !originalRequest._retry) {
      originalRequest._retry = true;
      const error_msg = error?.response?.data?.message;
      if (error_msg?.includes("Expire Date login")) {
        const authRes = await AuthService.refreshToken();
        if (authRes.success && authRes.data) {
          const jwt = authRes.data;
          Storage.setJWT(jwt);
          if (jwt?.token) {
            originalRequest.headers["Authorization"] = "Bearer " + jwt.token;
            originalRequest.headers["Token-Access"] = jwt.token;
          }
          return axiosApiInstance(originalRequest);
        } else {
          Storage.clear();
          window.location.href = "/auth/expire";
        }
      } else if (
        error_msg?.includes("The parameter 'token' cannot be a 'null'")
      ) {
        return Promise.reject({
          response: {
            data: {
              message: `Expire Date login [${helperUtil.getDate()}]`,
            },
          },
        });
      } else {
        const jwt = Storage.getJWT() || {};
        if (jwt.token) {
          let decoded = jwt_decode(jwt.token, { complete: true });
          if (decoded?.exp <= moment().unix().valueOf()) {
            const authRes = await AuthService.refreshToken();
            if (authRes.success) {
              const jwt = authRes.data;
              Storage.setJWT(jwt);
              if (jwt?.token) {
                originalRequest.headers["Authorization"] =
                  "Bearer " + jwt.token;
                originalRequest.headers["Token-Access"] = jwt.token;
              }
              return axiosApiInstance(originalRequest);
            }
          }
        }
      }
    }
    return Promise.reject(error);
  }
);

export default axiosApiInstance;
