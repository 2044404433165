
import { makeStyles } from '@material-ui/styles';

const styles = makeStyles((theme) => ({
    root: {},
    fields: {
      margin: theme.spacing(-1),
      display: 'flex',
      flexWrap: 'wrap',
      marginBottom: theme.spacing(2),
      '& > *': {
        flexGrow: 1,
        margin: theme.spacing(1)
      }
    },
    submitButton: {
      // marginTop: theme.spacing(2),
      width: '100%'
    },
    domainText:{
      color: '#5e6671',
      fontFamily: '"helvetica neue"',
      fontSize: '18px',
      fontWeight: 'bold',
      [theme.breakpoints.down('md')]: {
        fontSize: '15px',
      }
    }
  }));

export default styles;