import * as storageTypes from "./storageTypes";
import jwt_decode from "jwt-decode";
import helperUtil from "src/common/utils/helperUtil";
import store from 'store2';

let Storage = {};

Storage.clear = () => store.clearAll();

////////////////////// Get //////////////////////
Storage.getUser = () => {
  let result = {};

  try {
    result = JSON.parse(
      helperUtil.decryptData(store.get(storageTypes.USER))
    );
  } catch {}

  return result;
};

Storage.getJWT = () => {
  let result = null;

  try {
    result = JSON.parse(
      helperUtil.decryptData(store.get(storageTypes.JWT))
    );
  } catch {}

  return result;
};

Storage.getDataJWT = () => {
  let result = null;

  try {
    result = JSON.parse(
      helperUtil.decryptData(store.get(storageTypes.JWT_DATA))
    );
  } catch {}

  return result;
};

Storage.getCompany = () => {
  let result = {};

  try {
    result = JSON.parse(
      helperUtil.decryptData(store.get(storageTypes.COMPANY))
    );
  } catch {}

  return result;
};

Storage.getCurrentPath = () => {
  let result = null;

  try {
    result = JSON.parse(
      helperUtil.decryptData(store.get(storageTypes.PATH))
    );
  } catch {}

  return result;
};

Storage.getMenu = () => {
  let result = null;

  try {
    result = JSON.parse(
      helperUtil.decryptData(store.get(storageTypes.MENU))
    );
  } catch {}

  return result;
};

////////////////////// Set //////////////////////
Storage.setUser = (value) => {
  try {
    store.set(
      storageTypes.USER,
      helperUtil.encryptData(JSON.stringify(value))
    );
  } catch (error) {
    console.log("Storage.setUser error");
  }
};

Storage.setJWT = (value) => {
  try {
    store.set(
      storageTypes.JWT,
      helperUtil.encryptData(JSON.stringify(value))
    );
  } catch (error) {
    console.log("Storage.setJWT error");
  }
};

Storage.setDataJWT = (value) => {
  try {
    if (value?.token) {
      let decoded = jwt_decode(value?.token, { complete: true });
      if (decoded?.tokendata) {
        store.set(
          storageTypes.JWT_DATA,
          helperUtil.encryptData(JSON.stringify(JSON.parse(decoded.tokendata)))
        );
      }
    }
  } catch (error) {
    console.log("Storage.setDataJWT error");
  }
};

Storage.setCompany = (value) => {
  try {
    store.set(
      storageTypes.COMPANY,
      helperUtil.encryptData(JSON.stringify(value))
    );
  } catch (error) {
    console.log("Storage.setCompany error");
  }
};

Storage.setCurrentPath = (value) => {
  try {
    store.set(
      storageTypes.PATH,
      helperUtil.encryptData(JSON.stringify(value))
    );
  } catch (error) {
    console.log("Storage.setCurrentPath error");
  }
};

Storage.setMenu = (value) => {
  try {
    store.set(
      storageTypes.MENU,
      helperUtil.encryptData(JSON.stringify(value))
    );
  } catch (error) {
    console.log("Storage.setMenu error");
  }
};

////////////////////// Remove //////////////////////
Storage.removeCurrentPath = () => {
  try {
    store.remove(storageTypes.PATH);
  } catch (error) {
    console.log("Storage.removeCurrentPath error");
  }
};

export default Storage;
