import { axios } from "src/common/utils";
import { appSettings } from "src/configs";
import { commonHeader, authHeader } from "src/common/requestHeader";
const FaqService = {};

FaqService.searchFaq = (formData) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
      },
      method: "get",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/Faq`,
      params: { ...formData },
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

FaqService.addFaq = (formData) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
      },
      method: "post",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/Faq`,
      data: { ...formData },
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

FaqService.editFaq = (formData, id) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
      },
      method: "put",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/Faq/${id}`,
      data: { ...formData },
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

FaqService.getFaqById = (id) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
      },
      method: "get",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/Faq/${id}`,
    })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};
export default FaqService;
