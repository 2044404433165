const appSettingsAll = {
    appName: "map-web",
    platform: "web",
    appVersion: "2.0.2",
    defaultUrl: "/dashboard",
    loginUrl: "/login",
    IS_INTRANET: window.location.hostname.includes("do64008"),
    USE_SIRE_2_0: true,
};

export default appSettingsAll;
