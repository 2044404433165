import { axios } from "src/common/utils";
import { appSettings } from "src/configs";
import { commonHeader, authHeader } from "src/common/requestHeader";
import { ALL_STRING } from "src/common/utils/StringUtil";

const WORD_INPUT_INVALID = ALL_STRING.WORD_INPUT_INVALID;

const VesselService = {};

VesselService.searchvessel = (formData) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
      },
      method: "get",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/vessel`,
      params: { ...formData },
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

VesselService.getvessel = (id) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
      },
      method: "get",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/vessel/${id}`,
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

VesselService.getvesselsupportinfo = (id) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
      },
      method: "get",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/vessel/${id}/supportinfo`,
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

VesselService.addvessel = (formData) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
      },
      method: "post",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/vessel`,
      data: { ...formData },
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

VesselService.updateVessel = (formData, vesselId) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
      },
      method: "put",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/vessel/${vesselId}`,
      data: formData,
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

VesselService.getvesselrequest = (imo) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
      },
      method: "get",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/vessel/search/${imo}`,
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

VesselService.getcrewmatrixSire = (props = {}) => {
  const { id } = props;
  if (!id) {
    return new Promise((resolve, reject) =>
      resolve({ message: WORD_INPUT_INVALID })
    );
  }
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
      },
      method: "get",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/vessel/CrewMetrix/${id}`,
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

VesselService.getcrewmatrix = (props = {}) => {
  const { id, type } = props;
  if (!id || !type) {
    return new Promise((resolve, reject) =>
      resolve({ message: WORD_INPUT_INVALID })
    );
  }
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
      },
      method: "get",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/vessel/CrewMetrix/${type}/${id}`,
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

VesselService.getVPQ = (id) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
      },
      method: "get",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/Vessel/VPQ/${id}`,
      responseType: "blob",
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

VesselService.getCrewMatrixShipOwner = (imo) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
      },
      method: "get",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/Vessel/CrewMatrix/ShipOwner/${imo}`,
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

VesselService.addCrewMatrixShipOwner = (formData) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        ...commonHeader(),
        ...authHeader(),
      },
      method: "post",
      url: `${appSettings.services.map.apiUrl}/api/v1.0/Vessel/CrewMatrix/ShipOwner`,
      data: { ...formData },
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

export default VesselService;
