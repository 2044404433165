/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import Storage from "src/common/Storage";
// import { colors } from '@material-ui/core';
// import BarChartIcon from '@material-ui/icons/BarChart';
// import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
// import ChatIcon from '@material-ui/icons/ChatOutlined';
// import CodeIcon from '@material-ui/icons/Code';
// import DashboardIcon from '@material-ui/icons/DashboardOutlined';
// import ErrorIcon from '@material-ui/icons/ErrorOutline';
// import FolderIcon from '@material-ui/icons/FolderOutlined';
// import HomeIcon from '@material-ui/icons/HomeOutlined';
// import ListAltIcon from '@material-ui/icons/ListAlt';
// import LockOpenIcon from '@material-ui/icons/LockOpenOutlined';
// import MailIcon from '@material-ui/icons/MailOutlined';
// import PresentToAllIcon from '@material-ui/icons/PresentToAll';
// import PeopleIcon from '@material-ui/icons/PeopleOutlined';
// import PersonIcon from '@material-ui/icons/PersonOutlined';
// import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
// import SettingsIcon from '@material-ui/icons/SettingsOutlined';
// import ViewConfigIcon from '@material-ui/icons/ViewComfy';
// import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
// import ListIcon from '@material-ui/icons/List';
// import Label from 'src/components/Label/Label.Component';

// const getMenuList = () => {
//     let result = [];
//     result = 
//     return result;
// } 
const navConfig = [
        {
            subheader: "Pages",
            items: [
                ...Storage.getMenu() ?? []
            ]
        }
]
export default navConfig;

// export default [
//     {
//         subheader: "Pages",
//         items: [
//             {
//                 title: "ADD REQUEST",
//                 href: "/addRequest",
//                 icon: undefined,
//                 //icon:HomeIcon
//                 eleIcon: <i className="fas fa-plus-circle"></i>,
//                 className: "nav-btn",
//                 items: []
//             },
//             {
//                 title: "My Tasks",
//                 href: "/mytasks",
//                 eleIcon: <i className="icon icon-doc"></i>,
//             },
//             {
//                 title: "Draft",
//                 href: "/draft",
//                 eleIcon: <i className="icon icon-pen2"></i>,
//             },
//             {
//                 title: "Terminal Task",
//                 href: "/myterminalscreening",
//                 eleIcon: <i className="icon icon-text"></i>,
//             },
//             {
//                 title: "Vetter Task",
//                 href: "/vetterTask",
//                 eleIcon: <i className="icon icon-text"></i>,
//                 items: [
//                     {
//                         title: "Vetting Task",
//                         href: "/vettingtask",
//                     },
//                     {
//                         title: "Inspection Task",
//                         href: "/inspectiontask",
//                     },
//                 ],
//             },
//             {
//                 title: "Appealing",
//                 href: "/appealing",
//                 eleIcon: <i className="icon icon-appeal"></i>,
//             },
//             // {
//             //     title: "Appealing Detail",
//             //     href: "/appealing-info",
//             //     eleIcon: <i className="icon icon-appeal"></i>,
//             // },
//             {
//                 title: "Add Vessel",
//                 href: "/addVessel",
//                 eleIcon: <i className="icon icon-vessel-add"></i>,
//             },
//             {
//                 title: "Search",
//                 href: "/search",
//                 eleIcon: <i className="icon icon-search2"></i>,
//                 items: [
//                     {
//                         title: "Vetting Request",
//                         href: "/requestsearch",
//                     },
//                     {
//                         title: "Inspection Request",
//                         href: "/inspectionsearch",
//                     },
//                     {
//                         title: "Vessel Data",
//                         href: "/vesselsearch",
//                     },
//                 ],
//             },
//             {
//                 title: "Supported Info.",
//                 href: "/supportinfo",
//                 eleIcon: <i className="icon icon-text"></i>,
//                 items: [
//                     {
//                         title: "Terminal Feedback",
//                         href: "/terminalfeedback",
//                     },
//                     {
//                         title: "Industry Information",
//                         href: "/industryinformation",
//                     },
//                     {
//                         title: "Incident Report",
//                         href: "/incidentreport",
//                     },
//                     {
//                         title: "Port State",
//                         href: "/portstate",
//                     },
//                     {
//                         title: "Change in Status",
//                         href: "/changeinstatus",
//                     },
//                     {
//                         title: "Certificate",
//                         href: "/certificate",
//                     },
//                     {
//                         title: "Owner Performance",
//                         href: "/ownerperformance",
//                     },
//                     {
//                         title: "Crew Management",
//                         href: "/crewmanagement",
//                     },
//                     {
//                         title: "Structural Condition",
//                         href: "/structuralcondition",
//                     },
//                     {
//                         title: "SIRE Report",
//                         href: "/purchasesire",
//                     },
//                 ],
//             },
//             // {
//             //     title: "Add Supported Info.",
//             //     href: "/supportinfodetail",
//             //     eleIcon: <i className="icon icon-text"></i>,
//             //     items: [
//             //         {
//             //             title: "Terminal Feedback Detail",
//             //             href: "/terminalfeedback/add",
//             //         },
//             //         {
//             //             title: "Industry Information Detail",
//             //             href: "/industryinformation/add",
//             //         },
//             //         {
//             //             title: "Incident Report Detail",
//             //             href: "/incidentreport/add",
//             //         },
//             //         {
//             //             title: "Port State Detail",
//             //             href: "/portstate/add",
//             //         },
//             //         {
//             //             title: "Change in Status Detail",
//             //             href: "/changeinstatus/add",
//             //         },
//             //         // {
//             //         //     title: "Certificate Detail",
//             //         //     href: "/certificate/add",
//             //         // },
//             //         {
//             //             title: "Owner Performance Detail",
//             //             href: "/ownerperformance/add",
//             //         },
//             //         // {
//             //         //     title: "Crew Management Detail",
//             //         //     href: "/crewmanagement/add",
//             //         // },
//             //         {
//             //             title: "Structural Condition Detail",
//             //             href: "/structuralcondition/add",
//             //         },
//             //         // {
//             //         //     title: "SIRE Report",
//             //         //     href: "/purchasesire",
//             //         // },
//             //     ],
//             // },
//             {
//                 title: "Supported Info. Search",
//                 href: "/supportinfosearch",
//                 eleIcon: <i className="icon icon-vessel-add"></i>,
//             },
//             {
//                 title: "Report",
//                 href: "/report",
//                 //icon: HomeIcon,
//                 eleIcon: <i className="icon icon-text"></i>,
//                 items: [
//                     {
//                         title: "Vessel Nomination",
//                         href: "/vesselnomination",
//                     },
//                     {
//                         title: "Vessel Monitoring",
//                         href: "/vesselmonitoring",
//                     },
//                     {
//                         title: "Number of requests by Company",
//                         href: "/numberofrequest",
//                     },
//                     {
//                         title: "Inspection Report Purchased",
//                         href: "/inspectionreport",
//                     },
//                     {
//                         title: "Vessel Information Report",
//                         href: "/vesselinformationreport",
//                     },
//                     {
//                         title: "Company Information",
//                         href: "/companyinformation",
//                     },
//                     {
//                         title: "Purchase SIRE – History Report",
//                         href: "/purchasesirehistoryreport",
//                     },
//                     {
//                         title: "Questionnaire",
//                         href: "/questionnaire",
//                     },
//                     {
//                         title: "Requested by Member",
//                         href: "/requestedmember",
//                     },
//                     {
//                         title: "Requested by Flag",
//                         href: "/requestedflag",
//                     },
//                     {
//                         title: "Flag by Member",
//                         href: "/flagbymember",
//                     },
//                     {
//                         title: "Status by Member",
//                         href: "/statusbymember",
//                     },
//                     {
//                         title: "Status Summary",
//                         href: "/statussummary",
//                     },
//                     {
//                         title: "Rejected at Screening",
//                         href: "/rejected",
//                     },
//                     {
//                         title: "Not Released",
//                         href: "/notreleased",
//                     },
//                     {
//                         title: "Inspector Assignment Report",
//                         href: "/inspectorreport",
//                     },
//                     {
//                         title: "Risk Assessment Summary Report",
//                         href: "/riskassessmentreport",
//                     },
//                 ],
//             },
//             // {
//             //     title: "CrewMatrix",
//             //     href: "/crewmatrix",
//             //     eleIcon: <i className="icon icon-text"></i>,
//             // },
//             {
//                 title: "Add Inspection Request",
//                 href: "/requestvesselinspection",
//                 eleIcon: <i className="icon icon-vessel-add"></i>,
//             },
//             // {
//             //     title: "Add Inspection Report",
//             //     href: "/addinspectionreport",
//             //     eleIcon: <i className="icon icon-text"></i>,
//             // },
//             {
//                 title: "Recently Booking",
//                 href: "/recentlybooking",
//                 eleIcon: <i className="icon icon-text"></i>,
//             },
//             {
//                 title: "User Management",
//                 href: "/userlist",
//                 eleIcon: <i className="icon icon-text"></i>,
//                 items: [
//                     {
//                         title: "User List",
//                         href: "/userlist",
//                     },
//                 ],
//             },
//         ],
//     },
// ];
