import { appSettings } from "../configs";
import Storage from "../common/Storage";
import helperUtil from "src/common/utils/helperUtil";

export function authHeader() {
    let jwt = Storage.getJWT();

    let header = {
        "Access-Control-Allow-Origin": "*",
    };

    if (jwt && jwt.token) {
        header["Authorization"] = "Bearer " + jwt.token;
    }

    return header;
}

export function commonHeader() {
    const user = Storage.getUser();
    let jwt = Storage.getJWT();

    let header = {
        "Access-Control-Allow-Origin": "*",
    };

    header["X-AppName"] = appSettings.appName;
    header["X-Platform"] = appSettings.platform;

    if (helperUtil.checkHasObject(user)) {
        header["X-UserId"] = user.id;
        header["X-UserName"] = user.user_name;
    }
    if (jwt && jwt.token) {
        header["Token-Access"] = jwt.token;
    }

    return header;
}
