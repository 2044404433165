import { axios } from "src/common/utils";
const ThirdPartyService = {};

ThirdPartyService.ipApi = () => {
    return new Promise((resolve, reject) => {
        axios({
            method: "get",
            url: `https://ipapi.co/json`,
        })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
};

ThirdPartyService.ipInfo = () => {
    return new Promise((resolve, reject) => {
        axios({
            method: "get",
            url: `https://ipinfo.io/json?token=0e4e4096b16f30`,
        })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
};

export default ThirdPartyService;
