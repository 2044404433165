import { axios } from "src/common/utils";
import { appSettings } from "src/configs";
import { commonHeader, authHeader } from "src/common/requestHeader";

const ReportService = {};

ReportService.vesselNomination = (formData) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
                Pragma: "no-cache",
            },
            method: "get",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/Report/VesselNomination`,
            params: { ...formData },
        })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
};

ReportService.numberOfRequest = (formData) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
                Pragma: "no-cache",
            },
            method: "get",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/Report/NumberOfRequest`,
            params: { ...formData },
        })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
};

ReportService.inspectionReportPurchased = (formData) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
                Pragma: "no-cache",
            },
            method: "get",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/Report/InspectionReportPurchased`,
            params: { ...formData },
        })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
};

ReportService.vesselInformation = (formData) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
                Pragma: "no-cache",
            },
            method: "get",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/Report/VesselInformation`,
            params: { ...formData },
        })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
};

ReportService.RiskAssessment = (formData) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
                Pragma: "no-cache",
            },
            method: "get",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/Report/RiskAssessment`,
            params: { ...formData },
        })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
};

ReportService.companyInformation = (formData) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
                Pragma: "no-cache",
            },
            method: "get",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/Report/CompanyInformation`,
            params: { ...formData },
        })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
};

ReportService.requestedByMember = (formData) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
                Pragma: "no-cache",
            },
            method: "get",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/Report/RequestedByMember`,
            params: { ...formData },
        })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
};

ReportService.requestedByFlag = (formData) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
                Pragma: "no-cache",
            },
            method: "get",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/Report/RequestedByFlag`,
            params: { ...formData },
        })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
};

ReportService.flagByMember = (formData) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
                Pragma: "no-cache",
            },
            method: "get",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/Report/FlagByMember`,
            params: { ...formData },
        })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
};

ReportService.statusByMember = (formData) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
                Pragma: "no-cache",
            },
            method: "get",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/Report/StatusByMember`,
            params: { ...formData },
        })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
};

ReportService.statusSummary = (formData) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
                Pragma: "no-cache",
            },
            method: "get",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/Report/StatusSummary`,
            params: { ...formData },
        })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
};

ReportService.rejectedAtScreening = (formData) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
                Pragma: "no-cache",
            },
            method: "get",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/Report/RejectedAtScreening`,
            params: { ...formData },
        })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
};

ReportService.notReleased = (formData) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
                Pragma: "no-cache",
            },
            method: "get",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/Report/NotReleased`,
            params: { ...formData },
        })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
};

ReportService.rawData = (formData) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
                Pragma: "no-cache",
            },
            method: "get",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/Report/RawData`,
            params: { ...formData },
        })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
};

ReportService.inspectionAssignment = (formData) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
                Pragma: "no-cache",
            },
            method: "get",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/Report/InspectionAssignment`,
            params: { ...formData },
        })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
};

export default ReportService;
