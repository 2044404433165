import React from "react";

const Image = (props) => {
  const {
    className = {},
    style = {},
    path = "",
    alt = "",
    onError = () => {},
  } = props;
  return (
    <>
      <img
        className={className}
        style={style}
        src={path}
        alt={alt}
        onError={onError}
      />
    </>
  );
};

export default Image;
