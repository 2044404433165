/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import AuthLayout from "./layouts/Auth";
import ErrorLayout from "./layouts/Error";
import FullLayout from "./layouts/Full";
import AnswerShipLayout from "./layouts/Inspection/AnswerShipLayout";
import CrewShipLayout from "./layouts/Inspection/CrewShipLayout";
import MainScreen from "./screens/Main/MainScreen";
export default [
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/main" />,
  },
  {
    path: "/auth",
    component: AuthLayout,
    routes: [
      {
        path: "/auth/login",
        exact: true,
        component: lazy(() => import("./screens/Login/LoginScreen")),
      },
      {
        path: "/auth/resetpassword",
        exact: true,
        component: lazy(() => import("./screens/ResetPassword/ResetPassword")),
      },
      {
        path: "/auth/expire",
        exact: true,
        component: lazy(() =>
          import("./screens/SessionExpire/SessionExpireScreen")
        ),
      },
      {
        path: "/auth/azure",
        exact: true,
        component: lazy(() => import("./screens/Login/LoginAzure")),
      },
      {
        component: () => <Redirect to="/errors/error-404" />,
      },
    ],
  },
  {
    path: "/errors",
    component: ErrorLayout,
    routes: [
      {
        path: "/errors/401",
        exact: true,
        component: lazy(() => import("./screens/Error/Error401Screen")),
      },
      {
        path: "/errors/404",
        exact: true,
        component: lazy(() => import("./screens/Error/Error404Screen")),
      },
      {
        path: "/errors/500",
        exact: true,
        component: lazy(() => import("./screens/Error/Error500Screen")),
      },
      {
        component: () => <Redirect to="/errors/error-404" />,
      },
    ],
  },
  {
    path: "/inspection/AnswerShipOwner/:id",
    component: AnswerShipLayout,
    routes: [
      {
        path: "/inspection/AnswerShipOwner/:id",
        exact: true,
        component: lazy(() => import("src/inspection/screens/AnswerShipOwner")),
      },
      {
        component: () => <Redirect to="/errors/error-404" />,
      },
    ],
  },
  {
    path: "/inspection/CrewMatrixShipOwner/:id",
    component: CrewShipLayout,
    routes: [
      {
        path: "/inspection/CrewMatrixShipOwner/:id",
        exact: true,
        component: lazy(() =>
          import("src/inspection/screens/VesselCrewMatrix")
        ),
      },
      {
        component: () => <Redirect to="/errors/error-404" />,
      },
    ],
  },
  {
    path: "/VPQ",
    component: ErrorLayout,
    showLayout: false,
    routes: [
      {
        path: "/VPQ/File/:id",
        exact: true,
        component: lazy(() => import("src/screens/OpenVPQ/OpenVPQScreen")),
      },
      {
        component: () => <Redirect to="/errors/error-404" />,
      },
    ],
  },
  {
    path: "/UserManual",
    component: ErrorLayout,
    showLayout: false,
    routes: [
      {
        path: "/UserManual",
        exact: true,
        component: lazy(() =>
          import("src/screens/UserManual/UserManualScreen")
        ),
      },
      {
        component: () => <Redirect to="/errors/error-404" />,
      },
    ],
  },
  {
    path: "/InsProgramManual",
    component: ErrorLayout,
    showLayout: false,
    routes: [
      {
        path: "/InsProgramManual",
        exact: true,
        component: lazy(() =>
          import("src/screens/InsProgramManual/InsProgramManualScreen")
        ),
      },
      {
        component: () => <Redirect to="/errors/error-404" />,
      },
    ],
  },
  {
    path: "/CrewShipManual",
    component: ErrorLayout,
    showLayout: false,
    routes: [
      {
        path: "/CrewShipManual",
        exact: true,
        component: lazy(() =>
          import("src/screens/CrewShipManual/CrewShipManualScreen")
        ),
      },
      {
        component: () => <Redirect to="/errors/error-404" />,
      },
    ],
  },
  {
    path: "/AnswerShipManual",
    component: ErrorLayout,
    showLayout: false,
    routes: [
      {
        path: "/AnswerShipManual",
        exact: true,
        component: lazy(() =>
          import("src/screens/AnswerShipManual/AnswerShipManualScreen")
        ),
      },
      {
        component: () => <Redirect to="/errors/error-404" />,
      },
    ],
  },
  {
    path: "/ShipOperManual",
    component: ErrorLayout,
    showLayout: false,
    routes: [
      {
        path: "/ShipOperManual",
        exact: true,
        component: lazy(() =>
          import("src/screens/ShipOperManual/ShipOperManualScreen")
        ),
      },
      {
        component: () => <Redirect to="/errors/error-404" />,
      },
    ],
  },
  {
    route: "*",
    component: FullLayout,
    routes: [
      // {
      //   path: "/Blank",
      //   exact: true,
      //   component: lazy(() => import("src/screens/Blank/BlankScreen")),
      //   canFav: true,
      // },
      {
        path: "/InspectionReport/:id",
        exact: true,
        component: lazy(() =>
          import("src/screens/SummaryInspection/SummaryInspectionScreen")
        ),
      },
      {
        path: "/Vessel/Detail/:id",
        exact: true,
        component: lazy(() =>
          import("src/screens/SupportInfoWorkflow/SupportInfoWorkflowScreen")
        ),
        canFav: true,
      },
      {
        path: "/Vessel/VPQ/:id",
        exact: true,
        component: lazy(() => import("src/screens/OpenVPQ/OpenVPQScreen")),
      },
      {
        path: "/main",
        exact: true,
        component: MainScreen,
      },
      {
        path: "/blank",
        exact: true,
        component: lazy(() => import("src/screens/Blank/BlankScreen")),
      },
      {
        path: "/addRequest",
        exact: true,
        component: lazy(() =>
          import("src/screens/VettingRequestForm/VettingRequestFormScreen")
        ),
        defaultStep: 0,
      },
      {
        path: "/request/:id",
        exact: true,
        component: lazy(() =>
          import("src/screens/VettingRequestForm/VettingRequestFormScreen")
        ),
        defaultStep: 0,
      },
      {
        path: "/supportinfo/:id",
        exact: true,
        component: lazy(() =>
          import("src/screens/VettingRequestForm/VettingRequestFormScreen")
        ),
        defaultStep: 1,
      },
      {
        path: "/terminalscreening/:id",
        exact: true,
        component: lazy(() =>
          import("src/screens/VettingRequestForm/VettingRequestFormScreen")
        ),
        defaultStep: 2,
      },
      {
        path: "/vettingclearance/:id",
        exact: true,
        component: lazy(() =>
          import("src/screens/VettingRequestForm/VettingRequestFormScreen")
        ),
        defaultStep: 3,
      },
      {
        path: "/finalrelease/:id",
        exact: true,
        component: lazy(() =>
          import("src/screens/VettingRequestForm/VettingRequestFormScreen")
        ),
        defaultStep: 4,
      },
      {
        path: "/appealing/:id",
        exact: true,
        component: lazy(() =>
          import("src/screens/VettingRequestForm/VettingRequestFormScreen")
        ),
        defaultStep: 5,
      },
      {
        path: "/mytasks",
        exact: true,
        component: lazy(() => import("src/screens/MyTasks")),
      },
      {
        path: "/draft",
        exact: true,
        component: lazy(() => import("src/screens/Draft")),
      },
      {
        path: "/myterminalscreening",
        exact: true,
        component: lazy(() =>
          import("src/screens/MyTerminalScreening/MyTerminalScreeningScreen")
        ),
      },
      {
        path: "/appealing",
        exact: true,
        component: lazy(() => import("src/screens/Appealing")),
      },
      {
        path: "/requestsearch",
        exact: true,
        component: lazy(() => import("src/screens/Search")),
      },
      {
        path: "/inspectionsearch",
        exact: true,
        component: lazy(() => import("src/screens/Search")),
        defaultTab: 1,
      },
      {
        path: "/vesselsearch",
        exact: true,
        component: lazy(() => import("src/screens/Search")),
        defaultTab: 2,
      },
      {
        path: "/terminalfeedback",
        exact: true,
        component: lazy(() =>
          import("src/screens/SupportInfo/TerminalFeedbackScreen")
        ),
      },
      {
        path: "/industryinformation",
        exact: true,
        component: lazy(() =>
          import("src/screens/SupportInfo/IndustryInfoscreen")
        ),
        statusAccess: 0,
      },
      {
        path: "/casualtyreport",
        exact: true,
        component: lazy(() =>
          import("src/screens/SupportInfo/IncidentReportScreen")
        ),
      },
      {
        path: "/portstate",
        exact: true,
        component: lazy(() =>
          import("src/screens/SupportInfo/PortstateScreen")
        ),
      },
      {
        path: "/changeinstatus",
        exact: true,
        component: lazy(() =>
          import("src/screens/SupportInfo/ChangeInStatusScreen")
        ),
      },
      {
        path: "/certificate",
        exact: true,
        component: lazy(() =>
          import("src/screens/SupportInfo/CertificateScreen")
        ),
      },
      {
        path: "/tmsa",
        exact: true,
        component: lazy(() =>
          import("src/screens/SupportInfo/OwnerPerformanceScreen")
        ),
      },
      {
        path: "/crewmanagement",
        exact: true,
        component: lazy(() =>
          import("src/screens/SupportInfo/CrewManagementScreen")
        ),
      },
      {
        path: "/structuralcondition",
        exact: true,
        component: lazy(() =>
          import("src/screens/SupportInfo/StructuralConditionScreen")
        ),
      },
      {
        path: "/addvessel",
        exact: true,
        component: lazy(() => import("src/screens/AddVessel/AddVesselScreen")),
      },
      {
        path: "/requestedmember",
        exact: true,
        component: lazy(() => import("src/screens/Report/RequestedbyMember")),
      },
      {
        path: "/requestedflag",
        exact: true,
        component: lazy(() => import("src/screens/Report/RequestedbyFlag")),
      },
      {
        path: "/flagbymember",
        exact: true,
        component: lazy(() => import("src/screens/Report/FlagbyMember")),
      },
      {
        path: "/statusbymember",
        exact: true,
        component: lazy(() => import("src/screens/Report/StatusbyMember")),
      },
      {
        path: "/statussummary",
        exact: true,
        component: lazy(() => import("src/screens/Report/StatusSummary")),
      },
      {
        path: "/rejected",
        exact: true,
        component: lazy(() => import("src/screens/Report/Rejected")),
      },
      {
        path: "/notreleased",
        exact: true,
        component: lazy(() => import("src/screens/Report/NotReleased")),
      },
      {
        path: "/rawdata",
        exact: true,
        component: lazy(() => import("src/screens/Report/RawData")),
      },
      {
        path: "/Vesselnomination",
        exact: true,
        component: lazy(() => import("src/screens/Report/VesselNomination")),
      },
      {
        path: "/numberofrequest",
        exact: true,
        component: lazy(() => import("src/screens/Report/NumberOfRequest")),
      },
      {
        path: "/inspectionreport",
        exact: true,
        component: lazy(() => import("src/screens/Report/Inspection")),
      },
      {
        path: "/vesselinformationreport",
        exact: true,
        component: lazy(() => import("src/screens/Report/VesselInfoReport")),
      },
      {
        path: "/companyinformation",
        exact: true,
        component: lazy(() => import("src/screens/Report/CompanyInformation")),
      },
      {
        path: "/purchasesirehistoryreport",
        exact: true,
        component: lazy(() =>
          import("src/screens/SIRE/PurchaseSIREHistoryScreen")
        ),
      },
      {
        path: "/profile",
        exact: true,
        component: lazy(() =>
          import("src/screens/UserManagement/EditProfileScreen")
        ),
        statusAccess: 0,
      },
      {
        path: "/profile/:id",
        exact: true,
        component: lazy(() =>
          import("src/screens/UserManagement/EditProfileScreen")
        ),
        statusAccess: 1,
      },
      {
        path: "/requestvesselinspection",
        exact: true,
        component: lazy(() =>
          import("src/screens/Request/RequestVesselInspectionScreen")
        ),
        statusAccess: 0,
      },
      {
        path: "/requestvesselinspection/:id",
        exact: true,
        component: lazy(() =>
          import("src/screens/Request/RequestVesselInspectionScreen")
        ),
        statusAccess: 1,
      },
      {
        path: "/supportinfosearch",
        exact: true,
        component: lazy(() =>
          import("src/screens/SupportInfoSearch/SupportInfoSearchScreen")
        ),
      },
      {
        path: "/enqvettingclearance",
        exact: true,
        component: lazy(() => import("src/components/VetterTask/En-qScreen")),
      },
      {
        path: "/vettingtask",
        exact: true,
        component: lazy(() => import("src/screens/VetterTask")),
      },
      {
        path: "/inspectiontask",
        exact: true,
        component: lazy(() => import("src/screens/VetterTask")),
        defaultTab: 1,
      },
      {
        path: "/sponsortask",
        exact: true,
        component: lazy(() => import("src/screens/SponsorTask")),
      },
      {
        path: "/shipvetting",
        exact: true,
        component: lazy(() => import("src/screens/Report/ShipVetting")),
      },
      {
        path: "/shipaccident",
        exact: true,
        component: lazy(() => import("src/screens/Report/ShipAccident")),
      },
      {
        path: "/shipinspection",
        exact: true,
        component: lazy(() => import("src/screens/Report/ShipInspection")),
      },
      {
        path: "/vesselmonitoring",
        exact: true,
        component: lazy(() => import("src/screens/Report/VesselMonitoring")),
      },
      {
        path: "/recentlybooking",
        exact: true,
        component: lazy(() => import("src/screens/RecentlyBooking")),
      },
      {
        path: "/inspectorreport",
        exact: true,
        component: lazy(() => import("src/screens/Report/InspectorReport")),
      },
      {
        path: "/riskassessmentreport",
        exact: true,
        component: lazy(() =>
          import("src/screens/Report/RiskAssessmentReport")
        ),
      },
      {
        path: "/userlist",
        exact: true,
        component: lazy(() => import("src/screens/UserManagement/UserList")),
      },
      {
        path: "/adduser",
        exact: true,
        component: lazy(() =>
          import("src/screens/UserManagement/AddProfileScreen")
        ),
      },
      {
        path: "/vesselList",
        exact: true,
        component: lazy(() =>
          import("src/screens/VesselManagement/VesselList")
        ),
      },
      {
        path: "/editVessel/:id",
        exact: true,
        component: lazy(() => import("src/screens/AddVessel/AddVesselScreen")),
      },
      {
        path: "/configQuestion",
        exact: true,
        component: lazy(() => import("src/screens/Config/QuestionScreen")),
      },
      {
        path: "/configTerminalBerth",
        exact: true,
        component: lazy(() => import("src/screens/Config/TerminalBerthScreen")),
      },
      {
        path: "/configTerminal",
        exact: true,
        component: lazy(() => import("src/screens/Config/TerminalScreen")),
      },
      {
        path: "/configMenu",
        exact: true,
        component: lazy(() => import("src/screens/Config/MenuScreen")),
      },
      {
        path: "/configMenuRole",
        exact: true,
        component: lazy(() => import("src/screens/Config/MenuRoleScreen")),
      },
      {
        path: "/configClassification",
        exact: true,
        component: lazy(() =>
          import("src/screens/Config/ClassificationScreen")
        ),
      },
      {
        path: "/configCompany",
        exact: true,
        component: lazy(() => import("src/screens/Config/CompanyScreen")),
      },
      {
        path: "/configFlag",
        exact: true,
        component: lazy(() => import("src/screens/Config/FlagScreen")),
      },
      {
        path: "/configProduct",
        exact: true,
        component: lazy(() => import("src/screens/Config/ProductScreen")),
      },
      {
        path: "/configExpireReport",
        exact: true,
        component: lazy(() => import("src/screens/Config/ExpireReportScreen")),
      },
      {
        path: "/configDataSystem",
        react: true,
        component: lazy(() => import("src/screens/Config/DataSystemScreen")),
      },
      {
        path: "/configMasterGroup",
        react: true,
        component: lazy(() => import("src/screens/Config/MasterGroupScreen")),
      },
      {
        path: "/ShipAccident",
        exact: true,
        component: lazy(() => import("src/screens/Report/ShipAccident")),
      },
      {
        path: "/ShipInspection",
        exact: true,
        component: lazy(() => import("src/screens/Report/ShipInspection")),
      },
      {
        path: "/ShipVetting",
        exact: true,
        component: lazy(() => import("src/screens/Report/ShipVetting")),
      },
      {
        path: "/ClipRequester",
        exact: true,
        component: lazy(() =>
          import("src/screens/VideoTraining/VideoTrainingRequesterScreen")
        ),
      },
      {
        path: "/ClipWorkflow",
        exact: true,
        component: lazy(() =>
          import("src/screens/VideoTraining/VideoTrainingWorkflowScreen")
        ),
      },
      {
        path: "/faq",
        exact: true,
        component: lazy(() => import("src/screens/Faq")),
      },
      {
        path: "/ReportInspectionGrade/:id",
        exact: true,
        component: lazy(() => import("src/screens/ReportInspectionGradeList")),
      },
      {
        component: () => <Redirect to="/errors/404" />,
      },
    ],
  },
];
