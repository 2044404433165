import * as actionTypes from "./actionTypes";

import {
  AuthService,
  CompanyService,
  CountryService,
  LookupService,
  TerminalService,
  ClassificationService,
  ProductService,
  BerthService,
  UserService,
  DocStsService,
  MQFormService,
  RequestService,
  RoleService,
  BusinessUnitService,
  ConfigService,
} from "src/services";
import jwt_decode from "jwt-decode";

export const currentMenu = (menu) => (dispatch) => {
  dispatch({
    type: actionTypes.SESSION_CURRENT_MENU,
    payload: menu,
  });
};

export const login = (user) => (dispatch) => {
  // const user = await UserService.login(userName, password);
  dispatch({
    type: actionTypes.SESSION_LOGIN,
    payload: user,
  });
};

export const logout = (refreshToken) => (dispatch) => {
  try {
    if (refreshToken) {
      AuthService.logout(refreshToken);
    }
  } catch (error) {
    console.log("logout error: ", error);
  }

  dispatch({
    type: actionTypes.SESSION_LOGOUT,
  });
};

export const addCompanyList =
  (
    formData = {
      sortBy: 0,
      ascending: true,
    }
  ) =>
  async (dispatch) => {
    const list = await CompanyService.searchcompany(formData);
    dispatch({
      type: actionTypes.SESSION_COMPANY,
      payload: list?.data?.data,
    });
  };

export const addCountryList = () => async (dispatch) => {
  const list = await CountryService.searchcountry();
  dispatch({
    type: actionTypes.SESSION_COUNTRY,
    payload: list?.data,
  });
};

export const addCargoList = () => async (dispatch) => {
  const list = await LookupService.searchcargo();
  dispatch({
    type: actionTypes.SESSION_CARGO,
    payload: list?.data,
  });
};

export const addTerminalList = () => async (dispatch) => {
  const list = await TerminalService.searchterminal();
  dispatch({
    type: actionTypes.SESSION_TERMINAL,
    payload: list?.data,
  });
};

export const addOpertionList = () => async (dispatch) => {
  const list = await LookupService.searchopertion();
  dispatch({
    type: actionTypes.SESSION_OPERTION,
    payload: list?.data,
  });
};

export const addNationalityList = () => async (dispatch) => {
  const list = await LookupService.searchnationality();
  dispatch({
    type: actionTypes.SESSION_NATIONALITY,
    payload: list?.data,
  });
};

export const addClassificationList = () => async (dispatch) => {
  const list = await ClassificationService.searchclassification();
  dispatch({
    type: actionTypes.SESSION_CLASSIFICATION,
    payload: list?.data,
  });
};

export const addVesselTypeList = () => async (dispatch) => {
  const list = await LookupService.searchvesseltype();
  dispatch({
    type: actionTypes.SESSION_VESSELTYPE,
    payload: list?.data,
  });
};

export const addProductList = () => async (dispatch) => {
  const list = await ProductService.searchproduct();
  dispatch({
    type: actionTypes.SESSION_PRODUCT,
    payload: list?.data,
  });
};

export const addUnitList = () => async (dispatch) => {
  const list = await LookupService.searchunit();
  dispatch({
    type: actionTypes.SESSION_UNIT,
    payload: list?.data,
  });
};

export const addVesselContractList = () => async (dispatch) => {
  const list = await LookupService.searchvesselcontract();
  dispatch({
    type: actionTypes.SESSION_VESSEL_CONTACT_LIST,
    payload: list?.data,
  });
};

export const addTypeOfTransactionList = () => async (dispatch) => {
  const list = await LookupService.searchtypeoftransaction();
  dispatch({
    type: actionTypes.SESSION_TYPE_OF_TRANSACTION,
    payload: list?.data,
  });
};

export const addBerthList = () => async (dispatch) => {
  const list = await BerthService.searchberth();
  dispatch({
    type: actionTypes.SESSION_BERTH,
    payload: list?.data,
  });
};

export const addUserList = (formData) => async (dispatch) => {
  const response = await UserService.getUsers(formData);
  const { data: list } = response;

  dispatch({
    type: actionTypes.SESSION_USER,
    payload: list?.data,
  });
};

export const addUserTerminalList = (formData) => async (dispatch) => {
  const response = await UserService.getTerminalUsers(formData);
  const { data: list } = response;

  dispatch({
    type: actionTypes.SESSION_USER_TERMINAL,
    payload: list?.data,
  });
};

export const addUserVetterList = (formData) => async (dispatch) => {
  const response = await UserService.getVetterUsers(formData);
  const { data: list } = response;

  dispatch({
    type: actionTypes.SESSION_USER_VETTER,
    payload: list?.data,
  });
};

export const addBecausseList = () => async (dispatch) => {
  const list = await LookupService.searchbecause();

  dispatch({
    type: actionTypes.SESSION_BECAUSE,
    payload: list?.data,
  });
};

export const addBecausseFinalReleaseList = () => async (dispatch) => {
  const list = await LookupService.searchbecausefinalrelease();

  dispatch({
    type: actionTypes.SESSION_BECAUSE_FINALERELEASE,
    payload: list?.data,
  });
};

export const setTokenData = (token) => (dispatch) => {
  if (token) {
    let decoded = jwt_decode(token, { complete: true });
    if (decoded?.tokendata) {
      dispatch({
        type: actionTypes.SESSION_TOKENDATA,
        payload: JSON.parse(decoded.tokendata),
      });
    }
  }
};

export const setRequestDetail = (request) => (dispatch) => {
  dispatch({
    type: actionTypes.SESSION_REQUEST_CUREENT,
    payload: request,
  });
};

export const setSupportInfoVessel = (data) => (dispatch) => {
  dispatch({
    type: actionTypes.SESSION_SUPPORTINFO_VESSEL,
    payload: data,
  });
};

export const setRequestTerminal = (data) => (dispatch) => {
  dispatch({
    type: actionTypes.SESSION_REQUEST_TERMINAL,
    payload: data,
  });
};

export const setRequestClearance = (data) => (dispatch) => {
  dispatch({
    type: actionTypes.SESSION_REQUEST_CLEARANCE,
    payload: data,
  });
};

export const setRequestFinalrelease = (data) => (dispatch) => {
  dispatch({
    type: actionTypes.SESSION_REQUEST_FINALRELEASE,
    payload: data,
  });
};

export const setRequestAppealing = (data) => (dispatch) => {
  dispatch({
    type: actionTypes.SESSION_REQUEST_APPEALING,
    payload: data,
  });
};

export const setEvaluationList = () => async (dispatch) => {
  const list = await LookupService.searchevaluation();

  dispatch({
    type: actionTypes.SESSION_EVALUATION,
    payload: list?.data,
  });
};

export const setClearanceList = () => async (dispatch) => {
  const list = await LookupService.searchclearance();

  dispatch({
    type: actionTypes.SESSION_CLEARANCE,
    payload: list?.data,
  });
};

export const addRootCauseList = () => async (dispatch) => {
  const list = await LookupService.searchrootcause();
  dispatch({
    type: actionTypes.SESSION_ROOTCAUSE,
    payload: list?.data,
  });
};

export const addOperatorResponseList = () => async (dispatch) => {
  const list = await LookupService.searchoperatorresponse();
  dispatch({
    type: actionTypes.SESSION_OPERATORRESPONSE,
    payload: list?.data,
  });
};

export const addDocStsList = (type) => async (dispatch) => {
  const list = await DocStsService.seachdocsts(type);
  dispatch({
    type: actionTypes.SESSION_DOCSTS,
    payload: list?.data,
  });
};

export const addMQFormList = () => async (dispatch) => {
  const list = await MQFormService.searchMQForm();
  dispatch({
    type: actionTypes.SESSION_MQFORM,
    payload: list?.data,
  });
};

export const setMenuRole = (role_id) => async (dispatch) => {
  const menuList = await AuthService.getMenu(role_id);
  dispatch({
    type: actionTypes.SESSION_MENUROLE,
    payload: menuList,
  });
};

export const setVesselFavorite = () => async (dispatch) => {
  const favoriteList = await UserService.getVesselFavorite();
  dispatch({
    type: actionTypes.SESSION_VESSEL_FAVORITE,
    payload: favoriteList?.data?.data,
  });
};

export const setOperationOfIncidentList = () => async (dispatch) => {
  const list = await LookupService.searchOperationOfIncident();

  dispatch({
    type: actionTypes.SESSION_OPERATION_OF_INCIDENT,
    payload: list?.data,
  });
};

export const setCategoryList = () => async (dispatch) => {
  const list = await LookupService.searchCategory();

  dispatch({
    type: actionTypes.SESSION_CATEGORY,
    payload: list?.data,
  });
};

export const setCountMyTask = () => async (dispatch) => {
  const response = await RequestService.mytaskLength();
  dispatch({
    type: actionTypes.SESSION_COUNT_MY_TASK,
    payload: response?.data?.data,
  });
};

export const setCountMyDraft = () => async (dispatch) => {
  const response = await RequestService.draftLength();
  dispatch({
    type: actionTypes.SESSION_COUNT_MY_DRAFT,
    payload: response?.data?.data,
  });
};

export const setCountVetterTask = () => async (dispatch) => {
  const response = await RequestService.myTaskVetterLength();
  dispatch({
    type: actionTypes.SESSION_COUNT_VETTER_TASK,
    payload: response?.data?.data,
  });
};

export const setCountInspectionTask = () => async (dispatch) => {
  const response = await RequestService.myTaskInspectionLength();
  dispatch({
    type: actionTypes.SESSION_COUNT_INSPECTION_TASK,
    payload: response?.data?.data,
  });
};

export const setCountSponsorTask = () => async (dispatch) => {
  const response = await RequestService.myTaskSponsorLength();
  dispatch({
    type: actionTypes.SESSION_COUNT_SPONSOR_TASK,
    payload: response?.data?.data,
  });
};

export const setBusinessUnitList = () => async (dispatch) => {
  const response = await BusinessUnitService.searchbusinessunit();
  dispatch({
    type: actionTypes.SESSION_BUSINESS_UNIT_LIST,
    payload: response?.data,
  });
};

export const setRoleList = () => async (dispatch) => {
  const response = await RoleService.searchrole();
  dispatch({
    type: actionTypes.SESSION_ROLE_LIST,
    payload: response?.data,
  });
};

export const setConfigItem = (formData) => async (dispatch) => {
  const response = await ConfigService.getConfigSystem(formData);
  const { data: list } = response;

  dispatch({
    type: actionTypes.SESSION_CONFIG_SYSTEM,
    payload: list?.data,
  });
};

export const setOnHoldStatus = () => async (dispatch) => {
  const list = await LookupService.searchOnHoldStatus();

  dispatch({
    type: actionTypes.SESSION_ON_HOLD_STATUS,
    payload: list?.data,
  });
};


export const setConditionOfIns = () => async (dispatch) => {
  const list = await LookupService.searchConditionOfIns();

  dispatch({
    type: actionTypes.SEESION_CONDITION_OF_INS,
    payload: list?.data,
  });
};