import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { login, setTokenData, logout } from "src/redux/actions";
import PropTypes from "prop-types";
import Storage from "src/common/Storage";
import useRoute from "src/common/utils/useRoute";
import { useLocation } from "react-router-dom";
// Example of user roles: ['GUEST', 'USER', 'ADMIN'];

function SecureGate({ children }) {
  const location = useLocation();
  const session = useSelector((state) => state.session);
  const dispatch = useDispatch();
  const { routeLogin, routeExpire } = useRoute();

  useEffect(() => {
    const jwt = Storage.getJWT() || {};
    const token = jwt?.token;
    const refresh_token = jwt?.refresh_token;
    const jwt_data = Storage.getDataJWT();
    const user = Storage.getUser() || {};
    if (!session.loggedIn || !session.user) {
      if (token && user?.role_id) {
        dispatch(login(user));
      } else {
        dispatch(logout(refresh_token));
        Storage.clear();
        if (location?.pathname) {
          Storage.setCurrentPath(location?.pathname);
        }
        routeLogin();
        return;
      }
    }
    if (!session.tokenData && token) {
      dispatch(setTokenData(token));
    }
    if (!token) {
      dispatch(logout(refresh_token));
      Storage.clear();
      if (location?.pathname) {
        Storage.setCurrentPath(location?.pathname);
      }
      routeExpire();
      return;
    }
    if (!jwt_data && token) {
      Storage.setDataJWT(jwt);
    }

    // if (!roles.includes(session.user.role)) {
    //   history.push('/errors/error-401');
    // }
  });

  return <>{children}</>;
}

SecureGate.propTypes = {
  children: PropTypes.node,
  // roles: PropTypes.array.isRequired
};

export default SecureGate;
