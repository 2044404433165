import { colors } from "@material-ui/core";

const white = "#FFFFFF";

export default {
    primary: {
        contrastText: white,
        dark: colors.indigo[900],
        main: colors.indigo[500],
        light: colors.indigo[100],
        user: {
            color: "#000",
            avatarBorderColor: "#f2f2f2",
        },
    },
    secondary: {
        contrastText: white,
        dark: colors.blue[900],
        main: colors.blue.A700,
        light: colors.blue.A400,
    },
    error: {
        contrastText: white,
        dark: colors.red[900],
        main: colors.red[600],
        light: colors.red[400],
    },
    text: {
        primary: colors.blueGrey[900],
        secondary: colors.blueGrey[600],
        link: colors.blue[600],
    },
    link: colors.blue[800],
    icon: colors.blueGrey[600],
    background: {
        default: "#F4F6F8",
        paper: white,
    },
    divider: colors.grey[200],
    subTableHeader: { color: "#3167fb" },
    btn: {
        secondary: "rgb(220, 0, 78)",
        edit: "rgb(0, 153, 0)",
    },
    table: {
        row: {
            hover: "rgb(238 241 248)",
        },
    },
    btnAction: {
        back: {
            minWidth: 150,
            marginRight: 15,
        },
        saveDraft: {
            minWidth: 150,
            marginRight: 15,
            backgroundColor: "#ffffff",
            "&:hover": {
                backgroundColor: "#ffffff",
            },
        },
        edit: {
            minWidth: 150,
            marginRight: 15,
            backgroundColor: "#009900",
            "&:hover": {
                backgroundColor: "#009900",
            },
            color: "white",
        },
        recall: {
            minWidth: 150,
            marginRight: 15,
            backgroundColor: "#ff4500",
            "&:hover": {
                backgroundColor: "#ff4500",
            },
            color: "white",
        },
        submit: {
            marginRight: 15,
            minWidth: 150,
        },
        close: {
            minWidth: 150,
            marginRight: 15,
        },
        delete: {
            minWidth: 150,
            marginRight: 15,
            backgroundColor: "#ff0000",
            "&:hover": {
                backgroundColor: "#ff0000",
            },
            color: "white",
        },
        view: {
            minWidth: 150,
            marginRight: 15,
            height: 38,
        },
        export: {
            minWidth: 150,
            marginRight: 1,
            height: 38,
        },
        contact: {
            minWidth: 150,
            marginRight: 1,
            backgroundColor: "#feae0e",
            "&:hover": {
                backgroundColor: "#feae0e",
            },
            color: "#ffff",
            fontSize: 16,
        },
    },
};
