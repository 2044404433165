export default {
    root: {},
    notchedOutline: {
        borderColor: "#e7e8ed",
    },
    inputMarginDense: {
        paddingTop: 10,
        paddingBottom: 10,
    },
    input: {
        padding: "10px 10px",
    },
};
