/* eslint-disable react/no-multi-comp */
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
//import { Link as RouterLink } from 'react-router-dom';
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import {
  Drawer,
  Divider,
  //Avatar,
  List,
  //ListSubheader,
  //Typography,
  Hidden,
  //IconButton,
  //Badge,
  //Link,
  colors,
} from "@material-ui/core";
//import MoreIcon from '@material-ui/icons/MoreVert';
//import {NavItem} from 'src/components';
import NavItemMap from "src/components/NavItem/NavItem.Map.Component";
import navConfig from "./navConfig";
import SimpleBarReact from "simplebar-react";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    background: "#eef1f8",
  },
  mobileDrawer: {
    width: 300,
  },
  desktopDrawer: {
    width: 300,
    top: 110,
    height: "calc(100% - 64px)",
  },
  desktopDrawerSmall: {
    width: 70,
    top: 110,
    height: "calc(100% - 64px)",
    borderRight: 0,
  },
  navigation: {
    overflow: "auto",
    padding: theme.spacing(0, 2, 2, 2),
    flexGrow: 1,
  },
  profile: {
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
  },
  badge: {
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
  },
  badgeDot: {
    height: 9,
    minWidth: 9,
  },
  onlineBadge: {
    backgroundColor: colors.green[600],
  },
  awayBadge: {
    backgroundColor: colors.orange[600],
  },
  busyBadge: {
    backgroundColor: colors.red[600],
  },
  offlineBadge: {
    backgroundColor: colors.grey[300],
  },
  avatar: {
    cursor: "pointer",
    width: 40,
    height: 40,
  },
  details: {
    marginLeft: theme.spacing(2),
  },
  moreButton: {
    marginLeft: "auto",
    color: colors.blueGrey[200],
  },
  sideNavbar: {
    overflow: "hidden",
  },
}));

function renderNavItems({
  // eslint-disable-next-line react/prop-types
  items,
  subheader,
  key,
  ...rest
}) {
  return (
    <List key={key}>
      {/* eslint-disable-next-line react/prop-types */}
      {items.reduce(
        // eslint-disable-next-line no-use-before-define
        (acc, item, index) => ReduceChildRoutes({ acc, item, index, ...rest }),
        []
      )}
    </List>
  );
}

function ReduceChildRoutes({ acc, pathname, item, index, depth = 0 }) {
  if (item && item?.items?.length > 0) {
    const open = item?.items?.some((data) => data.href === pathname);
    acc.push(
      <NavItemMap
        key={depth + "_" + index}
        depth={depth}
        icon={item?.icon}
        label={item?.label}
        open={Boolean(open)}
        title={item?.title}
        eleIcon={item?.eleicon}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item?.items || [],
        })}
      </NavItemMap>
    );
  } else {
    acc.push(
      <NavItemMap
        key={depth + "_" + index}
        depth={depth}
        href={item?.href}
        icon={item?.icon}
        label={item?.label}
        title={item?.title}
        eleIcon={item?.eleicon}
        className={
          item
            ? item?.title === "ADD REQUEST"
              ? "nav-btn"
              : item?.className
            : ""
        }
      />
    );
  }
  return acc;
}

function NavBar({
  openMobile,
  onMobileClose,
  className,
  onNavToggle,
  ...rest
}) {
  const classes = useStyles();
  const location = useLocation();
  const [fullNav, setFullNav] = useState(true);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const handleNavBarToggle = () => {
    setFullNav((_temp) => {
      if (onNavToggle) {
        onNavToggle(!_temp);
      }
      return !_temp;
    });
  };

  const content = (
    <div {...rest} className={clsx(classes.root, className)}>
      <div
        className={clsx(
          "side-navbar",
          classes.sideNavbar,
          fullNav ? "open" : ""
        )}
      >
        <SimpleBarReact className="main-menu">
          <nav className={clsx(classes.navigation, "side-menu list-unstyled")}>
            <div
              id="toggle-btn-open"
              className="toggle-btn active"
              onClick={handleNavBarToggle}
            >
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
            {navConfig.map((list) =>
              renderNavItems({
                items: list.items,
                subheader: list.subheader,
                pathname: location.pathname,
                key: list.subheader,
              })
            )}
          </nav>
        </SimpleBarReact>
      </div>
      <Divider className={classes.divider} />
    </div>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{
            paper: classes.mobileDrawer,
          }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{
            paper: fullNav ? classes.desktopDrawer : classes.desktopDrawerSmall,
          }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  className: PropTypes.string,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
