/*eslint-disable eqeqeq*/
/*eslint-disable no-throw-literal*/
import { axios } from "src/common/utils";
import { appSettings } from "src/configs";
import { commonHeader, authHeader } from "src/common/requestHeader";
import Storage from "src/common/Storage";
import axiosDefault from "axios";
import {
    browserName,
    fullBrowserVersion,
    osName,
    osVersion,
    mobileVendor,
    mobileModel,
} from "react-device-detect";
import helperUtil from "src/common/utils/helperUtil";
import { ThirdPartyService } from "src/services";

const fetchIpService = async () => {
    let ipinfo_data = "";
    try {
        const response = await ThirdPartyService.ipApi();
        if (response.status == 200) {
            const data = response.data;
            const response_data = {
                ip: data?.ip || "",
                country_name: data?.country_name || "",
                region: data?.region || "",
                city: data?.city || "",
                org: data?.org || "",
            };
            ipinfo_data = helperUtil.encryptBase64(
                JSON.stringify(response_data)
            );
        } else {
            throw "call ip error";
        }
    } catch (err) {
        try {
            const response = await ThirdPartyService.ipInfo();
            if (response.status == 200) {
                const data = response.data;
                const response_data = {
                    ip: data?.ip || "",
                    country_name: data?.country || "",
                    region: data?.region || "",
                    city: data?.city || "",
                    org: data?.org || "",
                };
                ipinfo_data = helperUtil.encryptBase64(
                    JSON.stringify(response_data)
                );
            }
        } catch (err) {}
    }
    return ipinfo_data;
};

const dataDeviceInfo = async () => {
    let ipinfo_data = await fetchIpService();
    const deviceInfo = {
        browserName: browserName || "",
        browserVersion: fullBrowserVersion || "",
        deviceVendor: mobileVendor || "PC",
        deviceModel: mobileModel || "PC",
        osName: osName || "",
        osVersion: osVersion || "",
        ipinfo: ipinfo_data,
    };
    return deviceInfo;
};

const AuthService = {};

AuthService.login = async (param) => {
    let deviceInfo = await dataDeviceInfo();
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                Pragma: "no-cache",
            },
            method: "post",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/Auth/Login`,
            data: { ...param, deviceInfo },
        })
            .then((response) => resolve(response.data))
            .catch((error) => reject(error));
    });
};

AuthService.logout = (refreshToken) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                Pragma: "no-cache",
            },
            method: "post",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/Auth/Logout`,
            data: {
                refreshToken: refreshToken,
            },
        })
            .then((response) => resolve(response.data))
            .catch((error) => reject(error));
    });
};

AuthService.refreshToken = (param) => {
    return new Promise((resolve, reject) => {
        let jwt = Storage.getJWT();
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
            },
            method: "post",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/Auth/RefreshToken`,
            data: { refresh_token: jwt?.refresh_token },
        })
            .then((response) => resolve(response.data))
            .catch(function(error) {
                if (axiosDefault.isCancel(error)) {
                    console.log("Request canceled");
                } else {
                    reject(error);
                }
            });
    });
};

AuthService.loginAzure = async (param) => {
    let deviceInfo = await dataDeviceInfo();
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
            },
            method: "post",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/Auth/Setrole`,
            data: { ...param, deviceInfo },
        })
            .then((response) => resolve(response.data))
            .catch((error) => reject(error));
    });
};

AuthService.forgotPassword = (param) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
            },
            method: "post",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/Auth/password/email`,
            data: param,
        })
            .then((response) => resolve(response.data))
            .catch((error) => reject(error));
    });
};

AuthService.resetPassword = (param) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
            },
            method: "post",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/Auth/password/reset`,
            data: param,
        })
            .then((response) => resolve(response.data))
            .catch((error) => reject(error));
    });
};

AuthService.getMenu = (formData) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
            },
            method: "get",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/Auth/Menu?role_id=${formData.role_id}`,
            data: { ...formData },
        })
            .then((response) => resolve(response.data))
            .catch((error) => reject(error));
    });
};

export default AuthService;
