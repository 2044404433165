import { axios } from "src/common/utils";
import { appSettings } from "src/configs";
import { commonHeader, authHeader } from "src/common/requestHeader";
const CompanyService = {};

CompanyService.searchcompany = (formData) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
            },
            method: "get",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/company`,
            params: { ...formData },
        })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
};

CompanyService.companyDetail = (id) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
            },
            method: "get",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/company/${id}`,
        })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
};

CompanyService.searchcompanyAll = (formData) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
            },
            method: "get",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/company/all`,
            params: { ...formData },
        })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
};

CompanyService.updateFormCompany = (formData) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
            },
            method: "post",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/company/all`,
            data: { ...formData },
        })
            .then((response) => resolve(response.data))
            .catch((error) => reject(error));
    });
};

export default CompanyService;
