/* eslint-disable no-unused-vars */
import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import PersonIcon from "@material-ui/icons/Person";
import HttpsIcon from "@material-ui/icons/Https";
import helperUtil from "src/common/utils/helperUtil";
import ButtonLoading from "src/components/Loading/ButtonLoading";
import {
    Button,
    TextField,
    Typography,
    InputAdornment,
    FormControl,
    Select,
    MenuItem,
    FormControlLabel,
    Checkbox,
} from "@material-ui/core";
import styles from "./styles";
import useRoute from "src/common/utils/useRoute";
import { ALL_STRING } from "src/common/utils/StringUtil";
import { useSelector } from "react-redux";

const WORD_USERNAME = ALL_STRING.WORD_USERNAME;
const WORD_EMP_CODE = ALL_STRING.WORD_EMP_CODE;
const WORD_PASSWORD = ALL_STRING.WORD_PASSWORD;

function Login({
    className,
    handleSubmit,
    formState,
    handleChange = () => {},
    hasError = () => {},
    loading = false,
    ...rest
}) {
    const session = useSelector((state) => state.session);
    const classes = styles();
    const {
        routeTopAzureLogin,
        routeIRPCAzureLogin,
        routeGCAzureLogin,
        routeAzureLogout,
    } = useRoute();

    const isTopBU = formState.values.domain === "TOP";
    const isIRPCBU = formState.values.domain === "IRPC";
    const isGCBU = formState.values.domain === "GC";

    const businessUnitList = session?.businessUnitList?.length
        ? session?.businessUnitList
              //   ?.filter((data) => [1, 8, 99].includes(data.id))
              ?.map((data) => ({
                  id: data.id,
                  name: data.bu,
              }))
        : [];

    const roleList = session?.roleList?.length
        ? session?.roleList
              ?.filter((data) => ![7, 8].includes(data.id))
            //   ?.filter(
            //       (data) =>
            //           ["PTT", "OR", "OTHER"].includes(formState.values.domain) || [2, 3].includes(data.id)
            //   )
              ?.map((data) => ({
                  id: data.id,
                  name: data.name,
              }))
        : [];

    return (
        <form
            {...rest}
            className={clsx(classes.root, className)}
            onSubmit={handleSubmit}
            autoComplete="off"
        >
            <div className={classes.fields}>
                <Typography gutterBottom variant="h3" className="my-0">
                    <span className={classes.domainText}> Domain </span>
                </Typography>
                <FormControl variant="outlined" className={"w-100"}>
                    <Select
                        id="auth-select-domain"
                        value={formState.values.domain || "Please Select"}
                        onChange={handleChange}
                        margin="dense"
                        name="domain"
                        renderValue={(selected) => {
                            if (selected === "Please Select") {
                                return <>Please Select</>;
                            }
                            return selected;
                        }}
                    >
                        <MenuItem disabled value="Please Select">
                            Please Select
                        </MenuItem>
                        {businessUnitList?.map((value, key) => (
                            <MenuItem key={`role-${key}`} value={value.name}>
                                {value.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {!isTopBU && !isIRPCBU && !isGCBU && (
                    <>
                        <TextField
                            error={hasError("userName")}
                            fullWidth
                            helperText={
                                hasError("userName")
                                    ? formState.errors.userName[0]
                                    : null
                            }
                            label={WORD_USERNAME}
                            name="userName"
                            onChange={handleChange}
                            value={formState.values.userName || ""}
                            variant="outlined"
                            margin="dense"
                            placeholder={WORD_EMP_CODE}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <PersonIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <TextField
                            error={hasError("password")}
                            fullWidth
                            helperText={
                                hasError("password")
                                    ? formState.errors.password[0]
                                    : null
                            }
                            label={WORD_PASSWORD}
                            name="password"
                            onChange={handleChange}
                            type="password"
                            value={formState.values.password || ""}
                            variant="outlined"
                            margin="dense"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <HttpsIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <FormControl variant="outlined" className={"w-100"}>
                            <Select
                                id="auth-select-role"
                                value={formState.values.role}
                                onChange={handleChange}
                                margin="dense"
                                name="role"
                                displayEmpty
                                // disabled={!formState.values.domain}
                            >
                                <MenuItem value="" disabled>
                                    Role
                                </MenuItem>
                                {roleList?.map((value, key) => (
                                    <MenuItem
                                        key={`role-${key}`}
                                        value={value.id}
                                    >
                                        {value.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formState.values.isRemember}
                                    onChange={handleChange}
                                    name="isRemember"
                                    color="primary"
                                />
                            }
                            label="Remember me"
                        />
                    </>
                )}
            </div>
            {isTopBU && (
                <Button
                    className={classes.submitButton}
                    disabled={!isTopBU}
                    size="large"
                    variant="contained"
                    onClick={routeTopAzureLogin}
                    color="primary"
                >
                    TOP LOG ON
                </Button>
            )}
            {isIRPCBU && (
                <Button
                    className={classes.submitButton}
                    disabled={!isIRPCBU}
                    size="large"
                    variant="contained"
                    onClick={routeIRPCAzureLogin}
                    color="primary"
                >
                    IRPC LOG ON
                </Button>
            )}
            {isGCBU && (
                <Button
                    className={classes.submitButton}
                    disabled={!isGCBU}
                    size="large"
                    variant="contained"
                    onClick={routeGCAzureLogin}
                    color="primary"
                >
                    GC LOG ON
                </Button>
            )}
            {(isTopBU || isIRPCBU || isGCBU) && (
                <Button
                    className={clsx(classes.submitButton, "mt-3")}
                    disabled={!isTopBU && !isIRPCBU && !isGCBU}
                    size="large"
                    variant="contained"
                    onClick={routeAzureLogout}
                    color="default"
                >
                    LOG OUT ANOTHER ACCOUNT
                </Button>
            )}
            {!isTopBU && !isIRPCBU && !isGCBU && (
                <ButtonLoading
                    buttonClassname={classes.submitButton}
                    loading={loading}
                    disabled={loading || !formState.isValid}
                    buttonText={"LOG ON"}
                    typeButton={"submit"}
                />
            )}
        </form>
    );
}

Login.propTypes = {
    className: PropTypes.string,
};

export default Login;
