import { axios } from "src/common/utils";
import { appSettings } from "src/configs";
import { commonHeader, authHeader } from "src/common/requestHeader";

const SupportInfoService = {};

SupportInfoService.searchterminal = (formData) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
            },
            method: "get",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/supportinfo/terminalfeedback`,
            params: { ...formData },
        })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
};

SupportInfoService.terminaldetail = (id) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
            },
            method: "get",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/supportinfo/terminalfeedback/${id}`,
        })
            .then((response) => resolve(response.data))
            .catch((error) => reject(error));
    });
};

SupportInfoService.getterminalquestion = (formData) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
            },
            method: "get",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/supportinfo/terminalfeedback/question`,
            params: { ...formData },
        })
            .then((response) => resolve(response.data))
            .catch((error) => reject(error));
    });
};

SupportInfoService.addterminal = (bodyFormData) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
            },
            method: "post",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/supportinfo/terminalfeedback`,
            data: bodyFormData,
        })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
};

SupportInfoService.editterminal = (bodyFormData) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
            },
            method: "put",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/supportinfo/terminalfeedback`,
            data: bodyFormData,
        })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
};

SupportInfoService.vettingterminal = (formData) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
            },
            method: "post",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/supportinfo/terminalfeedback/vetting`,
            data: { ...formData },
        })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
};

SupportInfoService.searchindustry = (formData) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
            },
            method: "get",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/supportinfo/industryinformation`,
            params: { ...formData },
        })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
};

SupportInfoService.industrydetail = (id) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
            },
            method: "get",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/supportinfo/industryinformation/${id}`,
        })
            .then((response) => resolve(response.data))
            .catch((error) => reject(error));
    });
};

SupportInfoService.addindustry = (bodyFormData) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
            },
            method: "post",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/supportinfo/industryinformation`,
            data: bodyFormData,
        })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
};

SupportInfoService.editindustry = (bodyFormData) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
            },
            method: "put",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/supportinfo/industryinformation`,
            data: bodyFormData,
        })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
};

SupportInfoService.vettingindustry = (formData) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
            },
            method: "post",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/supportinfo/industryinformation/vetting`,
            data: { ...formData },
        })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
};

SupportInfoService.searchincident = (formData) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
            },
            method: "get",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/supportinfo/incidentreport`,
            params: { ...formData },
        })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
};

SupportInfoService.incidentdetail = (id) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
            },
            method: "get",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/supportinfo/incidentreport/${id}`,
        })
            .then((response) => resolve(response.data))
            .catch((error) => reject(error));
    });
};

SupportInfoService.addincident = (bodyFormData) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
            },
            method: "post",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/supportinfo/incidentreport`,
            data: bodyFormData,
        })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
};

SupportInfoService.editincident = (bodyFormData) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
            },
            method: "put",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/supportinfo/incidentreport`,
            data: bodyFormData,
        })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
};

SupportInfoService.vettingincident = (formData) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
            },
            method: "post",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/supportinfo/incidentreport/vetting`,
            data: { ...formData },
        })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
};

SupportInfoService.searchportstate = (formData) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
            },
            method: "get",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/supportinfo/portstate`,
            params: { ...formData },
        })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
};

SupportInfoService.portstatedetail = (id) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
            },
            method: "get",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/supportinfo/portstate/${id}`,
        })
            .then((response) => resolve(response.data))
            .catch((error) => reject(error));
    });
};

SupportInfoService.addportstate = (bodyFormData) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
            },
            method: "post",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/supportinfo/portstate`,
            data: bodyFormData,
        })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
};

SupportInfoService.editportstate = (bodyFormData) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
            },
            method: "put",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/supportinfo/portstate`,
            data: bodyFormData,
        })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
};

SupportInfoService.vettingportstate = (formData) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
            },
            method: "post",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/supportinfo/portstate/vetting`,
            data: { ...formData },
        })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
};

SupportInfoService.searchchangeinstatus = (formData) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
            },
            method: "get",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/supportinfo/changeinstatus`,
            params: { ...formData },
        })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
};

SupportInfoService.changeinstatusdetail = (id) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
            },
            method: "get",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/supportinfo/changeinstatus/${id}`,
        })
            .then((response) => resolve(response.data))
            .catch((error) => reject(error));
    });
};

SupportInfoService.addchangeinstatus = (bodyFormData) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
            },
            method: "post",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/supportinfo/changeinstatus`,
            data: bodyFormData,
        })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
};

SupportInfoService.editchangeinstatus = (bodyFormData) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
            },
            method: "put",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/supportinfo/changeinstatus`,
            data: bodyFormData,
        })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
};

SupportInfoService.vettingchangeinstatus = (formData) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
            },
            method: "post",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/supportinfo/changeinstatus/vetting`,
            data: { ...formData },
        })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
};

SupportInfoService.searchcertificate = (formData) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
            },
            method: "get",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/supportinfo/certificate`,
            params: { ...formData },
        })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
};

SupportInfoService.certificatedetail = (id) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
            },
            method: "get",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/supportinfo/certificate/${id}`,
        })
            .then((response) => resolve(response.data))
            .catch((error) => reject(error));
    });
};

SupportInfoService.vettingcertificate = (formData) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
            },
            method: "post",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/supportinfo/certificate/vetting`,
            data: { ...formData },
        })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
};

SupportInfoService.searchownerperformance = (formData) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
            },
            method: "get",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/supportinfo/ownerperformance`,
            params: { ...formData },
        })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
};

SupportInfoService.ownerperformancedetail = (id) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
            },
            method: "get",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/supportinfo/ownerperformance/${id}`,
        })
            .then((response) => resolve(response.data))
            .catch((error) => reject(error));
    });
};

SupportInfoService.addownerperformance = (bodyFormData) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
            },
            method: "post",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/supportinfo/ownerperformance`,
            data: bodyFormData,
        })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
};

SupportInfoService.editownerperformance = (bodyFormData) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
            },
            method: "put",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/supportinfo/ownerperformance`,
            data: bodyFormData,
        })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
};

SupportInfoService.vettingownerperformance = (formData) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
            },
            method: "post",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/supportinfo/ownerperformance/vetting`,
            data: { ...formData },
        })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
};

SupportInfoService.searchcrewmanagement = (formData) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
            },
            method: "get",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/supportinfo/crewmanagement`,
            params: { ...formData },
        })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
};

SupportInfoService.crewmanagementdetail = (id) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
            },
            method: "get",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/supportinfo/crewmanagement/${id}`,
        })
            .then((response) => resolve(response.data))
            .catch((error) => reject(error));
    });
};

SupportInfoService.vettingcrewmanagement = (formData) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
            },
            method: "post",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/supportinfo/crewmanagement/vetting`,
            data: { ...formData },
        })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
};

SupportInfoService.searchstructural = (formData) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
            },
            method: "get",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/supportinfo/structural`,
            params: { ...formData },
        })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
};

SupportInfoService.structuraldetail = (id) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
            },
            method: "get",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/supportinfo/structural/${id}`,
        })
            .then((response) => resolve(response.data))
            .catch((error) => reject(error));
    });
};

SupportInfoService.addstructural = (bodyFormData) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
            },
            method: "post",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/supportinfo/structural`,
            data: bodyFormData,
        })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
};

SupportInfoService.editstructural = (bodyFormData) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
            },
            method: "put",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/supportinfo/structural`,
            data: bodyFormData,
        })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
};

SupportInfoService.vettingstructural = (formData) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
            },
            method: "post",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/supportinfo/structural/vetting`,
            data: { ...formData },
        })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
};



SupportInfoService.owneresponseTerminalFeedback = (id) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
            },
            method: "get",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/Supportinfo/TerminalFeedback/ShipOwnerResponse/${id}`,
        })
            .then((response) => resolve(response.data))
            .catch((error) => reject(error));
    });
};

SupportInfoService.owneresponseIndustry = (id) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
            },
            method: "get",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/Supportinfo/IndustryInformation/ShipOwnerResponse/${id}`,
        })
            .then((response) => resolve(response.data))
            .catch((error) => reject(error));
    });
};

SupportInfoService.owneresponseIncident = (id) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
            },
            method: "get",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/Supportinfo/IncidentReport/ShipOwnerResponse/${id}`,
        })
            .then((response) => resolve(response.data))
            .catch((error) => reject(error));
    });
};

SupportInfoService.owneresponsePortState = (id) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
            },
            method: "get",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/Supportinfo/PortState/ShipOwnerResponse/${id}`,
        })
            .then((response) => resolve(response.data))
            .catch((error) => reject(error));
    });
};

SupportInfoService.owneresponseChangeInStatus = (id) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
            },
            method: "get",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/Supportinfo/ChangeInStatus/ShipOwnerResponse/${id}`,
        })
            .then((response) => resolve(response.data))
            .catch((error) => reject(error));
    });
};

SupportInfoService.owneresponseCertificate = (id) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
            },
            method: "get",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/Supportinfo/Certificate/ShipOwnerResponse/${id}`,
        })
            .then((response) => resolve(response.data))
            .catch((error) => reject(error));
    });
};

SupportInfoService.owneresponseOwnerPerformance = (id) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
            },
            method: "get",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/Supportinfo/OwnerPerformance/ShipOwnerResponse/${id}`,
        })
            .then((response) => resolve(response.data))
            .catch((error) => reject(error));
    });
};

SupportInfoService.owneresponseCrewManagement = (id) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
            },
            method: "get",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/Supportinfo/CrewManagement/ShipOwnerResponse/${id}`,
        })
            .then((response) => resolve(response.data))
            .catch((error) => reject(error));
    });
};

SupportInfoService.owneresponseStructural = (id) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
            },
            method: "get",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/Supportinfo/Structural/ShipOwnerResponse/${id}`,
        })
            .then((response) => resolve(response.data))
            .catch((error) => reject(error));
    });
};

SupportInfoService.ownerResponseTerminalFeedbackUpdate = (bodyFormData) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
            },
            method: "post",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/Supportinfo/TerminalFeedback/ShipOwnerResponse`,
            data: bodyFormData,
        })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
};

SupportInfoService.ownerResponseIndustryUpdate = (bodyFormData) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
            },
            method: "post",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/Supportinfo/IndustryInformation/ShipOwnerResponse`,
            data: bodyFormData,
        })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
};

SupportInfoService.ownerResponseIncidentReportUpdate = (bodyFormData) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
            },
            method: "post",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/Supportinfo/IncidentReport/ShipOwnerResponse`,
            data: bodyFormData,
        })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
};

SupportInfoService.ownerResponsePortStateUpdate = (bodyFormData) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
            },
            method: "post",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/Supportinfo/PortState/ShipOwnerResponse`,
            data: bodyFormData,
        })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
};

SupportInfoService.ownerResponseChangeInStatusUpdate = (bodyFormData) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
            },
            method: "post",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/Supportinfo/ChangeInStatus/ShipOwnerResponse`,
            data: bodyFormData,
        })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
};

SupportInfoService.ownerResponseCertificateUpdate = (bodyFormData) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
            },
            method: "post",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/Supportinfo/Certificate/ShipOwnerResponse`,
            data: bodyFormData,
        })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
};

SupportInfoService.ownerResponseOwnerPerformanceUpdate = (bodyFormData) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
            },
            method: "post",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/Supportinfo/OwnerPerformance/ShipOwnerResponse`,
            data: bodyFormData,
        })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
};

SupportInfoService.ownerResponseCrewManagementUpdate = (bodyFormData) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
            },
            method: "post",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/Supportinfo/CrewManagement/ShipOwnerResponse`,
            data: bodyFormData,
        })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
};

SupportInfoService.ownerResponseStructuralUpdate = (bodyFormData) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
            },
            method: "post",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/Supportinfo/Structural/ShipOwnerResponse`,
            data: bodyFormData,
        })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
};

export default SupportInfoService;
