import React from "react";
import NumberFormat from "react-number-format";

export default (props) => {
    const {
        inputRef,
        onChange,
        thousandSeparator = false,
        allowNegative = false,
        decimalScale = 0,
        ...other
    } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator={thousandSeparator}
            isNumericString
            allowNegative={allowNegative}
            decimalScale={decimalScale}
        />
    );
};
