import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Popover, colors } from "@material-ui/core";
import { useDispatch } from "react-redux";
import Storage from "src/common/Storage";
import { logout } from "src/redux/actions";
import useRoute from "src/common/utils/useRoute";
import helperUtil from "src/common/utils/helperUtil";
import Image from "src/components/Image";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 320,
    maxWidth: "100%",
    paddingTop: 15,
    paddingBottom: 15,
  },
  actions: {
    backgroundColor: colors.grey[50],
    justifyContent: "center",
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    border: "3px solid",
    borderColor: theme.palette.primary.user.avatarBorderColor,
    alignSelf: "center",
  },
  avatar: {
    alignSelf: "center",
    display: "flex",
  },
  cardTop: {
    fontSize: "0.8em",
    textAlign: "center",
    padding: "10px 0",
  },
  userThumb: {
    width: 80,
    height: 80,
    margin: "0 auto 10px",
  },
  p: {
    color: "#66728c",
    margin: "0 0 5px",
  },
  cardNav: {
    padding: "10px 0 15px",
  },
  navLink: {
    display: "flex",
    alignItems: "center",
    color: "#1f2847",
    fontSize: "0.8em",
  },
  cardBottom: {},
}));

function UserPopover({ anchorEl, ...rest }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [user, setUser] = useState(undefined);
  const { routeLogin } = useRoute();

  React.useEffect(() => {
    const _user = Storage.getUser();
    if (helperUtil.checkHasObject(_user)) {
      setUser(_user);
    }
  }, []);

  const handleLogout = (event) => {
    event.preventDefault();
    const jwt = Storage.getJWT() || {};

    dispatch(logout(jwt.refresh_token));
    Storage.clear();
    routeLogin();
  };

  return (
    <Popover
      {...rest}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
    >
      <div className={classes.root}>
        {/* <Divider /> */}
        <div className="profile-card">
          <div className="card-top">
            <div className="user-thumb">
              <Image
                path={helperUtil.getImageProfileUser()}
                alt={user ? user.full_name_en : ""}
                onError={(e) => {
                  e.target.src = "/assets/images/user.png";
                }}
              />
            </div>
            <h6>{user ? user.full_name_en : ""}</h6>
            <div className="p">Role : {user ? user.role : ""}</div>
            <div className="p">{user ? user.email : ""}</div>
          </div>
          <div className="card-nav">
            <a href="/profile" className="nav-link">
              <span className="icon-circle">
                <img src="/assets/images/icon-cog.png" alt="" />
              </span>{" "}
              My Profile
            </a>
            <a href="/logout" onClick={handleLogout} className="nav-link">
              <span className="icon-circle">
                <img src="/assets/images/icon-out.png" alt="" />
              </span>{" "}
              Log out
            </a>
          </div>
        </div>
      </div>
    </Popover>
  );
}

UserPopover.propTypes = {
  anchorEl: PropTypes.any,
  className: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default UserPopover;
