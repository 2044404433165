import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
    buttonProgressAbsolute: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    buttonProgress: {
        color: green[500],
    },
}));

const CircleProgress = (props) => {
    const { loadingSize = 24, showAbsolute = true } = props;

    const classes = useStyles();
    return (
        <CircularProgress
            size={loadingSize}
            className={
                showAbsolute
                    ? classes.buttonProgressAbsolute
                    : classes.buttonProgress
            }
        />
    );
};

export default CircleProgress;
