import React from "react";
import helperUtil from "src/common/utils/helperUtil";

export default function RequestInfo(props) {
  const { row, VesselInfo } = props;

  return (
    <div className="table-collapse__box">
      <div className="row">
        <div className="col-12">
          <div className="row">
            <div className="col-12">
              <div className="text lg mb-3">Request No. {row?.req_no}</div>
            </div>
            <div className="col-6">
              <h2>
                <span className="icon icon-vessel mr-2"></span>{" "}
                {VesselInfo?.name}
              </h2>
              <div className="text-with-bg">
                <div className="col-p p-lg">
                  IMO : <span className="text-blue">{VesselInfo?.imo}</span>
                </div>
                <div className="col-p p-lg">
                  Reg :{" "}
                  <span className="text-blue">{VesselInfo?.register_no}</span>
                </div>
              </div>
              {row?.terminal_load?.map((data, i) => (
                <div className="desc-row">
                  <div className="col-3 label">Load</div>
                  <div className="col-9 p">
                    {data?.terminal} {data?.berth} on{" "}
                    {helperUtil.formatDate({
                      value: data?.load_date,
                      format: "DD/MM/YYYY",
                      resInvalid: "null",
                    })}
                    {/* {data} */}
                  </div>
                </div>
              ))}
              {row?.terminal_discharge?.map((data, i) => (
                <div className="desc-row">
                  <div className="col-3 label">Discharge</div>
                  <div className="col-9 p">
                    {data?.terminal} {data?.berth} on{" "}
                    {helperUtil.formatDate({
                      value: data?.load_date,
                      format: "DD/MM/YYYY",
                      resInvalid: "null",
                    })}
                    {/* {data} */}
                  </div>
                </div>
              ))}
            </div>
            <div className="col-6">
              <div className="desc-row">
                <div className="col-3 label">Flag</div>
                <div className="col-9 p">{row?.flag}</div>
              </div>
              <div className="desc-row">
                <div className="col-3 label">Reg. Owner</div>
                <div className="col-9 p">{VesselInfo?.registered_own}</div>
              </div>
              <div className="desc-row">
                <div className="col-3 label">Operator</div>
                <div className="col-9 p">{VesselInfo?.operation}</div>
              </div>
              <div className="desc-row">
                <div className="col-3 label">Last Inspection</div>
                <div className="col-9 p">
                  {helperUtil.formatDate({
                    value: VesselInfo?.ins_last_date,
                    format: "DD/MM/YYYY",
                    resInvalid: "null",
                  })}
                </div>
              </div>
              <div className="desc-row">
                <div className="col-3 label">Expire Date</div>
                <div className="col-9 p">
                  {helperUtil.formatDate({
                    value: VesselInfo?.ins_last_exp_date,
                    format: "DD/MM/YYYY",
                    resInvalid: "null",
                  })}
                </div>
              </div>
              <div className="desc-row">
                <div className="col-3 label">Last Nomination</div>
                <div className="col-9 p">
                  {helperUtil.formatDate({
                    value: row?.nominate_date,
                    format: "DD/MM/YYYY",
                    resInvalid: "null",
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
