import { axios } from "src/common/utils";
import { appSettings } from "src/configs";
import { commonHeader, authHeader } from "src/common/requestHeader";
const CountryService = {};

CountryService.searchcountry = (formData) => {
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                ...commonHeader(),
                ...authHeader(),
            },
            method: "get",
            url: `${appSettings.services.map.apiUrl}/api/v1.0/country`,
            params: { ...formData },
        })
            .then((response) => resolve(response.data))
            .catch((error) => reject(error));
    });
};

export default CountryService;
